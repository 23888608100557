/* tslint:disable */
/* eslint-disable */
/**
 * VentureClub.RestApi v1.33.2.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  InvoiceData,
  InvoiceResultDataType,
} from '../models/index';
import {
    InvoiceDataFromJSON,
    InvoiceDataToJSON,
    InvoiceResultDataTypeFromJSON,
    InvoiceResultDataTypeToJSON,
} from '../models/index';

export interface InvoiceCreateRequest {
    invoiceData: InvoiceData;
}

export interface InvoiceGetNumberSeriesRequest {
    issuerCode: string;
}

/**
 * 
 */
export class InvoiceApi extends runtime.BaseAPI {

    /**
     * Create external invoice
     */
    async invoiceCreateRaw(requestParameters: InvoiceCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<InvoiceResultDataType>> {
        if (requestParameters.invoiceData === null || requestParameters.invoiceData === undefined) {
            throw new runtime.RequiredError('invoiceData','Required parameter requestParameters.invoiceData was null or undefined when calling invoiceCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/invoice/create`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InvoiceDataToJSON(requestParameters.invoiceData),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InvoiceResultDataTypeFromJSON(jsonValue));
    }

    /**
     * Create external invoice
     */
    async invoiceCreate(requestParameters: InvoiceCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<InvoiceResultDataType> {
        const response = await this.invoiceCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Číselné řady faktur
     */
    async invoiceGetNumberSeriesRaw(requestParameters: InvoiceGetNumberSeriesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.issuerCode === null || requestParameters.issuerCode === undefined) {
            throw new runtime.RequiredError('issuerCode','Required parameter requestParameters.issuerCode was null or undefined when calling invoiceGetNumberSeries.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/invoice/{issuerCode}/issued-invoices/number-series`.replace(`{${"issuerCode"}}`, encodeURIComponent(String(requestParameters.issuerCode))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Číselné řady faktur
     */
    async invoiceGetNumberSeries(requestParameters: InvoiceGetNumberSeriesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.invoiceGetNumberSeriesRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
