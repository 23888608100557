/* tslint:disable */
/* eslint-disable */
/**
 * VentureClub.RestApi2
 * 1.33.2.3, env PROD
 *
 * The version of the OpenAPI document: 1.0
 * Contact: it@spinware.cz
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Požadavek investice
 * @export
 * @interface InvestRequest
 */
export interface InvestRequest {
    /**
     * Id investiční příležitosti - nastavení (detailu)
     * @type {number}
     * @memberof InvestRequest
     */
    investOpId?: number;
    /**
     * Částka příslibu investice
     * @type {number}
     * @memberof InvestRequest
     */
    amount?: number;
    /**
     * Investice za právnickou osobu
     * @type {boolean}
     * @memberof InvestRequest
     */
    company?: boolean;
    /**
     * Heslo k ověření příslibu
     * @type {string}
     * @memberof InvestRequest
     */
    password?: string | null;
}

/**
 * Check if a given object implements the InvestRequest interface.
 */
export function instanceOfInvestRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function InvestRequestFromJSON(json: any): InvestRequest {
    return InvestRequestFromJSONTyped(json, false);
}

export function InvestRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): InvestRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'investOpId': !exists(json, 'investOpId') ? undefined : json['investOpId'],
        'amount': !exists(json, 'amount') ? undefined : json['amount'],
        'company': !exists(json, 'company') ? undefined : json['company'],
        'password': !exists(json, 'password') ? undefined : json['password'],
    };
}

export function InvestRequestToJSON(value?: InvestRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'investOpId': value.investOpId,
        'amount': value.amount,
        'company': value.company,
        'password': value.password,
    };
}

