import { BottomNavigation, BottomNavigationAction, Stack } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import { useLocation } from "@reach/router";
import { Link } from "gatsby";
import React, { type ReactNode } from "react";
import { Helmet } from "react-helmet";

import { useLocalization } from "../../../context/LanguageProvider";
import iosDetected from "../../../functions/iOSDetect";
import palette from "../../../gatsby-theme-material-ui-top-layout/palette";
import Home from "../../../icons/Home";
import Investing from "../../../icons/Investing";
import Settings from "../../../icons/Settings";

interface LayoutProps {
	children: ReactNode;
}
const Layout: React.FC<LayoutProps> = ({ children }: LayoutProps) => {
	const { localization } = useLocalization();
	const location = useLocation();
	const currentPath = location.pathname ?? "";

	return (
		<Stack
			sx={{
				flex: 1,
				justifyContent: "center",
				minHeight: "100%",
				mx: 1,
				pt: iosDetected ? 4 : 0,
				backgroundColor: "background.main",
			}}
		>
			<Helmet
				bodyAttributes={{
					class: "background--main",
				}}
			/>
			<Stack
				sx={{
					flex: 1,
					minHeight: "100%",
					pt: 2,
					pb: 10,
				}}
			>
				{children}
			</Stack>
			<AppBar
				position="fixed"
				sx={{
					top: "unset",
					bottom: 0,
					height: 72,
					overflowY: "hidden",
					backgroundColor: "background.main",
				}}
			>
				<BottomNavigation
					sx={{
						boxSizing: "content-box",
						height: 72,
						backgroundColor: "transparent",
						"& .MuiBottomNavigationAction-label": {
							marginTop: 1,
						},
					}}
				>
					<BottomNavigationAction
						component={Link}
						to="/overview"
						value="overview"
						label={localization.pages.overview}
						showLabel
						icon={<Home color={currentPath === "/overview" ? palette.secondary.main : "inherit"} />}
						sx={{ color: currentPath === "/overview" ? "secondary.main" : "text.main" }}
					/>
					<BottomNavigationAction
						component={Link}
						to="/investing"
						value="investing"
						label={localization.pages.investing}
						showLabel
						icon={
							<Investing color={currentPath.includes("/investing") ? palette.secondary.main : "inherit"} />
						}
						sx={{ color: currentPath.includes("/investing") ? "secondary.main" : "text.main" }}
					/>
					<BottomNavigationAction
						component={Link}
						to="/settings"
						value="settings"
						label={localization.pages.settings}
						showLabel
						icon={<Settings color={currentPath === "/settings" ? palette.secondary.main : "inherit"} />}
						sx={{ color: currentPath === "/settings" ? "secondary.main" : "text.main" }}
					/>
				</BottomNavigation>
			</AppBar>
		</Stack>
	);
};

export default Layout;
