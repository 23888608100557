import { createContext, type ReactElement, useContext, useEffect } from "react";

// local storage related helpers

const ORIGINAL_URL_LOCAL_STORAGE_KEY = "ORIGINAL_URL";

const saveOriginalURL = (url: string): void => {
	if (typeof window !== "undefined") {
		localStorage.setItem(ORIGINAL_URL_LOCAL_STORAGE_KEY, JSON.stringify(url));
	}
};

const getOriginalURL = (): string => {
	const url = typeof window !== "undefined" && localStorage.getItem(ORIGINAL_URL_LOCAL_STORAGE_KEY);
	return typeof url === "string" && typeof url !== "undefined" ? JSON.parse(url) : "";
};

// Context

const removeOriginalURL = (): void => {
	localStorage.removeItem(ORIGINAL_URL_LOCAL_STORAGE_KEY);
};

interface IProps {
	children: ReactElement;
}

export const KeepUrlContext = createContext<{
	url: string;
	removeOriginalURL: () => void;
}>({ url: "", removeOriginalURL });

export const useKeepUrl = () => useContext(KeepUrlContext);

export const KeepUrlProvider = ({ children }: IProps): JSX.Element => {
	useEffect(() => {
		const originalURL = window?.location?.pathname;
		const anchorURL = window?.location?.hash;

		if (anchorURL != null && anchorURL !== "") {
			saveOriginalURL(`${originalURL}${anchorURL}`);
		}
	}, []);

	return (
		<KeepUrlContext.Provider value={{ url: getOriginalURL(), removeOriginalURL }}>
			{children}
		</KeepUrlContext.Provider>
	);
};
