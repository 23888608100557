/* tslint:disable */
/* eslint-disable */
/**
 * VentureClub.RestApi2
 * 1.33.2.3, env PROD
 *
 * The version of the OpenAPI document: 1.0
 * Contact: it@spinware.cz
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Základní informace o uživateli
 * @export
 * @interface BaseUserInfoDataResultType
 */
export interface BaseUserInfoDataResultType {
    /**
     * 
     * @type {number}
     * @memberof BaseUserInfoDataResultType
     */
    resultCode?: number;
    /**
     * 
     * @type {boolean}
     * @memberof BaseUserInfoDataResultType
     */
    error?: boolean;
    /**
     * 
     * @type {string}
     * @memberof BaseUserInfoDataResultType
     */
    errorMessage?: string | null;
    /**
     * Id projektu
     * @type {number}
     * @memberof BaseUserInfoDataResultType
     */
    id?: number;
    /**
     * Titul
     * @type {string}
     * @memberof BaseUserInfoDataResultType
     */
    title?: string | null;
    /**
     * Jméno uživatele
     * @type {string}
     * @memberof BaseUserInfoDataResultType
     */
    givenName?: string | null;
    /**
     * Příjmení uživate
     * @type {string}
     * @memberof BaseUserInfoDataResultType
     */
    surname?: string | null;
    /**
     * Úroveň členství
     * @type {number}
     * @memberof BaseUserInfoDataResultType
     */
    membershipLevel?: number;
    /**
     * Souhlas NDA
     * @type {boolean}
     * @memberof BaseUserInfoDataResultType
     */
    agreeNda?: boolean;
    /**
     * Může investovat
     * @type {boolean}
     * @memberof BaseUserInfoDataResultType
     */
    canInvest?: boolean;
    /**
     * Kvalifikovaný investor
     * @type {boolean}
     * @memberof BaseUserInfoDataResultType
     */
    qInvestor?: boolean | null;
    /**
     * Číslo evidenční
     * @type {string}
     * @memberof BaseUserInfoDataResultType
     */
    readonly evidenceNumber?: string | null;
    /**
     * Jméno úrovně členství
     * @type {string}
     * @memberof BaseUserInfoDataResultType
     */
    readonly membershipName?: string | null;
    /**
     * Jméno firmy (pokud má k dispozici i investování na právnickou osobu)
     * @type {string}
     * @memberof BaseUserInfoDataResultType
     */
    readonly companyName?: string | null;
    /**
     * Preferovaný jazyk cs|en
     * @type {string}
     * @memberof BaseUserInfoDataResultType
     */
    readonly languageCode?: string | null;
}

/**
 * Check if a given object implements the BaseUserInfoDataResultType interface.
 */
export function instanceOfBaseUserInfoDataResultType(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function BaseUserInfoDataResultTypeFromJSON(json: any): BaseUserInfoDataResultType {
    return BaseUserInfoDataResultTypeFromJSONTyped(json, false);
}

export function BaseUserInfoDataResultTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): BaseUserInfoDataResultType {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'resultCode': !exists(json, 'resultCode') ? undefined : json['resultCode'],
        'error': !exists(json, 'error') ? undefined : json['error'],
        'errorMessage': !exists(json, 'errorMessage') ? undefined : json['errorMessage'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'givenName': !exists(json, 'givenName') ? undefined : json['givenName'],
        'surname': !exists(json, 'surname') ? undefined : json['surname'],
        'membershipLevel': !exists(json, 'membershipLevel') ? undefined : json['membershipLevel'],
        'agreeNda': !exists(json, 'agreeNda') ? undefined : json['agreeNda'],
        'canInvest': !exists(json, 'canInvest') ? undefined : json['canInvest'],
        'qInvestor': !exists(json, 'qInvestor') ? undefined : json['qInvestor'],
        'evidenceNumber': !exists(json, 'evidenceNumber') ? undefined : json['evidenceNumber'],
        'membershipName': !exists(json, 'membershipName') ? undefined : json['membershipName'],
        'companyName': !exists(json, 'companyName') ? undefined : json['companyName'],
        'languageCode': !exists(json, 'languageCode') ? undefined : json['languageCode'],
    };
}

export function BaseUserInfoDataResultTypeToJSON(value?: BaseUserInfoDataResultType | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'resultCode': value.resultCode,
        'error': value.error,
        'errorMessage': value.errorMessage,
        'id': value.id,
        'title': value.title,
        'givenName': value.givenName,
        'surname': value.surname,
        'membershipLevel': value.membershipLevel,
        'agreeNda': value.agreeNda,
        'canInvest': value.canInvest,
        'qInvestor': value.qInvestor,
    };
}

