/* tslint:disable */
/* eslint-disable */
/**
 * VentureClub.RestApi v1.33.2.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface JwtToken
 */
export interface JwtToken {
    /**
     * 
     * @type {number}
     * @memberof JwtToken
     */
    identityAccessId?: number;
    /**
     * 
     * @type {number}
     * @memberof JwtToken
     */
    registrationIdentityId?: number;
    /**
     * 
     * @type {number}
     * @memberof JwtToken
     */
    employeeIdentityId?: number;
    /**
     * 
     * @type {number}
     * @memberof JwtToken
     */
    applicationIdentityId?: number;
    /**
     * 
     * @type {string}
     * @memberof JwtToken
     */
    alg?: string;
    /**
     * 
     * @type {string}
     * @memberof JwtToken
     */
    typ?: string;
    /**
     * 
     * @type {string}
     * @memberof JwtToken
     */
    iss?: string;
    /**
     * 
     * @type {string}
     * @memberof JwtToken
     */
    sub?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof JwtToken
     */
    aud?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof JwtToken
     */
    azp?: string;
    /**
     * 
     * @type {string}
     * @memberof JwtToken
     */
    jti?: string;
    /**
     * 
     * @type {string}
     * @memberof JwtToken
     */
    purpose?: string;
    /**
     * 
     * @type {string}
     * @memberof JwtToken
     */
    scope?: string;
    /**
     * 
     * @type {Date}
     * @memberof JwtToken
     */
    authTime?: Date;
    /**
     * 
     * @type {Date}
     * @memberof JwtToken
     */
    iat?: Date;
    /**
     * 
     * @type {Date}
     * @memberof JwtToken
     */
    nbf?: Date;
    /**
     * 
     * @type {Date}
     * @memberof JwtToken
     */
    exp?: Date;
    /**
     * 
     * @type {string}
     * @memberof JwtToken
     */
    nonce?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof JwtToken
     */
    role?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof JwtToken
     */
    identProv?: string;
    /**
     * 
     * @type {boolean}
     * @memberof JwtToken
     */
    isValid?: boolean;
}

/**
 * Check if a given object implements the JwtToken interface.
 */
export function instanceOfJwtToken(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function JwtTokenFromJSON(json: any): JwtToken {
    return JwtTokenFromJSONTyped(json, false);
}

export function JwtTokenFromJSONTyped(json: any, ignoreDiscriminator: boolean): JwtToken {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'identityAccessId': !exists(json, 'identityAccessId') ? undefined : json['identityAccessId'],
        'registrationIdentityId': !exists(json, 'registrationIdentityId') ? undefined : json['registrationIdentityId'],
        'employeeIdentityId': !exists(json, 'employeeIdentityId') ? undefined : json['employeeIdentityId'],
        'applicationIdentityId': !exists(json, 'applicationIdentityId') ? undefined : json['applicationIdentityId'],
        'alg': !exists(json, 'Alg') ? undefined : json['Alg'],
        'typ': !exists(json, 'Typ') ? undefined : json['Typ'],
        'iss': !exists(json, 'Iss') ? undefined : json['Iss'],
        'sub': !exists(json, 'Sub') ? undefined : json['Sub'],
        'aud': !exists(json, 'Aud') ? undefined : json['Aud'],
        'azp': !exists(json, 'Azp') ? undefined : json['Azp'],
        'jti': !exists(json, 'Jti') ? undefined : json['Jti'],
        'purpose': !exists(json, 'Purpose') ? undefined : json['Purpose'],
        'scope': !exists(json, 'Scope') ? undefined : json['Scope'],
        'authTime': !exists(json, 'Auth_time') ? undefined : (new Date(json['Auth_time'])),
        'iat': !exists(json, 'Iat') ? undefined : (new Date(json['Iat'])),
        'nbf': !exists(json, 'Nbf') ? undefined : (new Date(json['Nbf'])),
        'exp': !exists(json, 'Exp') ? undefined : (new Date(json['Exp'])),
        'nonce': !exists(json, 'Nonce') ? undefined : json['Nonce'],
        'role': !exists(json, 'Role') ? undefined : json['Role'],
        'identProv': !exists(json, 'Ident_prov') ? undefined : json['Ident_prov'],
        'isValid': !exists(json, 'IsValid') ? undefined : json['IsValid'],
    };
}

export function JwtTokenToJSON(value?: JwtToken | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'identityAccessId': value.identityAccessId,
        'registrationIdentityId': value.registrationIdentityId,
        'employeeIdentityId': value.employeeIdentityId,
        'applicationIdentityId': value.applicationIdentityId,
        'Alg': value.alg,
        'Typ': value.typ,
        'Iss': value.iss,
        'Sub': value.sub,
        'Aud': value.aud,
        'Azp': value.azp,
        'Jti': value.jti,
        'Purpose': value.purpose,
        'Scope': value.scope,
        'Auth_time': value.authTime === undefined ? undefined : (value.authTime.toISOString()),
        'Iat': value.iat === undefined ? undefined : (value.iat.toISOString()),
        'Nbf': value.nbf === undefined ? undefined : (value.nbf.toISOString()),
        'Exp': value.exp === undefined ? undefined : (value.exp.toISOString()),
        'Nonce': value.nonce,
        'Role': value.role,
        'Ident_prov': value.identProv,
        'IsValid': value.isValid,
    };
}

