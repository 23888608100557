/* tslint:disable */
/* eslint-disable */
/**
 * VentureClub.RestApi v1.33.2.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PaymentPrescription } from './PaymentPrescription';
import {
    PaymentPrescriptionFromJSON,
    PaymentPrescriptionFromJSONTyped,
    PaymentPrescriptionToJSON,
} from './PaymentPrescription';

/**
 * 
 * @export
 * @interface PagedListResultDataTypePaymentPrescription
 */
export interface PagedListResultDataTypePaymentPrescription {
    /**
     * 
     * @type {number}
     * @memberof PagedListResultDataTypePaymentPrescription
     */
    totalCount?: number;
    /**
     * 
     * @type {number}
     * @memberof PagedListResultDataTypePaymentPrescription
     */
    pageSize?: number;
    /**
     * 
     * @type {number}
     * @memberof PagedListResultDataTypePaymentPrescription
     */
    currentPage?: number;
    /**
     * 
     * @type {number}
     * @memberof PagedListResultDataTypePaymentPrescription
     */
    totalPages?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PagedListResultDataTypePaymentPrescription
     */
    readonly hasNext?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PagedListResultDataTypePaymentPrescription
     */
    readonly hasPrevious?: boolean;
    /**
     * 
     * @type {Array<PaymentPrescription>}
     * @memberof PagedListResultDataTypePaymentPrescription
     */
    items?: Array<PaymentPrescription>;
    /**
     * 
     * @type {boolean}
     * @memberof PagedListResultDataTypePaymentPrescription
     */
    error?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PagedListResultDataTypePaymentPrescription
     */
    errorMessage?: string;
}

/**
 * Check if a given object implements the PagedListResultDataTypePaymentPrescription interface.
 */
export function instanceOfPagedListResultDataTypePaymentPrescription(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PagedListResultDataTypePaymentPrescriptionFromJSON(json: any): PagedListResultDataTypePaymentPrescription {
    return PagedListResultDataTypePaymentPrescriptionFromJSONTyped(json, false);
}

export function PagedListResultDataTypePaymentPrescriptionFromJSONTyped(json: any, ignoreDiscriminator: boolean): PagedListResultDataTypePaymentPrescription {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'totalCount': !exists(json, 'TotalCount') ? undefined : json['TotalCount'],
        'pageSize': !exists(json, 'PageSize') ? undefined : json['PageSize'],
        'currentPage': !exists(json, 'CurrentPage') ? undefined : json['CurrentPage'],
        'totalPages': !exists(json, 'TotalPages') ? undefined : json['TotalPages'],
        'hasNext': !exists(json, 'HasNext') ? undefined : json['HasNext'],
        'hasPrevious': !exists(json, 'HasPrevious') ? undefined : json['HasPrevious'],
        'items': !exists(json, 'Items') ? undefined : ((json['Items'] as Array<any>).map(PaymentPrescriptionFromJSON)),
        'error': !exists(json, 'Error') ? undefined : json['Error'],
        'errorMessage': !exists(json, 'ErrorMessage') ? undefined : json['ErrorMessage'],
    };
}

export function PagedListResultDataTypePaymentPrescriptionToJSON(value?: PagedListResultDataTypePaymentPrescription | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'TotalCount': value.totalCount,
        'PageSize': value.pageSize,
        'CurrentPage': value.currentPage,
        'TotalPages': value.totalPages,
        'Items': value.items === undefined ? undefined : ((value.items as Array<any>).map(PaymentPrescriptionToJSON)),
        'Error': value.error,
        'ErrorMessage': value.errorMessage,
    };
}

