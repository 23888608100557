/* tslint:disable */
/* eslint-disable */
/**
 * VentureClub.RestApi2
 * 1.33.2.3, env PROD
 *
 * The version of the OpenAPI document: 1.0
 * Contact: it@spinware.cz
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PerfKpiValue } from './PerfKpiValue';
import {
    PerfKpiValueFromJSON,
    PerfKpiValueFromJSONTyped,
    PerfKpiValueToJSON,
} from './PerfKpiValue';

/**
 * Perioda výkonnostních KPI projektu
 * @export
 * @interface PerfKpiPeriod
 */
export interface PerfKpiPeriod {
    /**
     * Datum periody
     * @type {Date}
     * @memberof PerfKpiPeriod
     */
    date?: Date;
    /**
     * AVG všech výkonnostních KPI za období
     * @type {number}
     * @memberof PerfKpiPeriod
     */
    kpiValuePrc?: number | null;
    /**
     * Výkonnostní KPI za období s hodnotami
     * @type {Array<PerfKpiValue>}
     * @memberof PerfKpiPeriod
     */
    kpisValues?: Array<PerfKpiValue> | null;
}

/**
 * Check if a given object implements the PerfKpiPeriod interface.
 */
export function instanceOfPerfKpiPeriod(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PerfKpiPeriodFromJSON(json: any): PerfKpiPeriod {
    return PerfKpiPeriodFromJSONTyped(json, false);
}

export function PerfKpiPeriodFromJSONTyped(json: any, ignoreDiscriminator: boolean): PerfKpiPeriod {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'date': !exists(json, 'date') ? undefined : (new Date(json['date'])),
        'kpiValuePrc': !exists(json, 'kpiValuePrc') ? undefined : json['kpiValuePrc'],
        'kpisValues': !exists(json, 'kpisValues') ? undefined : (json['kpisValues'] === null ? null : (json['kpisValues'] as Array<any>).map(PerfKpiValueFromJSON)),
    };
}

export function PerfKpiPeriodToJSON(value?: PerfKpiPeriod | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'date': value.date === undefined ? undefined : (value.date.toISOString()),
        'kpiValuePrc': value.kpiValuePrc,
        'kpisValues': value.kpisValues === undefined ? undefined : (value.kpisValues === null ? null : (value.kpisValues as Array<any>).map(PerfKpiValueToJSON)),
    };
}

