/* tslint:disable */
/* eslint-disable */
/**
 * VentureClub.RestApi v1.33.2.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ResultDataTypeVersionInfo,
} from '../models/index';
import {
    ResultDataTypeVersionInfoFromJSON,
    ResultDataTypeVersionInfoToJSON,
} from '../models/index';

export interface SystemIsValidVersionRequest {
    requiredApiVersion: string;
}

export interface SystemUpdateFeTextsRequest {
    envCode: string;
}

/**
 * 
 */
export class SystemApi extends runtime.BaseAPI {

    /**
     * Informace o verzi aplikace
     */
    async systemGetVersionRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResultDataTypeVersionInfo>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/system/ver`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResultDataTypeVersionInfoFromJSON(jsonValue));
    }

    /**
     * Informace o verzi aplikace
     */
    async systemGetVersion(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResultDataTypeVersionInfo> {
        const response = await this.systemGetVersionRaw(initOverrides);
        return await response.value();
    }

    /**
     * Zkontroluje zda je verze API aktuální požadovaná
     */
    async systemIsValidVersionRaw(requestParameters: SystemIsValidVersionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters.requiredApiVersion === null || requestParameters.requiredApiVersion === undefined) {
            throw new runtime.RequiredError('requiredApiVersion','Required parameter requestParameters.requiredApiVersion was null or undefined when calling systemIsValidVersion.');
        }

        const queryParameters: any = {};

        if (requestParameters.requiredApiVersion !== undefined) {
            queryParameters['requiredApiVersion'] = requestParameters.requiredApiVersion;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/system/isvalidver`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Zkontroluje zda je verze API aktuální požadovaná
     */
    async systemIsValidVersion(requestParameters: SystemIsValidVersionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.systemIsValidVersionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Nahrátí aktualizovaných FE textů
     */
    async systemUpdateFeTextsRaw(requestParameters: SystemUpdateFeTextsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters.envCode === null || requestParameters.envCode === undefined) {
            throw new runtime.RequiredError('envCode','Required parameter requestParameters.envCode was null or undefined when calling systemUpdateFeTexts.');
        }

        const queryParameters: any = {};

        if (requestParameters.envCode !== undefined) {
            queryParameters['envCode'] = requestParameters.envCode;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/system/updatetexts`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Nahrátí aktualizovaných FE textů
     */
    async systemUpdateFeTexts(requestParameters: SystemUpdateFeTextsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.systemUpdateFeTextsRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
