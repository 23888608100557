import { useLocalization } from "../../context/LanguageProvider";
import PureErrorBody from "./PureErrorBody";

const ErrorBody = ({ title }: { title?: string | null | undefined }): JSX.Element => {
	const { localization } = useLocalization();
	return (
		<PureErrorBody
			title={title}
			subtitle={localization.global.unexpectedError}
			reload={localization.global.tryAgain}
			logout={localization.settings.logout}
		/>
	);
};

export default ErrorBody;
