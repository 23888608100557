const typography = {
	fontFamily: [
		"Fira Sans",
		"Open Sans",
		"Sans-serif",
		"-apple-system",
		"BlinkMacSystemFont",
		"Segoe UI",
		"Roboto",
		"Oxygen",
		"Ubuntu",
		"Cantarell",
		"Fira Sans",
		"Droid Sans",
		"Helvetica Neue",
		"sans-serif",
	].join(","),
};

export default typography;
