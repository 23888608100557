/* tslint:disable */
/* eslint-disable */
/**
 * VentureClub.RestApi2
 * 1.33.2.3, env PROD
 *
 * The version of the OpenAPI document: 1.0
 * Contact: it@spinware.cz
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Odstavec teaseru projektu
 * @export
 * @interface TeaserParagraph
 */
export interface TeaserParagraph {
    /**
     * Id odstavce teaseru
     * @type {number}
     * @memberof TeaserParagraph
     */
    id?: number;
    /**
     * Min. úroveň zobrazení
     * @type {number}
     * @memberof TeaserParagraph
     */
    minLevel?: number;
    /**
     * Typ id
     * @type {number}
     * @memberof TeaserParagraph
     */
    typeId?: number;
    /**
     * Kód typu
     * @type {string}
     * @memberof TeaserParagraph
     */
    typeCode?: string | null;
    /**
     * Obsah k zobrazení
     * @type {string}
     * @memberof TeaserParagraph
     */
    content?: string | null;
    /**
     * Rozšířená data pro specifické užití - např. graf
     * @type {string}
     * @memberof TeaserParagraph
     */
    data?: string | null;
    /**
     * Pořadí zobrazení
     * @type {number}
     * @memberof TeaserParagraph
     */
    order?: number;
    /**
     * Zobrazit nahoře
     * @type {boolean}
     * @memberof TeaserParagraph
     */
    priorityShow?: boolean;
    /**
     * DT vytvoření odstavce
     * @type {Date}
     * @memberof TeaserParagraph
     */
    created?: Date;
}

/**
 * Check if a given object implements the TeaserParagraph interface.
 */
export function instanceOfTeaserParagraph(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function TeaserParagraphFromJSON(json: any): TeaserParagraph {
    return TeaserParagraphFromJSONTyped(json, false);
}

export function TeaserParagraphFromJSONTyped(json: any, ignoreDiscriminator: boolean): TeaserParagraph {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'minLevel': !exists(json, 'minLevel') ? undefined : json['minLevel'],
        'typeId': !exists(json, 'typeId') ? undefined : json['typeId'],
        'typeCode': !exists(json, 'typeCode') ? undefined : json['typeCode'],
        'content': !exists(json, 'content') ? undefined : json['content'],
        'data': !exists(json, 'data') ? undefined : json['data'],
        'order': !exists(json, 'order') ? undefined : json['order'],
        'priorityShow': !exists(json, 'priorityShow') ? undefined : json['priorityShow'],
        'created': !exists(json, 'created') ? undefined : (new Date(json['created'])),
    };
}

export function TeaserParagraphToJSON(value?: TeaserParagraph | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'minLevel': value.minLevel,
        'typeId': value.typeId,
        'typeCode': value.typeCode,
        'content': value.content,
        'data': value.data,
        'order': value.order,
        'priorityShow': value.priorityShow,
        'created': value.created === undefined ? undefined : (value.created.toISOString()),
    };
}

