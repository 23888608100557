/* tslint:disable */
/* eslint-disable */
/**
 * VentureClub.RestApi v1.33.2.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { VersionInfo } from './VersionInfo';
import {
    VersionInfoFromJSON,
    VersionInfoFromJSONTyped,
    VersionInfoToJSON,
} from './VersionInfo';

/**
 * 
 * @export
 * @interface ResultDataTypeVersionInfo
 */
export interface ResultDataTypeVersionInfo {
    /**
     * 
     * @type {VersionInfo}
     * @memberof ResultDataTypeVersionInfo
     */
    data?: VersionInfo;
    /**
     * 
     * @type {boolean}
     * @memberof ResultDataTypeVersionInfo
     */
    error?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ResultDataTypeVersionInfo
     */
    errorMessage?: string;
}

/**
 * Check if a given object implements the ResultDataTypeVersionInfo interface.
 */
export function instanceOfResultDataTypeVersionInfo(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ResultDataTypeVersionInfoFromJSON(json: any): ResultDataTypeVersionInfo {
    return ResultDataTypeVersionInfoFromJSONTyped(json, false);
}

export function ResultDataTypeVersionInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ResultDataTypeVersionInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': !exists(json, 'Data') ? undefined : VersionInfoFromJSON(json['Data']),
        'error': !exists(json, 'Error') ? undefined : json['Error'],
        'errorMessage': !exists(json, 'ErrorMessage') ? undefined : json['ErrorMessage'],
    };
}

export function ResultDataTypeVersionInfoToJSON(value?: ResultDataTypeVersionInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Data': VersionInfoToJSON(value.data),
        'Error': value.error,
        'ErrorMessage': value.errorMessage,
    };
}

