/* tslint:disable */
/* eslint-disable */
/**
 * VentureClub.RestApi v1.33.2.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CAresBasicInfo } from './CAresBasicInfo';
import {
    CAresBasicInfoFromJSON,
    CAresBasicInfoFromJSONTyped,
    CAresBasicInfoToJSON,
} from './CAresBasicInfo';

/**
 * 
 * @export
 * @interface ResultDataTypeCAresBasicInfo
 */
export interface ResultDataTypeCAresBasicInfo {
    /**
     * 
     * @type {CAresBasicInfo}
     * @memberof ResultDataTypeCAresBasicInfo
     */
    data?: CAresBasicInfo;
    /**
     * 
     * @type {boolean}
     * @memberof ResultDataTypeCAresBasicInfo
     */
    error?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ResultDataTypeCAresBasicInfo
     */
    errorMessage?: string;
}

/**
 * Check if a given object implements the ResultDataTypeCAresBasicInfo interface.
 */
export function instanceOfResultDataTypeCAresBasicInfo(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ResultDataTypeCAresBasicInfoFromJSON(json: any): ResultDataTypeCAresBasicInfo {
    return ResultDataTypeCAresBasicInfoFromJSONTyped(json, false);
}

export function ResultDataTypeCAresBasicInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ResultDataTypeCAresBasicInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': !exists(json, 'Data') ? undefined : CAresBasicInfoFromJSON(json['Data']),
        'error': !exists(json, 'Error') ? undefined : json['Error'],
        'errorMessage': !exists(json, 'ErrorMessage') ? undefined : json['ErrorMessage'],
    };
}

export function ResultDataTypeCAresBasicInfoToJSON(value?: ResultDataTypeCAresBasicInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Data': CAresBasicInfoToJSON(value.data),
        'Error': value.error,
        'ErrorMessage': value.errorMessage,
    };
}

