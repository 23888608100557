/* tslint:disable */
/* eslint-disable */
/**
 * VentureClub.RestApi2
 * 1.33.2.3, env PROD
 *
 * The version of the OpenAPI document: 1.0
 * Contact: it@spinware.cz
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { KpiDefinitionResultDataType } from './KpiDefinitionResultDataType';
import {
    KpiDefinitionResultDataTypeFromJSON,
    KpiDefinitionResultDataTypeFromJSONTyped,
    KpiDefinitionResultDataTypeToJSON,
} from './KpiDefinitionResultDataType';

/**
 * Seznam definic KPI
 * @export
 * @interface KpiDefinitionListResultDataType
 */
export interface KpiDefinitionListResultDataType {
    /**
     * 
     * @type {number}
     * @memberof KpiDefinitionListResultDataType
     */
    resultCode?: number;
    /**
     * 
     * @type {boolean}
     * @memberof KpiDefinitionListResultDataType
     */
    error?: boolean;
    /**
     * 
     * @type {string}
     * @memberof KpiDefinitionListResultDataType
     */
    errorMessage?: string | null;
    /**
     * 
     * @type {Array<KpiDefinitionResultDataType>}
     * @memberof KpiDefinitionListResultDataType
     */
    items?: Array<KpiDefinitionResultDataType> | null;
}

/**
 * Check if a given object implements the KpiDefinitionListResultDataType interface.
 */
export function instanceOfKpiDefinitionListResultDataType(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function KpiDefinitionListResultDataTypeFromJSON(json: any): KpiDefinitionListResultDataType {
    return KpiDefinitionListResultDataTypeFromJSONTyped(json, false);
}

export function KpiDefinitionListResultDataTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): KpiDefinitionListResultDataType {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'resultCode': !exists(json, 'resultCode') ? undefined : json['resultCode'],
        'error': !exists(json, 'error') ? undefined : json['error'],
        'errorMessage': !exists(json, 'errorMessage') ? undefined : json['errorMessage'],
        'items': !exists(json, 'items') ? undefined : (json['items'] === null ? null : (json['items'] as Array<any>).map(KpiDefinitionResultDataTypeFromJSON)),
    };
}

export function KpiDefinitionListResultDataTypeToJSON(value?: KpiDefinitionListResultDataType | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'resultCode': value.resultCode,
        'error': value.error,
        'errorMessage': value.errorMessage,
        'items': value.items === undefined ? undefined : (value.items === null ? null : (value.items as Array<any>).map(KpiDefinitionResultDataTypeToJSON)),
    };
}

