/* tslint:disable */
/* eslint-disable */
/**
 * VentureClub.RestApi v1.33.2.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ApplicationClient,
  AuthenticationRequest,
  IdentityToken,
  JwtToken,
  JwtTokenRequest,
  LogoutRequest,
} from '../models/index';
import {
    ApplicationClientFromJSON,
    ApplicationClientToJSON,
    AuthenticationRequestFromJSON,
    AuthenticationRequestToJSON,
    IdentityTokenFromJSON,
    IdentityTokenToJSON,
    JwtTokenFromJSON,
    JwtTokenToJSON,
    JwtTokenRequestFromJSON,
    JwtTokenRequestToJSON,
    LogoutRequestFromJSON,
    LogoutRequestToJSON,
} from '../models/index';

export interface AuthIntrospectRequest {
    token: JwtTokenRequest;
}

export interface AuthLogoutRequest {
    logoutRequest: LogoutRequest;
}

export interface AuthTokenRequest {
    authRequest: AuthenticationRequest;
}

/**
 * 
 */
export class AuthApi extends runtime.BaseAPI {

    /**
     * Getting basic information about a logged-in application
     */
    async authEndUserAppInfoRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ApplicationClient>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/auth/application`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApplicationClientFromJSON(jsonValue));
    }

    /**
     * Getting basic information about a logged-in application
     */
    async authEndUserAppInfo(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ApplicationClient> {
        const response = await this.authEndUserAppInfoRaw(initOverrides);
        return await response.value();
    }

    /**
     * token introspect
     */
    async authIntrospectRaw(requestParameters: AuthIntrospectRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<JwtToken>> {
        if (requestParameters.token === null || requestParameters.token === undefined) {
            throw new runtime.RequiredError('token','Required parameter requestParameters.token was null or undefined when calling authIntrospect.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v1/auth/introspect`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: JwtTokenRequestToJSON(requestParameters.token),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => JwtTokenFromJSON(jsonValue));
    }

    /**
     * token introspect
     */
    async authIntrospect(requestParameters: AuthIntrospectRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<JwtToken> {
        const response = await this.authIntrospectRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Logout identity
     */
    async authLogoutRaw(requestParameters: AuthLogoutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters.logoutRequest === null || requestParameters.logoutRequest === undefined) {
            throw new runtime.RequiredError('logoutRequest','Required parameter requestParameters.logoutRequest was null or undefined when calling authLogout.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v1/auth/logout`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LogoutRequestToJSON(requestParameters.logoutRequest),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Logout identity
     */
    async authLogout(requestParameters: AuthLogoutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.authLogoutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * get Identity token (AuthN)
     */
    async authTokenRaw(requestParameters: AuthTokenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IdentityToken>> {
        if (requestParameters.authRequest === null || requestParameters.authRequest === undefined) {
            throw new runtime.RequiredError('authRequest','Required parameter requestParameters.authRequest was null or undefined when calling authToken.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v1/auth/token`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AuthenticationRequestToJSON(requestParameters.authRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IdentityTokenFromJSON(jsonValue));
    }

    /**
     * get Identity token (AuthN)
     */
    async authToken(requestParameters: AuthTokenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IdentityToken> {
        const response = await this.authTokenRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
