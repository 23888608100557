/* tslint:disable */
/* eslint-disable */
/**
 * VentureClub.RestApi v1.33.2.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ResultDataTypeUInt32,
} from '../models/index';
import {
    ResultDataTypeUInt32FromJSON,
    ResultDataTypeUInt32ToJSON,
} from '../models/index';

export interface MembershipCreateCallRequest {
    regId: number;
    reqLevelId: number;
    invoiceAsPo?: boolean;
    agreeToShow?: boolean;
    silent?: boolean;
}

/**
 * 
 */
export class MembershipApi extends runtime.BaseAPI {

    /**
     * Vytvoření platebního příkazu k úhradě členství
     */
    async membershipCreateCallRaw(requestParameters: MembershipCreateCallRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResultDataTypeUInt32>> {
        if (requestParameters.regId === null || requestParameters.regId === undefined) {
            throw new runtime.RequiredError('regId','Required parameter requestParameters.regId was null or undefined when calling membershipCreateCall.');
        }

        if (requestParameters.reqLevelId === null || requestParameters.reqLevelId === undefined) {
            throw new runtime.RequiredError('reqLevelId','Required parameter requestParameters.reqLevelId was null or undefined when calling membershipCreateCall.');
        }

        const queryParameters: any = {};

        if (requestParameters.regId !== undefined) {
            queryParameters['regId'] = requestParameters.regId;
        }

        if (requestParameters.reqLevelId !== undefined) {
            queryParameters['reqLevelId'] = requestParameters.reqLevelId;
        }

        if (requestParameters.invoiceAsPo !== undefined) {
            queryParameters['invoiceAsPo'] = requestParameters.invoiceAsPo;
        }

        if (requestParameters.agreeToShow !== undefined) {
            queryParameters['agreeToShow'] = requestParameters.agreeToShow;
        }

        if (requestParameters.silent !== undefined) {
            queryParameters['silent'] = requestParameters.silent;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/membership/createcall`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResultDataTypeUInt32FromJSON(jsonValue));
    }

    /**
     * Vytvoření platebního příkazu k úhradě členství
     */
    async membershipCreateCall(requestParameters: MembershipCreateCallRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResultDataTypeUInt32> {
        const response = await this.membershipCreateCallRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
