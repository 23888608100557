/* tslint:disable */
/* eslint-disable */
/**
 * VentureClub.RestApi2
 * 1.33.2.3, env PROD
 *
 * The version of the OpenAPI document: 1.0
 * Contact: it@spinware.cz
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Příslib
 * @export
 * @interface InvestmentPromise
 */
export interface InvestmentPromise {
    /**
     * Id příslibu
     * @type {number}
     * @memberof InvestmentPromise
     */
    id?: number;
    /**
     * Id kampaně
     * @type {number}
     * @memberof InvestmentPromise
     */
    readonly campaignId?: number;
    /**
     * Id investiční příležitosti - nastavení příležitosti
     * @type {number}
     * @memberof InvestmentPromise
     */
    investOpId?: number | null;
    /**
     * Přislíbeno
     * @type {number}
     * @memberof InvestmentPromise
     */
    promised?: number;
    /**
     * Započteno
     * @type {number}
     * @memberof InvestmentPromise
     */
    counted?: number;
    /**
     * Datuma čas příslibu
     * @type {Date}
     * @memberof InvestmentPromise
     */
    created?: Date;
    /**
     * Investováno na právnickou osobu
     * @type {boolean}
     * @memberof InvestmentPromise
     */
    readonly company?: boolean;
    /**
     * Typ investice
     * @type {number}
     * @memberof InvestmentPromise
     */
    readonly typeId?: number;
}

/**
 * Check if a given object implements the InvestmentPromise interface.
 */
export function instanceOfInvestmentPromise(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function InvestmentPromiseFromJSON(json: any): InvestmentPromise {
    return InvestmentPromiseFromJSONTyped(json, false);
}

export function InvestmentPromiseFromJSONTyped(json: any, ignoreDiscriminator: boolean): InvestmentPromise {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'campaignId': !exists(json, 'campaignId') ? undefined : json['campaignId'],
        'investOpId': !exists(json, 'investOpId') ? undefined : json['investOpId'],
        'promised': !exists(json, 'promised') ? undefined : json['promised'],
        'counted': !exists(json, 'counted') ? undefined : json['counted'],
        'created': !exists(json, 'created') ? undefined : (new Date(json['created'])),
        'company': !exists(json, 'company') ? undefined : json['company'],
        'typeId': !exists(json, 'typeId') ? undefined : json['typeId'],
    };
}

export function InvestmentPromiseToJSON(value?: InvestmentPromise | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'investOpId': value.investOpId,
        'promised': value.promised,
        'counted': value.counted,
        'created': value.created === undefined ? undefined : (value.created.toISOString()),
    };
}

