/* tslint:disable */
/* eslint-disable */
/**
 * VentureClub.RestApi2
 * 1.33.2.3, env PROD
 *
 * The version of the OpenAPI document: 1.0
 * Contact: it@spinware.cz
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ProjectNews } from './ProjectNews';
import {
    ProjectNewsFromJSON,
    ProjectNewsFromJSONTyped,
    ProjectNewsToJSON,
} from './ProjectNews';

/**
 * 
 * @export
 * @interface ProjectNewsList
 */
export interface ProjectNewsList {
    /**
     * 
     * @type {number}
     * @memberof ProjectNewsList
     */
    resultCode?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectNewsList
     */
    error?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ProjectNewsList
     */
    errorMessage?: string | null;
    /**
     * 
     * @type {Array<ProjectNews>}
     * @memberof ProjectNewsList
     */
    news?: Array<ProjectNews> | null;
}

/**
 * Check if a given object implements the ProjectNewsList interface.
 */
export function instanceOfProjectNewsList(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ProjectNewsListFromJSON(json: any): ProjectNewsList {
    return ProjectNewsListFromJSONTyped(json, false);
}

export function ProjectNewsListFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectNewsList {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'resultCode': !exists(json, 'resultCode') ? undefined : json['resultCode'],
        'error': !exists(json, 'error') ? undefined : json['error'],
        'errorMessage': !exists(json, 'errorMessage') ? undefined : json['errorMessage'],
        'news': !exists(json, 'news') ? undefined : (json['news'] === null ? null : (json['news'] as Array<any>).map(ProjectNewsFromJSON)),
    };
}

export function ProjectNewsListToJSON(value?: ProjectNewsList | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'resultCode': value.resultCode,
        'error': value.error,
        'errorMessage': value.errorMessage,
        'news': value.news === undefined ? undefined : (value.news === null ? null : (value.news as Array<any>).map(ProjectNewsToJSON)),
    };
}

