/* tslint:disable */
/* eslint-disable */
/**
 * VentureClub.RestApi2
 * 1.33.2.3, env PROD
 *
 * The version of the OpenAPI document: 1.0
 * Contact: it@spinware.cz
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { InvestmentPromise } from './InvestmentPromise';
import {
    InvestmentPromiseFromJSON,
    InvestmentPromiseFromJSONTyped,
    InvestmentPromiseToJSON,
} from './InvestmentPromise';

/**
 * Základní informace o projektu
 * @export
 * @interface UserInvestmentsBaseResultDataType
 */
export interface UserInvestmentsBaseResultDataType {
    /**
     * 
     * @type {number}
     * @memberof UserInvestmentsBaseResultDataType
     */
    resultCode?: number;
    /**
     * 
     * @type {boolean}
     * @memberof UserInvestmentsBaseResultDataType
     */
    error?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserInvestmentsBaseResultDataType
     */
    errorMessage?: string | null;
    /**
     * Id projektu
     * @type {number}
     * @memberof UserInvestmentsBaseResultDataType
     */
    projectId?: number;
    /**
     * Jméno projektu
     * @type {string}
     * @memberof UserInvestmentsBaseResultDataType
     */
    projectName?: string | null;
    /**
     * Celková přislíbená částka (SUM list)
     * @type {number}
     * @memberof UserInvestmentsBaseResultDataType
     */
    totalPromised?: number;
    /**
     * Celkově započtená částka (SUM list)
     * @type {number}
     * @memberof UserInvestmentsBaseResultDataType
     */
    totalCounted?: number;
    /**
     * Investiční přísliby
     * @type {Array<InvestmentPromise>}
     * @memberof UserInvestmentsBaseResultDataType
     */
    promises?: Array<InvestmentPromise> | null;
}

/**
 * Check if a given object implements the UserInvestmentsBaseResultDataType interface.
 */
export function instanceOfUserInvestmentsBaseResultDataType(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function UserInvestmentsBaseResultDataTypeFromJSON(json: any): UserInvestmentsBaseResultDataType {
    return UserInvestmentsBaseResultDataTypeFromJSONTyped(json, false);
}

export function UserInvestmentsBaseResultDataTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserInvestmentsBaseResultDataType {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'resultCode': !exists(json, 'resultCode') ? undefined : json['resultCode'],
        'error': !exists(json, 'error') ? undefined : json['error'],
        'errorMessage': !exists(json, 'errorMessage') ? undefined : json['errorMessage'],
        'projectId': !exists(json, 'projectId') ? undefined : json['projectId'],
        'projectName': !exists(json, 'projectName') ? undefined : json['projectName'],
        'totalPromised': !exists(json, 'totalPromised') ? undefined : json['totalPromised'],
        'totalCounted': !exists(json, 'totalCounted') ? undefined : json['totalCounted'],
        'promises': !exists(json, 'promises') ? undefined : (json['promises'] === null ? null : (json['promises'] as Array<any>).map(InvestmentPromiseFromJSON)),
    };
}

export function UserInvestmentsBaseResultDataTypeToJSON(value?: UserInvestmentsBaseResultDataType | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'resultCode': value.resultCode,
        'error': value.error,
        'errorMessage': value.errorMessage,
        'projectId': value.projectId,
        'projectName': value.projectName,
        'totalPromised': value.totalPromised,
        'totalCounted': value.totalCounted,
        'promises': value.promises === undefined ? undefined : (value.promises === null ? null : (value.promises as Array<any>).map(InvestmentPromiseToJSON)),
    };
}

