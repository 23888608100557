/* tslint:disable */
/* eslint-disable */
/**
 * VentureClub.RestApi2
 * 1.33.2.3, env PROD
 *
 * The version of the OpenAPI document: 1.0
 * Contact: it@spinware.cz
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  InvoiceData,
  InvoiceResultDataType,
  ResultDataType,
} from '../models/index';
import {
    InvoiceDataFromJSON,
    InvoiceDataToJSON,
    InvoiceResultDataTypeFromJSON,
    InvoiceResultDataTypeToJSON,
    ResultDataTypeFromJSON,
    ResultDataTypeToJSON,
} from '../models/index';

export interface ApiV1UserManagementInvoicesCreatePostRequest {
    invoiceData?: InvoiceData;
}

export interface ApiV1UserManagementInvoicesIssuerCodeIssuedInvoicesNumberSeriesGetRequest {
    issuerCode: string;
}

/**
 * 
 */
export class InvoicesApi extends runtime.BaseAPI {

    /**
     * Create external invoice
     */
    async apiV1UserManagementInvoicesCreatePostRaw(requestParameters: ApiV1UserManagementInvoicesCreatePostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<InvoiceResultDataType>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json; x-api-version=1.0';

        const response = await this.request({
            path: `/api/v1/user-management/invoices/create`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InvoiceDataToJSON(requestParameters.invoiceData),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InvoiceResultDataTypeFromJSON(jsonValue));
    }

    /**
     * Create external invoice
     */
    async apiV1UserManagementInvoicesCreatePost(requestParameters: ApiV1UserManagementInvoicesCreatePostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<InvoiceResultDataType> {
        const response = await this.apiV1UserManagementInvoicesCreatePostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Číselné řady faktur
     */
    async apiV1UserManagementInvoicesIssuerCodeIssuedInvoicesNumberSeriesGetRaw(requestParameters: ApiV1UserManagementInvoicesIssuerCodeIssuedInvoicesNumberSeriesGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters.issuerCode === null || requestParameters.issuerCode === undefined) {
            throw new runtime.RequiredError('issuerCode','Required parameter requestParameters.issuerCode was null or undefined when calling apiV1UserManagementInvoicesIssuerCodeIssuedInvoicesNumberSeriesGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/user-management/invoices/{issuerCode}/issued-invoices/number-series`.replace(`{${"issuerCode"}}`, encodeURIComponent(String(requestParameters.issuerCode))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Číselné řady faktur
     */
    async apiV1UserManagementInvoicesIssuerCodeIssuedInvoicesNumberSeriesGet(requestParameters: ApiV1UserManagementInvoicesIssuerCodeIssuedInvoicesNumberSeriesGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.apiV1UserManagementInvoicesIssuerCodeIssuedInvoicesNumberSeriesGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
