/* tslint:disable */
/* eslint-disable */
/**
 * VentureClub.RestApi2
 * 1.33.2.3, env PROD
 *
 * The version of the OpenAPI document: 1.0
 * Contact: it@spinware.cz
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { TeaserParagraph } from './TeaserParagraph';
import {
    TeaserParagraphFromJSON,
    TeaserParagraphFromJSONTyped,
    TeaserParagraphToJSON,
} from './TeaserParagraph';

/**
 * 
 * @export
 * @interface PagedListResultDataTypeOfTTeaserParagraph
 */
export interface PagedListResultDataTypeOfTTeaserParagraph {
    /**
     * 
     * @type {number}
     * @memberof PagedListResultDataTypeOfTTeaserParagraph
     */
    resultCode?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PagedListResultDataTypeOfTTeaserParagraph
     */
    error?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PagedListResultDataTypeOfTTeaserParagraph
     */
    errorMessage?: string | null;
    /**
     * Celkový počet
     * @type {number}
     * @memberof PagedListResultDataTypeOfTTeaserParagraph
     */
    totalCount?: number;
    /**
     * Počet položek na stránku
     * @type {number}
     * @memberof PagedListResultDataTypeOfTTeaserParagraph
     */
    pageSize?: number;
    /**
     * Aktuální stránka
     * @type {number}
     * @memberof PagedListResultDataTypeOfTTeaserParagraph
     */
    currentPage?: number;
    /**
     * Celkový počet stran
     * @type {number}
     * @memberof PagedListResultDataTypeOfTTeaserParagraph
     */
    totalPages?: number;
    /**
     * K dispozici další strana
     * @type {boolean}
     * @memberof PagedListResultDataTypeOfTTeaserParagraph
     */
    readonly hasNext?: boolean;
    /**
     * K dispozici předchozí strana
     * @type {boolean}
     * @memberof PagedListResultDataTypeOfTTeaserParagraph
     */
    readonly hasPrevious?: boolean;
    /**
     * Položky
     * @type {Array<TeaserParagraph>}
     * @memberof PagedListResultDataTypeOfTTeaserParagraph
     */
    items?: Array<TeaserParagraph> | null;
}

/**
 * Check if a given object implements the PagedListResultDataTypeOfTTeaserParagraph interface.
 */
export function instanceOfPagedListResultDataTypeOfTTeaserParagraph(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PagedListResultDataTypeOfTTeaserParagraphFromJSON(json: any): PagedListResultDataTypeOfTTeaserParagraph {
    return PagedListResultDataTypeOfTTeaserParagraphFromJSONTyped(json, false);
}

export function PagedListResultDataTypeOfTTeaserParagraphFromJSONTyped(json: any, ignoreDiscriminator: boolean): PagedListResultDataTypeOfTTeaserParagraph {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'resultCode': !exists(json, 'resultCode') ? undefined : json['resultCode'],
        'error': !exists(json, 'error') ? undefined : json['error'],
        'errorMessage': !exists(json, 'errorMessage') ? undefined : json['errorMessage'],
        'totalCount': !exists(json, 'totalCount') ? undefined : json['totalCount'],
        'pageSize': !exists(json, 'pageSize') ? undefined : json['pageSize'],
        'currentPage': !exists(json, 'currentPage') ? undefined : json['currentPage'],
        'totalPages': !exists(json, 'totalPages') ? undefined : json['totalPages'],
        'hasNext': !exists(json, 'hasNext') ? undefined : json['hasNext'],
        'hasPrevious': !exists(json, 'hasPrevious') ? undefined : json['hasPrevious'],
        'items': !exists(json, 'items') ? undefined : (json['items'] === null ? null : (json['items'] as Array<any>).map(TeaserParagraphFromJSON)),
    };
}

export function PagedListResultDataTypeOfTTeaserParagraphToJSON(value?: PagedListResultDataTypeOfTTeaserParagraph | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'resultCode': value.resultCode,
        'error': value.error,
        'errorMessage': value.errorMessage,
        'totalCount': value.totalCount,
        'pageSize': value.pageSize,
        'currentPage': value.currentPage,
        'totalPages': value.totalPages,
        'items': value.items === undefined ? undefined : (value.items === null ? null : (value.items as Array<any>).map(TeaserParagraphToJSON)),
    };
}

