import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import LogOutButton from "../Link/Logout";

const PureErrorBody = ({
	title,
	subtitle,
	reload,
	logout,
}: {
	title?: string | null | undefined;
	subtitle: string;
	reload: string;
	logout: string;
}): JSX.Element => {
	return (
		<Stack
			sx={{
				flex: 1,
				justifyContent: "center",
				alignItems: "center",
				width: "100%",
				minHeight: "100%",
				pt: 0,
			}}
		>
			<Alert severity="error" sx={{ mx: 2, ".MuiAlert-icon": { m: 0 } }} icon={<></>}>
				{title != null && title !== "" && (
					<Typography variant="h6" sx={{ mb: 2 }}>
						{title}
					</Typography>
				)}
				<Typography variant="body1" sx={{ mb: 2 }}>
					{subtitle}
				</Typography>
				<Stack direction="row" justifyContent="space-between">
					<Button
						variant="text"
						size="small"
						onClick={() => {
							window.location.reload();
						}}
					>
						{reload}
					</Button>
					<LogOutButton variant="text" size="small">
						{logout}
					</LogOutButton>
				</Stack>
			</Alert>
		</Stack>
	);
};

export default PureErrorBody;
