/* tslint:disable */
/* eslint-disable */
/**
 * VentureClub.RestApi v1.33.2.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CAddress } from './CAddress';
import {
    CAddressFromJSON,
    CAddressFromJSONTyped,
    CAddressToJSON,
} from './CAddress';

/**
 * 
 * @export
 * @interface CAresBasicInfo
 */
export interface CAresBasicInfo {
    /**
     * 
     * @type {string}
     * @memberof CAresBasicInfo
     */
    iC?: string;
    /**
     * 
     * @type {string}
     * @memberof CAresBasicInfo
     */
    dIC?: string;
    /**
     * 
     * @type {string}
     * @memberof CAresBasicInfo
     */
    companyName?: string;
    /**
     * 
     * @type {CAddress}
     * @memberof CAresBasicInfo
     */
    address?: CAddress;
    /**
     * 
     * @type {string}
     * @memberof CAresBasicInfo
     */
    companyBusinessBranch?: string;
}

/**
 * Check if a given object implements the CAresBasicInfo interface.
 */
export function instanceOfCAresBasicInfo(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CAresBasicInfoFromJSON(json: any): CAresBasicInfo {
    return CAresBasicInfoFromJSONTyped(json, false);
}

export function CAresBasicInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CAresBasicInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'iC': !exists(json, 'IC') ? undefined : json['IC'],
        'dIC': !exists(json, 'DIC') ? undefined : json['DIC'],
        'companyName': !exists(json, 'CompanyName') ? undefined : json['CompanyName'],
        'address': !exists(json, 'Address') ? undefined : CAddressFromJSON(json['Address']),
        'companyBusinessBranch': !exists(json, 'CompanyBusinessBranch') ? undefined : json['CompanyBusinessBranch'],
    };
}

export function CAresBasicInfoToJSON(value?: CAresBasicInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'IC': value.iC,
        'DIC': value.dIC,
        'CompanyName': value.companyName,
        'Address': CAddressToJSON(value.address),
        'CompanyBusinessBranch': value.companyBusinessBranch,
    };
}

