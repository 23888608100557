/* tslint:disable */
/* eslint-disable */
/**
 * VentureClub.RestApi2
 * 1.33.2.3, env PROD
 *
 * The version of the OpenAPI document: 1.0
 * Contact: it@spinware.cz
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PriceType } from './PriceType';
import {
    PriceTypeFromJSON,
    PriceTypeFromJSONTyped,
    PriceTypeToJSON,
} from './PriceType';
import type { VatTRateType } from './VatTRateType';
import {
    VatTRateTypeFromJSON,
    VatTRateTypeFromJSONTyped,
    VatTRateTypeToJSON,
} from './VatTRateType';

/**
 * Položka faktury
 * @export
 * @interface InvoiceItem
 */
export interface InvoiceItem {
    /**
     * Jednotka
     * @type {string}
     * @memberof InvoiceItem
     */
    unit?: string | null;
    /**
     * Jednotková cena
     * @type {number}
     * @memberof InvoiceItem
     */
    unitPrice: number;
    /**
     * Množsví
     * @type {number}
     * @memberof InvoiceItem
     */
    quantity: number;
    /**
     * Název položky
     * @type {string}
     * @memberof InvoiceItem
     */
    name: string;
    /**
     * 
     * @type {PriceType}
     * @memberof InvoiceItem
     */
    priceType: PriceType;
    /**
     * 
     * @type {VatTRateType}
     * @memberof InvoiceItem
     */
    vatRateType: VatTRateType;
}

/**
 * Check if a given object implements the InvoiceItem interface.
 */
export function instanceOfInvoiceItem(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "unitPrice" in value;
    isInstance = isInstance && "quantity" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "priceType" in value;
    isInstance = isInstance && "vatRateType" in value;

    return isInstance;
}

export function InvoiceItemFromJSON(json: any): InvoiceItem {
    return InvoiceItemFromJSONTyped(json, false);
}

export function InvoiceItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): InvoiceItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'unit': !exists(json, 'unit') ? undefined : json['unit'],
        'unitPrice': json['unitPrice'],
        'quantity': json['quantity'],
        'name': json['name'],
        'priceType': PriceTypeFromJSON(json['priceType']),
        'vatRateType': VatTRateTypeFromJSON(json['vatRateType']),
    };
}

export function InvoiceItemToJSON(value?: InvoiceItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'unit': value.unit,
        'unitPrice': value.unitPrice,
        'quantity': value.quantity,
        'name': value.name,
        'priceType': PriceTypeToJSON(value.priceType),
        'vatRateType': VatTRateTypeToJSON(value.vatRateType),
    };
}

