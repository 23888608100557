import SvgIcon from "@mui/material/SvgIcon";
import React from "react";

import type { ColorProps } from "../types";
const Home = ({ color }: ColorProps): JSX.Element => {
	return (
		<SvgIcon fill={color}>
			<svg width="22" height="20" viewBox="0 0 22 20" xmlns="http://www.w3.org/2000/svg">
				<path d="M13.3027 17.5889V11.8848C13.3027 11.6797 13.2412 11.5156 13.1182 11.3926C12.9951 11.2695 12.8311 11.208 12.626 11.208H9.37402C9.16895 11.208 9.00195 11.2695 8.87305 11.3926C8.75 11.5156 8.68848 11.6797 8.68848 11.8848V17.5889H13.3027ZM3.11621 17.29V10.5928L10.4639 4.43164C10.8213 4.12695 11.1816 4.12695 11.5449 4.43164L18.8926 10.5928V17.29C18.8926 17.9229 18.7051 18.418 18.3301 18.7754C17.9551 19.1328 17.4453 19.3115 16.8008 19.3115H5.20801C4.55762 19.3115 4.04492 19.1328 3.66992 18.7754C3.30078 18.418 3.11621 17.9229 3.11621 17.29ZM0.268555 9.38867C0.268555 9.11914 0.382812 8.8877 0.611328 8.69434L9.76953 1.00391C10.1504 0.6875 10.5635 0.529297 11.0088 0.529297C11.4541 0.529297 11.8643 0.6875 12.2393 1.00391L21.3887 8.69434C21.6172 8.88184 21.7314 9.12207 21.7314 9.41504C21.7314 9.66699 21.6436 9.86621 21.4678 10.0127C21.2979 10.1592 21.0869 10.2324 20.835 10.2324C20.7002 10.2324 20.5713 10.2002 20.4482 10.1357C20.3252 10.0713 20.2109 9.99805 20.1055 9.91602L11.3867 2.59473C11.2637 2.48926 11.1377 2.43652 11.0088 2.43652C10.8799 2.43652 10.7539 2.48926 10.6309 2.59473L1.90332 9.91602C1.79199 9.99805 1.6748 10.0713 1.55176 10.1357C1.43457 10.2002 1.30859 10.2324 1.17383 10.2324C0.892578 10.2324 0.669922 10.1504 0.505859 9.98633C0.347656 9.81641 0.268555 9.61719 0.268555 9.38867ZM16.4316 5.12598V2.97266C16.4316 2.7793 16.4902 2.62402 16.6074 2.50684C16.7305 2.38379 16.8887 2.32227 17.082 2.32227H18.2686C18.4678 2.32227 18.626 2.38379 18.7432 2.50684C18.8604 2.62402 18.9189 2.7793 18.9189 2.97266V7.21777L16.4316 5.12598Z" />
			</svg>
		</SvgIcon>
	);
};
export default Home;
