import { Stack } from "@mui/material";
import React, { type ReactNode } from "react";

import iosDetected from "../../../functions/iOSDetect";
interface SimpleLayoutProps {
	children: ReactNode;
}
const SimpleLayout: React.FC<SimpleLayoutProps> = ({ children }: SimpleLayoutProps) => {
	return (
		<Stack
			sx={{
				flex: "1",
				minHeight: "100%",
				mt: iosDetected ? 4 : 0,
				pt: 12,
				pb: 4,
				px: 2,
			}}
		>
			{children}
		</Stack>
	);
};

export default SimpleLayout;
