/* tslint:disable */
/* eslint-disable */
/**
 * VentureClub.RestApi v1.33.2.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PaymentQrResultDataType
 */
export interface PaymentQrResultDataType {
    /**
     * 
     * @type {string}
     * @memberof PaymentQrResultDataType
     */
    base64StringPaymentQr?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentQrResultDataType
     */
    qrString?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PaymentQrResultDataType
     */
    error?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PaymentQrResultDataType
     */
    errorMessage?: string;
}

/**
 * Check if a given object implements the PaymentQrResultDataType interface.
 */
export function instanceOfPaymentQrResultDataType(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PaymentQrResultDataTypeFromJSON(json: any): PaymentQrResultDataType {
    return PaymentQrResultDataTypeFromJSONTyped(json, false);
}

export function PaymentQrResultDataTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaymentQrResultDataType {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'base64StringPaymentQr': !exists(json, 'Base64StringPaymentQr') ? undefined : json['Base64StringPaymentQr'],
        'qrString': !exists(json, 'QrString') ? undefined : json['QrString'],
        'error': !exists(json, 'Error') ? undefined : json['Error'],
        'errorMessage': !exists(json, 'ErrorMessage') ? undefined : json['ErrorMessage'],
    };
}

export function PaymentQrResultDataTypeToJSON(value?: PaymentQrResultDataType | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Base64StringPaymentQr': value.base64StringPaymentQr,
        'QrString': value.qrString,
        'Error': value.error,
        'ErrorMessage': value.errorMessage,
    };
}

