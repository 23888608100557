/* tslint:disable */
/* eslint-disable */
/**
 * VentureClub.RestApi2
 * 1.33.2.3, env PROD
 *
 * The version of the OpenAPI document: 1.0
 * Contact: it@spinware.cz
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Investiční příležitost
 * @export
 * @interface InvestOpportunity
 */
export interface InvestOpportunity {
    /**
     * Id investiční příležitosti
     * @type {number}
     * @memberof InvestOpportunity
     */
    id?: number;
    /**
     * Jméno investiční příležitosti
     * @type {string}
     * @memberof InvestOpportunity
     */
    name?: string | null;
    /**
     * typ investiční příležitosti id
     * @type {number}
     * @memberof InvestOpportunity
     */
    typeId?: number;
    /**
     * Textový popis typu
     * @type {string}
     * @memberof InvestOpportunity
     */
    typeStr?: string | null;
    /**
     * Min. úroveň členství pro investování
     * @type {number}
     * @memberof InvestOpportunity
     */
    minMembershipLevel?: number;
    /**
     * Možný násobek příslibu.
     * U ME/VE se jedná o fixní nastavenou hodnotu
     * U KL/MEKL se jedná o příplatkové části KL, tj. částky kterou chce investor investovat a kterou zadává k investování
     * @type {number}
     * @memberof InvestOpportunity
     */
    investStep?: number;
    /**
     * Minimální částka příslibu
     * @type {number}
     * @memberof InvestOpportunity
     */
    minAmount?: number;
    /**
     * Maximální částka příslibu, může být ponížena vzhledem k možné výši kroku investice
     * @type {number}
     * @memberof InvestOpportunity
     */
    maxAmount?: number;
    /**
     * Základ pro výpočet výnosů. KL je příplatek, ME je min. výše
     * @type {number}
     * @memberof InvestOpportunity
     */
    readonly investentBase?: number;
    /**
     * Srážka dividendy %
     * Za celé období projektu
     * @type {number}
     * @memberof InvestOpportunity
     */
    readonly deductionDividendPrc?: number;
    /**
     * Srážka Exit %
     * Za celé období projektu
     * @type {number}
     * @memberof InvestOpportunity
     */
    readonly deductionExitPrc?: number;
    /**
     * Poplatek %
     * @type {number}
     * @memberof InvestOpportunity
     */
    chargePrc?: number;
    /**
     * Id kmenového listu, null = nejedná se o kmenový list
     * Víc na detailu KL (api/v{version:apiVersion}/invest-management/investments/holding-lists/{HoldingListId})
     * @type {number}
     * @memberof InvestOpportunity
     */
    holdingListId?: number | null;
    /**
     * Aktuální volný počet Kmenových Listů (listy které nemají příslib)
     * Pokud není KL tak je null.
     * Volných listů může být i víc než je max. částka investice (MaxAmount)
     * Nebo jich může být volných méně (než umožňuje MaxAmount), tj. lze investovat max. částku: HoldingListAvailablePcs *
     * InvestStep
     * Víc na detailu KL (api/v{version:apiVersion}/invest-management/investments/holding-lists/{HoldingListId})
     * @type {number}
     * @memberof InvestOpportunity
     */
    holdingListAvailableQuantity?: number | null;
}

/**
 * Check if a given object implements the InvestOpportunity interface.
 */
export function instanceOfInvestOpportunity(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function InvestOpportunityFromJSON(json: any): InvestOpportunity {
    return InvestOpportunityFromJSONTyped(json, false);
}

export function InvestOpportunityFromJSONTyped(json: any, ignoreDiscriminator: boolean): InvestOpportunity {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'typeId': !exists(json, 'typeId') ? undefined : json['typeId'],
        'typeStr': !exists(json, 'typeStr') ? undefined : json['typeStr'],
        'minMembershipLevel': !exists(json, 'minMembershipLevel') ? undefined : json['minMembershipLevel'],
        'investStep': !exists(json, 'investStep') ? undefined : json['investStep'],
        'minAmount': !exists(json, 'minAmount') ? undefined : json['minAmount'],
        'maxAmount': !exists(json, 'maxAmount') ? undefined : json['maxAmount'],
        'investentBase': !exists(json, 'investentBase') ? undefined : json['investentBase'],
        'deductionDividendPrc': !exists(json, 'deductionDividendPrc') ? undefined : json['deductionDividendPrc'],
        'deductionExitPrc': !exists(json, 'deductionExitPrc') ? undefined : json['deductionExitPrc'],
        'chargePrc': !exists(json, 'chargePrc') ? undefined : json['chargePrc'],
        'holdingListId': !exists(json, 'holdingListId') ? undefined : json['holdingListId'],
        'holdingListAvailableQuantity': !exists(json, 'holdingListAvailableQuantity') ? undefined : json['holdingListAvailableQuantity'],
    };
}

export function InvestOpportunityToJSON(value?: InvestOpportunity | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'typeId': value.typeId,
        'typeStr': value.typeStr,
        'minMembershipLevel': value.minMembershipLevel,
        'investStep': value.investStep,
        'minAmount': value.minAmount,
        'maxAmount': value.maxAmount,
        'chargePrc': value.chargePrc,
        'holdingListId': value.holdingListId,
        'holdingListAvailableQuantity': value.holdingListAvailableQuantity,
    };
}

