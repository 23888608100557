/* tslint:disable */
/* eslint-disable */
/**
 * VentureClub.RestApi v1.33.2.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InvoiceItem
 */
export interface InvoiceItem {
    /**
     * 
     * @type {string}
     * @memberof InvoiceItem
     */
    unit?: string;
    /**
     * 
     * @type {number}
     * @memberof InvoiceItem
     */
    unitPrice: number;
    /**
     * 
     * @type {number}
     * @memberof InvoiceItem
     */
    quantity: number;
    /**
     * 
     * @type {string}
     * @memberof InvoiceItem
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof InvoiceItem
     */
    priceType: InvoiceItemPriceTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof InvoiceItem
     */
    vatRateType: InvoiceItemVatRateTypeEnum;
}


/**
 * @export
 */
export const InvoiceItemPriceTypeEnum = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2
} as const;
export type InvoiceItemPriceTypeEnum = typeof InvoiceItemPriceTypeEnum[keyof typeof InvoiceItemPriceTypeEnum];

/**
 * @export
 */
export const InvoiceItemVatRateTypeEnum = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3
} as const;
export type InvoiceItemVatRateTypeEnum = typeof InvoiceItemVatRateTypeEnum[keyof typeof InvoiceItemVatRateTypeEnum];


/**
 * Check if a given object implements the InvoiceItem interface.
 */
export function instanceOfInvoiceItem(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "unitPrice" in value;
    isInstance = isInstance && "quantity" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "priceType" in value;
    isInstance = isInstance && "vatRateType" in value;

    return isInstance;
}

export function InvoiceItemFromJSON(json: any): InvoiceItem {
    return InvoiceItemFromJSONTyped(json, false);
}

export function InvoiceItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): InvoiceItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'unit': !exists(json, 'Unit') ? undefined : json['Unit'],
        'unitPrice': json['UnitPrice'],
        'quantity': json['Quantity'],
        'name': json['Name'],
        'priceType': json['PriceType'],
        'vatRateType': json['VatRateType'],
    };
}

export function InvoiceItemToJSON(value?: InvoiceItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Unit': value.unit,
        'UnitPrice': value.unitPrice,
        'Quantity': value.quantity,
        'Name': value.name,
        'PriceType': value.priceType,
        'VatRateType': value.vatRateType,
    };
}

