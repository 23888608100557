/* tslint:disable */
/* eslint-disable */
/**
 * VentureClub.RestApi v1.33.2.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CBankAccount
 */
export interface CBankAccount {
    /**
     * 
     * @type {string}
     * @memberof CBankAccount
     */
    iBAN?: string;
    /**
     * 
     * @type {string}
     * @memberof CBankAccount
     */
    sWIFT?: string;
    /**
     * 
     * @type {string}
     * @memberof CBankAccount
     */
    holderName?: string;
    /**
     * 
     * @type {string}
     * @memberof CBankAccount
     */
    holderStreet?: string;
    /**
     * 
     * @type {string}
     * @memberof CBankAccount
     */
    holderCity?: string;
    /**
     * 
     * @type {string}
     * @memberof CBankAccount
     */
    countryOfOrigin?: string;
    /**
     * 
     * @type {string}
     * @memberof CBankAccount
     */
    number?: string;
    /**
     * 
     * @type {string}
     * @memberof CBankAccount
     */
    bankCode?: string;
    /**
     * 
     * @type {string}
     * @memberof CBankAccount
     */
    readonly currencyCode?: string;
    /**
     * 
     * @type {number}
     * @memberof CBankAccount
     */
    currency?: CBankAccountCurrencyEnum;
    /**
     * 
     * @type {boolean}
     * @memberof CBankAccount
     */
    readonly isOK?: boolean;
}


/**
 * @export
 */
export const CBankAccountCurrencyEnum = {
    NUMBER_192: 192,
    NUMBER_193: 193
} as const;
export type CBankAccountCurrencyEnum = typeof CBankAccountCurrencyEnum[keyof typeof CBankAccountCurrencyEnum];


/**
 * Check if a given object implements the CBankAccount interface.
 */
export function instanceOfCBankAccount(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CBankAccountFromJSON(json: any): CBankAccount {
    return CBankAccountFromJSONTyped(json, false);
}

export function CBankAccountFromJSONTyped(json: any, ignoreDiscriminator: boolean): CBankAccount {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'iBAN': !exists(json, 'IBAN') ? undefined : json['IBAN'],
        'sWIFT': !exists(json, 'SWIFT') ? undefined : json['SWIFT'],
        'holderName': !exists(json, 'HolderName') ? undefined : json['HolderName'],
        'holderStreet': !exists(json, 'HolderStreet') ? undefined : json['HolderStreet'],
        'holderCity': !exists(json, 'HolderCity') ? undefined : json['HolderCity'],
        'countryOfOrigin': !exists(json, 'CountryOfOrigin') ? undefined : json['CountryOfOrigin'],
        'number': !exists(json, 'Number') ? undefined : json['Number'],
        'bankCode': !exists(json, 'BankCode') ? undefined : json['BankCode'],
        'currencyCode': !exists(json, 'CurrencyCode') ? undefined : json['CurrencyCode'],
        'currency': !exists(json, 'Currency') ? undefined : json['Currency'],
        'isOK': !exists(json, 'IsOK') ? undefined : json['IsOK'],
    };
}

export function CBankAccountToJSON(value?: CBankAccount | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'IBAN': value.iBAN,
        'SWIFT': value.sWIFT,
        'HolderName': value.holderName,
        'HolderStreet': value.holderStreet,
        'HolderCity': value.holderCity,
        'CountryOfOrigin': value.countryOfOrigin,
        'Number': value.number,
        'BankCode': value.bankCode,
        'Currency': value.currency,
    };
}

