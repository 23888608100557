/* tslint:disable */
/* eslint-disable */
/**
 * VentureClub.RestApi v1.33.2.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LogoutRequest
 */
export interface LogoutRequest {
    /**
     * 
     * @type {string}
     * @memberof LogoutRequest
     */
    token?: string;
    /**
     * 
     * @type {number}
     * @memberof LogoutRequest
     */
    scope?: LogoutRequestScopeEnum;
}


/**
 * @export
 */
export const LogoutRequestScopeEnum = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2
} as const;
export type LogoutRequestScopeEnum = typeof LogoutRequestScopeEnum[keyof typeof LogoutRequestScopeEnum];


/**
 * Check if a given object implements the LogoutRequest interface.
 */
export function instanceOfLogoutRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function LogoutRequestFromJSON(json: any): LogoutRequest {
    return LogoutRequestFromJSONTyped(json, false);
}

export function LogoutRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): LogoutRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'token': !exists(json, 'token') ? undefined : json['token'],
        'scope': !exists(json, 'scope') ? undefined : json['scope'],
    };
}

export function LogoutRequestToJSON(value?: LogoutRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'token': value.token,
        'scope': value.scope,
    };
}

