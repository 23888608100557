/* tslint:disable */
/* eslint-disable */
/**
 * VentureClub.RestApi2
 * 1.33.2.3, env PROD
 *
 * The version of the OpenAPI document: 1.0
 * Contact: it@spinware.cz
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Translate,
} from '../models/index';
import {
    TranslateFromJSON,
    TranslateToJSON,
} from '../models/index';

export interface ApiV1CommonDebugGetRequest {
    code?: string;
}

export interface ApiV1CommonPutRequest {
    code?: string;
}

/**
 * 
 */
export class CommonApi extends runtime.BaseAPI {

    /**
     */
    async apiV1CommonDebugGetRaw(requestParameters: ApiV1CommonDebugGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.code !== undefined) {
            queryParameters['code'] = requestParameters.code;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/common/debug`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiV1CommonDebugGet(requestParameters: ApiV1CommonDebugGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.apiV1CommonDebugGetRaw(requestParameters, initOverrides);
    }

    /**
     * Return ok and version string
     */
    async apiV1CommonGetRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/common`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Return ok and version string
     */
    async apiV1CommonGet(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.apiV1CommonGetRaw(initOverrides);
    }

    /**
     */
    async apiV1CommonPutRaw(requestParameters: ApiV1CommonPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.code !== undefined) {
            queryParameters['code'] = requestParameters.code;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/common`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiV1CommonPut(requestParameters: ApiV1CommonPutRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.apiV1CommonPutRaw(requestParameters, initOverrides);
    }

    /**
     * Texty aplikace
     */
    async apiV1CommonTextsGetRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Translate>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/common/texts`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TranslateFromJSON));
    }

    /**
     * Texty aplikace
     */
    async apiV1CommonTextsGet(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Translate>> {
        const response = await this.apiV1CommonTextsGetRaw(initOverrides);
        return await response.value();
    }

}
