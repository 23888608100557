/* tslint:disable */
/* eslint-disable */
/**
 * VentureClub.RestApi2
 * 1.33.2.3, env PROD
 *
 * The version of the OpenAPI document: 1.0
 * Contact: it@spinware.cz
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Parametry k požadavku na odběr notifikací
 * @export
 * @interface SubscriptionRequest
 */
export interface SubscriptionRequest {
    /**
     * Odebírat ano/ne
     * @type {boolean}
     * @memberof SubscriptionRequest
     */
    subscribe?: boolean;
    /**
     * Token zařízení
     * @type {string}
     * @memberof SubscriptionRequest
     */
    fcmToken?: string | null;
    /**
     * Volitelná informace o zařízení, pro případnou správu odběrů
     * @type {string}
     * @memberof SubscriptionRequest
     */
    deviceDescription?: string | null;
    /**
     * Téma k odběru - výchozí doporučen 'default'
     * @type {string}
     * @memberof SubscriptionRequest
     */
    topic?: string | null;
}

/**
 * Check if a given object implements the SubscriptionRequest interface.
 */
export function instanceOfSubscriptionRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SubscriptionRequestFromJSON(json: any): SubscriptionRequest {
    return SubscriptionRequestFromJSONTyped(json, false);
}

export function SubscriptionRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): SubscriptionRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'subscribe': !exists(json, 'subscribe') ? undefined : json['subscribe'],
        'fcmToken': !exists(json, 'fcmToken') ? undefined : json['fcmToken'],
        'deviceDescription': !exists(json, 'deviceDescription') ? undefined : json['deviceDescription'],
        'topic': !exists(json, 'topic') ? undefined : json['topic'],
    };
}

export function SubscriptionRequestToJSON(value?: SubscriptionRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'subscribe': value.subscribe,
        'fcmToken': value.fcmToken,
        'deviceDescription': value.deviceDescription,
        'topic': value.topic,
    };
}

