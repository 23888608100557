import { navigate } from "gatsby";
import { createContext, type ReactElement, useContext } from "react";

import {
	InvestmentsApi,
	KpisApi,
	ProjectsApi,
	UserApi,
	UserInvestmentsApi,
} from "../../API/main/apis/index";
import { Configuration as MainConfig } from "../../API/main/runtime";
import { validEnv } from "../../gatsby-shared";
import { useTokens } from "../queryHooks/user";
import { useIdentity } from "./IdentityProvider";

interface IApiContext {
	InvestmentsApi: InvestmentsApi;
	ProjectsApi: ProjectsApi;
	UserApi: UserApi;
	UserInvestmentsApi: UserInvestmentsApi;
	KpisApi: KpisApi;
}

export const ApiClientContext = createContext<IApiContext>({} as unknown as IApiContext);

export function useApiClient(): IApiContext {
	return useContext(ApiClientContext);
}

interface IProps {
	children: ReactElement;
}

export const ApiClientProvider = ({ children }: IProps): JSX.Element => {
	const identity = useIdentity();
	const { mutate } = useTokens();

	const missingContext = Object.keys(identity).length === 0;
	if (missingContext) {
		window.location.reload(); // I have no idea why I need it, this protects from unexpected error from useIdentity being {} (default value) unexpectedly
	}

	const validUser = typeof identity?.user !== "undefined";

	const mainConfig =
		validEnv && validUser
			? new MainConfig({
					headers: {
						// eslint-disable-next-line
						Authorization: "Bearer " + identity?.user?.accessToken,
						"Accept-Language": "",
					},
					fetchApi: async (input, init) =>
						await fetch(input, init).then(async (response) => {
							if (response.ok) {
								return response;
							} else {
								const errorMessage = await response.json();
								throw errorMessage;
							}
						}),
					middleware: [
						{
							async onError(context) {
								// Handle expired access token
								if (context?.error?.resultCode === 401) {
									mutate(
										{
											refreshToken: identity?.user?.refreshToken,
											grantType: "refresh_token",
										},
										{
											onSuccess: (data) => {
												identity.setUser(data);
											},
											onError: () => {
												identity.removeToken();
												void navigate("/");
											},
										},
									);
								}
							},
						},
					],
			  })
			: undefined;

	return (
		<ApiClientContext.Provider
			value={{
				ProjectsApi: new ProjectsApi(mainConfig),
				UserApi: new UserApi(mainConfig),
				InvestmentsApi: new InvestmentsApi(mainConfig),
				UserInvestmentsApi: new UserInvestmentsApi(mainConfig),
				KpisApi: new KpisApi(mainConfig),
			}}
		>
			{children}
		</ApiClientContext.Provider>
	);
};
