/* tslint:disable */
/* eslint-disable */
/**
 * VentureClub.RestApi v1.33.2.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CBankAccount } from './CBankAccount';
import {
    CBankAccountFromJSON,
    CBankAccountFromJSONTyped,
    CBankAccountToJSON,
} from './CBankAccount';

/**
 * 
 * @export
 * @interface PaymentPrescription
 */
export interface PaymentPrescription {
    /**
     * 
     * @type {number}
     * @memberof PaymentPrescription
     */
    readonly id?: number;
    /**
     * 
     * @type {number}
     * @memberof PaymentPrescription
     */
    readonly type?: PaymentPrescriptionTypeEnum;
    /**
     * 
     * @type {Date}
     * @memberof PaymentPrescription
     */
    readonly dueDate?: Date;
    /**
     * 
     * @type {number}
     * @memberof PaymentPrescription
     */
    readonly amount?: number;
    /**
     * 
     * @type {number}
     * @memberof PaymentPrescription
     */
    readonly payed?: number;
    /**
     * 
     * @type {number}
     * @memberof PaymentPrescription
     */
    readonly debt?: number;
    /**
     * 
     * @type {string}
     * @memberof PaymentPrescription
     */
    readonly description?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentPrescription
     */
    readonly variableSymbol?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentPrescription
     */
    readonly specificSymbol?: string;
    /**
     * 
     * @type {Date}
     * @memberof PaymentPrescription
     */
    readonly created?: Date;
    /**
     * 
     * @type {CBankAccount}
     * @memberof PaymentPrescription
     */
    bankAccount?: CBankAccount;
    /**
     * 
     * @type {string}
     * @memberof PaymentPrescription
     */
    readonly paymentMessage?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentPrescription
     */
    readonly projectName?: string;
    /**
     * 
     * @type {number}
     * @memberof PaymentPrescription
     */
    readonly projectId?: number;
    /**
     * 
     * @type {string}
     * @memberof PaymentPrescription
     */
    readonly paymentQr?: string;
}


/**
 * @export
 */
export const PaymentPrescriptionTypeEnum = {
    NUMBER_115: 115,
    NUMBER_116: 116,
    NUMBER_117: 117,
    NUMBER_121: 121,
    NUMBER_128: 128,
    NUMBER_160: 160,
    NUMBER_161: 161,
    NUMBER_173: 173,
    NUMBER_198: 198,
    NUMBER_199: 199,
    NUMBER_215: 215
} as const;
export type PaymentPrescriptionTypeEnum = typeof PaymentPrescriptionTypeEnum[keyof typeof PaymentPrescriptionTypeEnum];


/**
 * Check if a given object implements the PaymentPrescription interface.
 */
export function instanceOfPaymentPrescription(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PaymentPrescriptionFromJSON(json: any): PaymentPrescription {
    return PaymentPrescriptionFromJSONTyped(json, false);
}

export function PaymentPrescriptionFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaymentPrescription {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'Id') ? undefined : json['Id'],
        'type': !exists(json, 'Type') ? undefined : json['Type'],
        'dueDate': !exists(json, 'DueDate') ? undefined : (new Date(json['DueDate'])),
        'amount': !exists(json, 'Amount') ? undefined : json['Amount'],
        'payed': !exists(json, 'Payed') ? undefined : json['Payed'],
        'debt': !exists(json, 'Debt') ? undefined : json['Debt'],
        'description': !exists(json, 'Description') ? undefined : json['Description'],
        'variableSymbol': !exists(json, 'VariableSymbol') ? undefined : json['VariableSymbol'],
        'specificSymbol': !exists(json, 'SpecificSymbol') ? undefined : json['SpecificSymbol'],
        'created': !exists(json, 'Created') ? undefined : (new Date(json['Created'])),
        'bankAccount': !exists(json, 'BankAccount') ? undefined : CBankAccountFromJSON(json['BankAccount']),
        'paymentMessage': !exists(json, 'PaymentMessage') ? undefined : json['PaymentMessage'],
        'projectName': !exists(json, 'ProjectName') ? undefined : json['ProjectName'],
        'projectId': !exists(json, 'ProjectId') ? undefined : json['ProjectId'],
        'paymentQr': !exists(json, 'PaymentQr') ? undefined : json['PaymentQr'],
    };
}

export function PaymentPrescriptionToJSON(value?: PaymentPrescription | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'BankAccount': CBankAccountToJSON(value.bankAccount),
    };
}

