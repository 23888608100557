/* tslint:disable */
/* eslint-disable */
/**
 * VentureClub.RestApi2
 * 1.33.2.3, env PROD
 *
 * The version of the OpenAPI document: 1.0
 * Contact: it@spinware.cz
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { InvestOpportunity } from './InvestOpportunity';
import {
    InvestOpportunityFromJSON,
    InvestOpportunityFromJSONTyped,
    InvestOpportunityToJSON,
} from './InvestOpportunity';

/**
 * Investiční kampaň
 * @export
 * @interface InvestCampaign
 */
export interface InvestCampaign {
    /**
     * Id kampaně
     * @type {number}
     * @memberof InvestCampaign
     */
    id?: number;
    /**
     * Id projektu
     * @type {number}
     * @memberof InvestCampaign
     */
    projectId?: number;
    /**
     * Min. úroveň uživatele pro investování
     * @type {number}
     * @memberof InvestCampaign
     */
    minMembershipLevel?: number;
    /**
     * Kampaň je aktivní, je povolená pro zobrazení.
     * Nicméně může být dostupnost omezena ještě datumovým rozsahem (StartDateTime-EndDateTime)
     * @type {boolean}
     * @memberof InvestCampaign
     */
    readonly active?: boolean;
    /**
     * Začátek investiční kampaně
     * @type {Date}
     * @memberof InvestCampaign
     */
    startDateTime?: Date;
    /**
     * Konec investiční kampaně
     * @type {Date}
     * @memberof InvestCampaign
     */
    endDateTime?: Date;
    /**
     * Minimální akceptovatelná částka
     * @type {number}
     * @memberof InvestCampaign
     */
    minAcceptedAmount?: number;
    /**
     * Maximální akceptovatelná částka - cíl kampaně
     * @type {number}
     * @memberof InvestCampaign
     */
    maxAcceptedAmount?: number;
    /**
     * Investiční příležitosti
     * @type {Array<InvestOpportunity>}
     * @memberof InvestCampaign
     */
    investOpportunities?: Array<InvestOpportunity> | null;
}

/**
 * Check if a given object implements the InvestCampaign interface.
 */
export function instanceOfInvestCampaign(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function InvestCampaignFromJSON(json: any): InvestCampaign {
    return InvestCampaignFromJSONTyped(json, false);
}

export function InvestCampaignFromJSONTyped(json: any, ignoreDiscriminator: boolean): InvestCampaign {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'projectId': !exists(json, 'projectId') ? undefined : json['projectId'],
        'minMembershipLevel': !exists(json, 'minMembershipLevel') ? undefined : json['minMembershipLevel'],
        'active': !exists(json, 'active') ? undefined : json['active'],
        'startDateTime': !exists(json, 'startDateTime') ? undefined : (new Date(json['startDateTime'])),
        'endDateTime': !exists(json, 'endDateTime') ? undefined : (new Date(json['endDateTime'])),
        'minAcceptedAmount': !exists(json, 'minAcceptedAmount') ? undefined : json['minAcceptedAmount'],
        'maxAcceptedAmount': !exists(json, 'maxAcceptedAmount') ? undefined : json['maxAcceptedAmount'],
        'investOpportunities': !exists(json, 'investOpportunities') ? undefined : (json['investOpportunities'] === null ? null : (json['investOpportunities'] as Array<any>).map(InvestOpportunityFromJSON)),
    };
}

export function InvestCampaignToJSON(value?: InvestCampaign | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'projectId': value.projectId,
        'minMembershipLevel': value.minMembershipLevel,
        'startDateTime': value.startDateTime === undefined ? undefined : (value.startDateTime.toISOString()),
        'endDateTime': value.endDateTime === undefined ? undefined : (value.endDateTime.toISOString()),
        'minAcceptedAmount': value.minAcceptedAmount,
        'maxAcceptedAmount': value.maxAcceptedAmount,
        'investOpportunities': value.investOpportunities === undefined ? undefined : (value.investOpportunities === null ? null : (value.investOpportunities as Array<any>).map(InvestOpportunityToJSON)),
    };
}

