/* tslint:disable */
/* eslint-disable */
/**
 * VentureClub.RestApi v1.33.2.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PaymentPrescription } from './PaymentPrescription';
import {
    PaymentPrescriptionFromJSON,
    PaymentPrescriptionFromJSONTyped,
    PaymentPrescriptionToJSON,
} from './PaymentPrescription';

/**
 * 
 * @export
 * @interface ResultDataTypePaymentPrescription
 */
export interface ResultDataTypePaymentPrescription {
    /**
     * 
     * @type {PaymentPrescription}
     * @memberof ResultDataTypePaymentPrescription
     */
    data?: PaymentPrescription;
    /**
     * 
     * @type {boolean}
     * @memberof ResultDataTypePaymentPrescription
     */
    error?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ResultDataTypePaymentPrescription
     */
    errorMessage?: string;
}

/**
 * Check if a given object implements the ResultDataTypePaymentPrescription interface.
 */
export function instanceOfResultDataTypePaymentPrescription(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ResultDataTypePaymentPrescriptionFromJSON(json: any): ResultDataTypePaymentPrescription {
    return ResultDataTypePaymentPrescriptionFromJSONTyped(json, false);
}

export function ResultDataTypePaymentPrescriptionFromJSONTyped(json: any, ignoreDiscriminator: boolean): ResultDataTypePaymentPrescription {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': !exists(json, 'Data') ? undefined : PaymentPrescriptionFromJSON(json['Data']),
        'error': !exists(json, 'Error') ? undefined : json['Error'],
        'errorMessage': !exists(json, 'ErrorMessage') ? undefined : json['ErrorMessage'],
    };
}

export function ResultDataTypePaymentPrescriptionToJSON(value?: ResultDataTypePaymentPrescription | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Data': PaymentPrescriptionToJSON(value.data),
        'Error': value.error,
        'ErrorMessage': value.errorMessage,
    };
}

