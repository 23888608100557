/* tslint:disable */
/* eslint-disable */
/**
 * VentureClub.RestApi v1.33.2.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InvoiceResultDataType
 */
export interface InvoiceResultDataType {
    /**
     * 
     * @type {boolean}
     * @memberof InvoiceResultDataType
     */
    result?: boolean;
    /**
     * 
     * @type {string}
     * @memberof InvoiceResultDataType
     */
    vS?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceResultDataType
     */
    paymentQrStr?: string;
    /**
     * 
     * @type {boolean}
     * @memberof InvoiceResultDataType
     */
    error?: boolean;
    /**
     * 
     * @type {string}
     * @memberof InvoiceResultDataType
     */
    errorMessage?: string;
}

/**
 * Check if a given object implements the InvoiceResultDataType interface.
 */
export function instanceOfInvoiceResultDataType(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function InvoiceResultDataTypeFromJSON(json: any): InvoiceResultDataType {
    return InvoiceResultDataTypeFromJSONTyped(json, false);
}

export function InvoiceResultDataTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): InvoiceResultDataType {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'result': !exists(json, 'Result') ? undefined : json['Result'],
        'vS': !exists(json, 'VS') ? undefined : json['VS'],
        'paymentQrStr': !exists(json, 'PaymentQrStr') ? undefined : json['PaymentQrStr'],
        'error': !exists(json, 'Error') ? undefined : json['Error'],
        'errorMessage': !exists(json, 'ErrorMessage') ? undefined : json['ErrorMessage'],
    };
}

export function InvoiceResultDataTypeToJSON(value?: InvoiceResultDataType | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Result': value.result,
        'VS': value.vS,
        'PaymentQrStr': value.paymentQrStr,
        'Error': value.error,
        'ErrorMessage': value.errorMessage,
    };
}

