import { useMutation, type UseMutationResult } from "@tanstack/react-query";
import jwtDecode from "jwt-decode";

import { type AuthIntrospectRequest } from "../../API/identity/apis";
import {
	type AuthenticationRequest,
	type IdentityToken,
	type JwtToken,
	LogoutRequestScopeEnum,
} from "../../API/identity/models";
import { useIdentity } from "../context/IdentityProvider";
import { type HttpError } from "../types";

const responseModifier = (response: IdentityToken): IdentityTokenDecoded => ({
	...response,
	accessTime: {
		authTime:
			response.accessToken != null ? jwtDecode<TokensType>(response.accessToken).auth_time : undefined,
		exp: response.accessToken != null ? jwtDecode<TokensType>(response.accessToken).exp : undefined,
	},
	refreshTime: {
		authTime:
			response.refreshToken != null
				? jwtDecode<TokensType>(response.refreshToken).auth_time
				: undefined,
		exp: response.refreshToken != null ? jwtDecode<TokensType>(response.refreshToken).exp : undefined,
	},
});

interface TokensType {
	aud?: string;
	auth_time: number;
	azp?: string;
	exp?: number;
	iat?: number;
	ident_prov?: string;
	iss?: string;
	jti?: string;
	nbf?: number;
	nonce?: string;
	purpose?: string;
	role?: string;
	scope?: string;
	sub?: string;
}

interface ExtendedTokensType {
	authTime?: number;
	exp?: number;
}

export interface IdentityTokenDecoded extends IdentityToken {
	accessTime: ExtendedTokensType;
	refreshTime: ExtendedTokensType;
}

export const useTokens = (): UseMutationResult<
	IdentityToken,
	HttpError,
	AuthenticationRequest,
	unknown
> => {
	const identity = useIdentity();

	return useMutation(async (parameters: AuthenticationRequest) => {
		const response = await identity.AuthApi.authToken({
			authRequest: {
				scope: "app_auth-reg",
				...parameters,
			},
		});
		const modifiedResponse = responseModifier(response);
		return modifiedResponse;
	});
};

export const useIntrospectToken = (): UseMutationResult<
	JwtToken,
	HttpError,
	AuthIntrospectRequest,
	unknown
> => {
	const identity = useIdentity();

	return useMutation(async (parameters: AuthIntrospectRequest) => {
		return await identity.AuthApi.authIntrospect({
			token: parameters.token,
		});
	});
};

export const useLogout = (): UseMutationResult<boolean, HttpError, { token: string }, unknown> => {
	const identity = useIdentity();

	return useMutation(async (parameters: { token: string }) => {
		return await identity.AuthApi.authLogout({
			logoutRequest: {
				token: parameters.token,
				scope: LogoutRequestScopeEnum.NUMBER_1,
			},
		});
	});
};
