import Stack from "@mui/material/Stack";
import { createContext, type ReactElement, useContext, useEffect, useState } from "react";
import { setLocale } from "yup";

import PureErrorBody from "../components/Errors/PureErrorBody";
import { useIdentity } from "../context/IdentityProvider";
import { getLanguageCode, saveLanguageCode } from "../functions/language";
import { useUserDetail } from "../queryHooks/userDetail";
import csLinks from "../translatedLinks/cs.json";
import enLinks from "../translatedLinks/en.json";
import cs from "../translation/cs.json";
import en from "../translation/en.json";
import locale from "../validation/locale";

export type SupportedLanguageType = "cs" | "en";

const links = {
	cs: csLinks,
	en: enLinks,
};

const texts = {
	cs,
	en,
};

interface ILanguageContext {
	localization: typeof cs;
	localizedUrls: typeof csLinks;
	code: string;
	languageIsLoading: boolean;
}

const LanguageContext = createContext<ILanguageContext>({
	localization: cs,
	localizedUrls: csLinks,
	code: "cs",
	languageIsLoading: false,
});

interface IProps {
	children: ReactElement;
}

export const useLocalization = (): ILanguageContext => {
	return useContext(LanguageContext);
};

const LanguageProvider = ({ children }: IProps): JSX.Element => {
	const { isAuthorized, user } = useIdentity();
	const languageCode = getLanguageCode();
	setLocale(locale[languageCode]);
	const [localization, setLocalization] = useState<typeof cs>(texts[languageCode]);
	const [localizedUrls, setLocalizedUrls] = useState<typeof csLinks>(links[languageCode]);

	const { data, refetch, isLoading, isError } = useUserDetail({
		enabled: false,
	});
	const beLangueCode =
		data?.languageCode === "cs" || data?.languageCode === "en" ? data?.languageCode : "cs";

	useEffect(() => {
		if (isAuthorized()) {
			void refetch();
		}
	}, [user]);

	useEffect(() => {
		if (isAuthorized()) {
			saveLanguageCode(beLangueCode);
			setLocale(locale[beLangueCode]);
			setLocalization(texts[beLangueCode]);
			setLocalizedUrls(links[beLangueCode]);
		}
	}, [beLangueCode]);

	if (isError) {
		return (
			<Stack justifyContent="space-around" sx={{ height: "100dvh" }}>
				<PureErrorBody
					subtitle={localization.global.unexpectedError}
					reload={localization.global.tryAgain}
					logout={localization.settings.logout}
				/>
			</Stack>
		);
	}

	return (
		<LanguageContext.Provider
			value={{
				localization,
				localizedUrls,
				code: languageCode,
				languageIsLoading: isLoading,
			}}
		>
			{children}
		</LanguageContext.Provider>
	);
};

export default LanguageProvider;
