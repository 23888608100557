/* tslint:disable */
/* eslint-disable */
/**
 * VentureClub.RestApi2
 * 1.33.2.3, env PROD
 *
 * The version of the OpenAPI document: 1.0
 * Contact: it@spinware.cz
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Novinka projektu
 * @export
 * @interface ProjectNews
 */
export interface ProjectNews {
    /**
     * Id novinky
     * @type {number}
     * @memberof ProjectNews
     */
    readonly id?: number;
    /**
     * Id projektu
     * @type {number}
     * @memberof ProjectNews
     */
    readonly projectId?: number;
    /**
     * Jméno společnosti
     * @type {string}
     * @memberof ProjectNews
     */
    readonly companyName?: string | null;
    /**
     * Popis
     * @type {string}
     * @memberof ProjectNews
     */
    readonly description?: string | null;
    /**
     * Úroven zobrazení
     * @type {number}
     * @memberof ProjectNews
     */
    readonly level?: number;
    /**
     * Datum novinky
     * @type {Date}
     * @memberof ProjectNews
     */
    readonly date?: Date;
    /**
     * Zda má uživatel do projektu zainvestováno
     * @type {boolean}
     * @memberof ProjectNews
     */
    hasInvest?: boolean;
}

/**
 * Check if a given object implements the ProjectNews interface.
 */
export function instanceOfProjectNews(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ProjectNewsFromJSON(json: any): ProjectNews {
    return ProjectNewsFromJSONTyped(json, false);
}

export function ProjectNewsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectNews {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'projectId': !exists(json, 'projectId') ? undefined : json['projectId'],
        'companyName': !exists(json, 'companyName') ? undefined : json['companyName'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'level': !exists(json, 'level') ? undefined : json['level'],
        'date': !exists(json, 'date') ? undefined : (new Date(json['date'])),
        'hasInvest': !exists(json, 'hasInvest') ? undefined : json['hasInvest'],
    };
}

export function ProjectNewsToJSON(value?: ProjectNews | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'hasInvest': value.hasInvest,
    };
}

