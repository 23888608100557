/* tslint:disable */
/* eslint-disable */
/**
 * VentureClub.RestApi2
 * 1.33.2.3, env PROD
 *
 * The version of the OpenAPI document: 1.0
 * Contact: it@spinware.cz
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  PagedListResultDataTypeOfTProjectBaseResultDataType,
  PagedListResultDataTypeOfTTeaserParagraph,
  ProjectExtendedBaseResultDataType,
  ProjectNewsList,
  ResultDataType,
  ResultDataTypeOfTDecimal,
} from '../models/index';
import {
    PagedListResultDataTypeOfTProjectBaseResultDataTypeFromJSON,
    PagedListResultDataTypeOfTProjectBaseResultDataTypeToJSON,
    PagedListResultDataTypeOfTTeaserParagraphFromJSON,
    PagedListResultDataTypeOfTTeaserParagraphToJSON,
    ProjectExtendedBaseResultDataTypeFromJSON,
    ProjectExtendedBaseResultDataTypeToJSON,
    ProjectNewsListFromJSON,
    ProjectNewsListToJSON,
    ResultDataTypeFromJSON,
    ResultDataTypeToJSON,
    ResultDataTypeOfTDecimalFromJSON,
    ResultDataTypeOfTDecimalToJSON,
} from '../models/index';

export interface ApiV1ProjectManagementProjectsGetRequest {
    status?: Array<number>;
    projectId?: Array<number>;
    allLevels?: boolean;
    page?: number;
    limit?: number;
}

export interface ApiV1ProjectManagementProjectsNewsGetRequest {
    projectId?: number;
}

export interface ApiV1ProjectManagementProjectsProjectIdCountedAmountGetRequest {
    projectId: number;
    onlyOwn?: boolean;
}

export interface ApiV1ProjectManagementProjectsProjectIdDividendGetRequest {
    projectId: number;
}

export interface ApiV1ProjectManagementProjectsProjectIdExitDateGetRequest {
    projectId: number;
}

export interface ApiV1ProjectManagementProjectsProjectIdExitGetRequest {
    projectId: number;
}

export interface ApiV1ProjectManagementProjectsProjectIdGetRequest {
    projectId: number;
}

export interface ApiV1ProjectManagementProjectsProjectIdPromisedAmountGetRequest {
    projectId: number;
    onlyOwn?: boolean;
}

export interface ApiV1ProjectManagementProjectsProjectIdTeaserGetRequest {
    projectId: number;
    page?: number;
    limit?: number;
    typeId?: number;
    priority?: boolean;
    stripHtml?: boolean;
    paragraphId?: number;
}

/**
 * 
 */
export class ProjectsApi extends runtime.BaseAPI {

    /**
     * Seznam projektů, projekty jsou řazeny od nejnovějšího
     */
    async apiV1ProjectManagementProjectsGetRaw(requestParameters: ApiV1ProjectManagementProjectsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PagedListResultDataTypeOfTProjectBaseResultDataType>> {
        const queryParameters: any = {};

        if (requestParameters.status) {
            queryParameters['status'] = requestParameters.status;
        }

        if (requestParameters.projectId) {
            queryParameters['projectId'] = requestParameters.projectId;
        }

        if (requestParameters.allLevels !== undefined) {
            queryParameters['allLevels'] = requestParameters.allLevels;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/project-management/projects`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PagedListResultDataTypeOfTProjectBaseResultDataTypeFromJSON(jsonValue));
    }

    /**
     * Seznam projektů, projekty jsou řazeny od nejnovějšího
     */
    async apiV1ProjectManagementProjectsGet(requestParameters: ApiV1ProjectManagementProjectsGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PagedListResultDataTypeOfTProjectBaseResultDataType> {
        const response = await this.apiV1ProjectManagementProjectsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Získání seznamu novinek projektu
     */
    async apiV1ProjectManagementProjectsNewsGetRaw(requestParameters: ApiV1ProjectManagementProjectsNewsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProjectNewsList>> {
        const queryParameters: any = {};

        if (requestParameters.projectId !== undefined) {
            queryParameters['projectId'] = requestParameters.projectId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/project-management/projects/news`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProjectNewsListFromJSON(jsonValue));
    }

    /**
     * Získání seznamu novinek projektu
     */
    async apiV1ProjectManagementProjectsNewsGet(requestParameters: ApiV1ProjectManagementProjectsNewsGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProjectNewsList> {
        const response = await this.apiV1ProjectManagementProjectsNewsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Aktuální SUM výše všech započtených částek na projektu (no cache)
     */
    async apiV1ProjectManagementProjectsProjectIdCountedAmountGetRaw(requestParameters: ApiV1ProjectManagementProjectsProjectIdCountedAmountGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResultDataTypeOfTDecimal>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling apiV1ProjectManagementProjectsProjectIdCountedAmountGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.onlyOwn !== undefined) {
            queryParameters['onlyOwn'] = requestParameters.onlyOwn;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/project-management/projects/{projectId}/counted-amount`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResultDataTypeOfTDecimalFromJSON(jsonValue));
    }

    /**
     * Aktuální SUM výše všech započtených částek na projektu (no cache)
     */
    async apiV1ProjectManagementProjectsProjectIdCountedAmountGet(requestParameters: ApiV1ProjectManagementProjectsProjectIdCountedAmountGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResultDataTypeOfTDecimal> {
        const response = await this.apiV1ProjectManagementProjectsProjectIdCountedAmountGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Aktuální hodnota dividendy na projektu
     */
    async apiV1ProjectManagementProjectsProjectIdDividendGetRaw(requestParameters: ApiV1ProjectManagementProjectsProjectIdDividendGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling apiV1ProjectManagementProjectsProjectIdDividendGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/project-management/projects/{projectId}/dividend`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Aktuální hodnota dividendy na projektu
     */
    async apiV1ProjectManagementProjectsProjectIdDividendGet(requestParameters: ApiV1ProjectManagementProjectsProjectIdDividendGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.apiV1ProjectManagementProjectsProjectIdDividendGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Aktuální hodnota datumu exitu na projektu
     */
    async apiV1ProjectManagementProjectsProjectIdExitDateGetRaw(requestParameters: ApiV1ProjectManagementProjectsProjectIdExitDateGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Date>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling apiV1ProjectManagementProjectsProjectIdExitDateGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/project-management/projects/{projectId}/exit-date`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<Date>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Aktuální hodnota datumu exitu na projektu
     */
    async apiV1ProjectManagementProjectsProjectIdExitDateGet(requestParameters: ApiV1ProjectManagementProjectsProjectIdExitDateGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Date> {
        const response = await this.apiV1ProjectManagementProjectsProjectIdExitDateGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Aktuální hodnota exitu na projektu
     */
    async apiV1ProjectManagementProjectsProjectIdExitGetRaw(requestParameters: ApiV1ProjectManagementProjectsProjectIdExitGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling apiV1ProjectManagementProjectsProjectIdExitGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/project-management/projects/{projectId}/exit`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Aktuální hodnota exitu na projektu
     */
    async apiV1ProjectManagementProjectsProjectIdExitGet(requestParameters: ApiV1ProjectManagementProjectsProjectIdExitGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.apiV1ProjectManagementProjectsProjectIdExitGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Získání detailu projektu
     */
    async apiV1ProjectManagementProjectsProjectIdGetRaw(requestParameters: ApiV1ProjectManagementProjectsProjectIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProjectExtendedBaseResultDataType>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling apiV1ProjectManagementProjectsProjectIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/project-management/projects/{projectId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProjectExtendedBaseResultDataTypeFromJSON(jsonValue));
    }

    /**
     * Získání detailu projektu
     */
    async apiV1ProjectManagementProjectsProjectIdGet(requestParameters: ApiV1ProjectManagementProjectsProjectIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProjectExtendedBaseResultDataType> {
        const response = await this.apiV1ProjectManagementProjectsProjectIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Aktuální SUM výše všech příslibů na projektu (no cache)
     */
    async apiV1ProjectManagementProjectsProjectIdPromisedAmountGetRaw(requestParameters: ApiV1ProjectManagementProjectsProjectIdPromisedAmountGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResultDataTypeOfTDecimal>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling apiV1ProjectManagementProjectsProjectIdPromisedAmountGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.onlyOwn !== undefined) {
            queryParameters['onlyOwn'] = requestParameters.onlyOwn;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/project-management/projects/{projectId}/promised-amount`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResultDataTypeOfTDecimalFromJSON(jsonValue));
    }

    /**
     * Aktuální SUM výše všech příslibů na projektu (no cache)
     */
    async apiV1ProjectManagementProjectsProjectIdPromisedAmountGet(requestParameters: ApiV1ProjectManagementProjectsProjectIdPromisedAmountGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResultDataTypeOfTDecimal> {
        const response = await this.apiV1ProjectManagementProjectsProjectIdPromisedAmountGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Získání teaser projektu
     */
    async apiV1ProjectManagementProjectsProjectIdTeaserGetRaw(requestParameters: ApiV1ProjectManagementProjectsProjectIdTeaserGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PagedListResultDataTypeOfTTeaserParagraph>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling apiV1ProjectManagementProjectsProjectIdTeaserGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.typeId !== undefined) {
            queryParameters['typeId'] = requestParameters.typeId;
        }

        if (requestParameters.priority !== undefined) {
            queryParameters['priority'] = requestParameters.priority;
        }

        if (requestParameters.stripHtml !== undefined) {
            queryParameters['stripHtml'] = requestParameters.stripHtml;
        }

        if (requestParameters.paragraphId !== undefined) {
            queryParameters['paragraphId'] = requestParameters.paragraphId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/project-management/projects/{projectId}/teaser`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PagedListResultDataTypeOfTTeaserParagraphFromJSON(jsonValue));
    }

    /**
     * Získání teaser projektu
     */
    async apiV1ProjectManagementProjectsProjectIdTeaserGet(requestParameters: ApiV1ProjectManagementProjectsProjectIdTeaserGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PagedListResultDataTypeOfTTeaserParagraph> {
        const response = await this.apiV1ProjectManagementProjectsProjectIdTeaserGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
