/* tslint:disable */
/* eslint-disable */
/**
 * VentureClub.RestApi2
 * 1.33.2.3, env PROD
 *
 * The version of the OpenAPI document: 1.0
 * Contact: it@spinware.cz
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Hodnota KPI
 * @export
 * @interface KpiValue
 */
export interface KpiValue {
    /**
     * Období
     * @type {Date}
     * @memberof KpiValue
     */
    scheduledDate?: Date;
    /**
     * Naplánováno, nullable hodnota datového typu dle definice KPI
     * @type {any}
     * @memberof KpiValue
     */
    scheduledValue?: any | null;
    /**
     * Datum a čas zadání skutečné hodnoty
     * @type {Date}
     * @memberof KpiValue
     */
    realityDate?: Date | null;
    /**
     * Skutečnost, nullable hodnota datového typu dle definice KPI
     * @type {any}
     * @memberof KpiValue
     */
    realityValue?: any | null;
}

/**
 * Check if a given object implements the KpiValue interface.
 */
export function instanceOfKpiValue(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function KpiValueFromJSON(json: any): KpiValue {
    return KpiValueFromJSONTyped(json, false);
}

export function KpiValueFromJSONTyped(json: any, ignoreDiscriminator: boolean): KpiValue {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'scheduledDate': !exists(json, 'scheduledDate') ? undefined : (new Date(json['scheduledDate'])),
        'scheduledValue': !exists(json, 'scheduledValue') ? undefined : json['scheduledValue'],
        'realityDate': !exists(json, 'realityDate') ? undefined : (json['realityDate'] === null ? null : new Date(json['realityDate'])),
        'realityValue': !exists(json, 'realityValue') ? undefined : json['realityValue'],
    };
}

export function KpiValueToJSON(value?: KpiValue | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'scheduledDate': value.scheduledDate === undefined ? undefined : (value.scheduledDate.toISOString()),
        'scheduledValue': value.scheduledValue,
        'realityDate': value.realityDate === undefined ? undefined : (value.realityDate === null ? null : value.realityDate.toISOString()),
        'realityValue': value.realityValue,
    };
}

