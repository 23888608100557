export const isAndroid = (w: any): boolean => w.Android !== undefined;
export const isIOS = (w: any): boolean =>
	w.webkit?.messageHandlers.openInNativeBrowser !== undefined;
export const appLinkClick = (value: string): void => {
	const w = window as any;
	if (w !== undefined) {
		if (isAndroid(w)) {
			w.Android.openLink(value);
		} else if (isIOS(w)) {
			w.webkit.messageHandlers.openInNativeBrowser.postMessage(value);
		}
	}
};
