/* tslint:disable */
/* eslint-disable */
/**
 * VentureClub.RestApi v1.33.2.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  PagedListResultDataTypePaymentPrescription,
  ResultDataTypePaymentPrescription,
} from '../models/index';
import {
    PagedListResultDataTypePaymentPrescriptionFromJSON,
    PagedListResultDataTypePaymentPrescriptionToJSON,
    ResultDataTypePaymentPrescriptionFromJSON,
    ResultDataTypePaymentPrescriptionToJSON,
} from '../models/index';

export interface PrescriptionGetPaymentPrescriptionRequest {
    id: number;
    registrationId?: number;
    payed?: boolean;
}

export interface PrescriptionGetPaymentPrescriptionsRequest {
    regId: number;
    payed?: boolean;
    pageNumber?: number;
    pageSize?: number;
    typeId?: number;
}

/**
 * 
 */
export class PrescriptionApi extends runtime.BaseAPI {

    /**
     * Detail platebního předpisu
     */
    async prescriptionGetPaymentPrescriptionRaw(requestParameters: PrescriptionGetPaymentPrescriptionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResultDataTypePaymentPrescription>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling prescriptionGetPaymentPrescription.');
        }

        const queryParameters: any = {};

        if (requestParameters.registrationId !== undefined) {
            queryParameters['registrationId'] = requestParameters.registrationId;
        }

        if (requestParameters.payed !== undefined) {
            queryParameters['payed'] = requestParameters.payed;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/prescriptions/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResultDataTypePaymentPrescriptionFromJSON(jsonValue));
    }

    /**
     * Detail platebního předpisu
     */
    async prescriptionGetPaymentPrescription(requestParameters: PrescriptionGetPaymentPrescriptionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResultDataTypePaymentPrescription> {
        const response = await this.prescriptionGetPaymentPrescriptionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List of payment prescriptions
     */
    async prescriptionGetPaymentPrescriptionsRaw(requestParameters: PrescriptionGetPaymentPrescriptionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PagedListResultDataTypePaymentPrescription>> {
        if (requestParameters.regId === null || requestParameters.regId === undefined) {
            throw new runtime.RequiredError('regId','Required parameter requestParameters.regId was null or undefined when calling prescriptionGetPaymentPrescriptions.');
        }

        const queryParameters: any = {};

        if (requestParameters.regId !== undefined) {
            queryParameters['regId'] = requestParameters.regId;
        }

        if (requestParameters.payed !== undefined) {
            queryParameters['payed'] = requestParameters.payed;
        }

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['pageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.typeId !== undefined) {
            queryParameters['typeId'] = requestParameters.typeId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/prescriptions`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PagedListResultDataTypePaymentPrescriptionFromJSON(jsonValue));
    }

    /**
     * List of payment prescriptions
     */
    async prescriptionGetPaymentPrescriptions(requestParameters: PrescriptionGetPaymentPrescriptionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PagedListResultDataTypePaymentPrescription> {
        const response = await this.prescriptionGetPaymentPrescriptionsRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
