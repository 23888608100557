/* tslint:disable */
/* eslint-disable */
/**
 * VentureClub.RestApi2
 * 1.33.2.3, env PROD
 *
 * The version of the OpenAPI document: 1.0
 * Contact: it@spinware.cz
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Base invoice contact data
 * @export
 * @interface InvoiceContact
 */
export interface InvoiceContact {
    /**
     * Jméno společnosti
     * @type {string}
     * @memberof InvoiceContact
     */
    companyName?: string | null;
    /**
     * Jméno odběratele
     * @type {string}
     * @memberof InvoiceContact
     */
    firstname?: string | null;
    /**
     * Příjmení odběratele
     * @type {string}
     * @memberof InvoiceContact
     */
    surname?: string | null;
    /**
     * Město
     * @type {string}
     * @memberof InvoiceContact
     */
    city?: string | null;
    /**
     * Adresa, ulice a číslo domu
     * @type {string}
     * @memberof InvoiceContact
     */
    street?: string | null;
    /**
     * Poštovní směrovací číslo
     * @type {string}
     * @memberof InvoiceContact
     */
    postalCode?: string | null;
    /**
     * IBAN kód země
     * @type {string}
     * @memberof InvoiceContact
     */
    countryCode?: string | null;
    /**
     * Emailová adresa, povinné
     * @type {string}
     * @memberof InvoiceContact
     */
    email: string;
    /**
     * IČO
     * @type {string}
     * @memberof InvoiceContact
     */
    companyRegNo?: string | null;
    /**
     * DIČ
     * @type {string}
     * @memberof InvoiceContact
     */
    companyVatNo?: string | null;
}

/**
 * Check if a given object implements the InvoiceContact interface.
 */
export function instanceOfInvoiceContact(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "email" in value;

    return isInstance;
}

export function InvoiceContactFromJSON(json: any): InvoiceContact {
    return InvoiceContactFromJSONTyped(json, false);
}

export function InvoiceContactFromJSONTyped(json: any, ignoreDiscriminator: boolean): InvoiceContact {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'companyName': !exists(json, 'companyName') ? undefined : json['companyName'],
        'firstname': !exists(json, 'firstname') ? undefined : json['firstname'],
        'surname': !exists(json, 'surname') ? undefined : json['surname'],
        'city': !exists(json, 'city') ? undefined : json['city'],
        'street': !exists(json, 'street') ? undefined : json['street'],
        'postalCode': !exists(json, 'postalCode') ? undefined : json['postalCode'],
        'countryCode': !exists(json, 'countryCode') ? undefined : json['countryCode'],
        'email': json['email'],
        'companyRegNo': !exists(json, 'companyRegNo') ? undefined : json['companyRegNo'],
        'companyVatNo': !exists(json, 'companyVatNo') ? undefined : json['companyVatNo'],
    };
}

export function InvoiceContactToJSON(value?: InvoiceContact | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'companyName': value.companyName,
        'firstname': value.firstname,
        'surname': value.surname,
        'city': value.city,
        'street': value.street,
        'postalCode': value.postalCode,
        'countryCode': value.countryCode,
        'email': value.email,
        'companyRegNo': value.companyRegNo,
        'companyVatNo': value.companyVatNo,
    };
}

