import { createContext, type ReactElement, useContext } from "react";

export const UrlParamsContext = createContext({} as any);

export const useUrlParams = <TUrlParams,>(): TUrlParams => {
	return useContext(UrlParamsContext);
};

interface IProps {
	params: any;
	children: ReactElement;
}

export const UrlParamsProvider = ({ children, params = {} }: IProps): JSX.Element => {
	return <UrlParamsContext.Provider value={params}>{children}</UrlParamsContext.Provider>;
};
