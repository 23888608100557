import { ErrorBoundary } from "@sentry/gatsby";
import { type GatsbyBrowser } from "gatsby";
import { Helmet } from "react-helmet";

import GeneralFallback from "./src/components/Errors/GeneralFallback";
import Layout from "./src/components/Layout/LayoutMenu";
import SimpleLayout from "./src/components/Layout/SimpleLayout";
import { ApiClientProvider } from "./src/context/ApiProvider";
import { IdentityProvider } from "./src/context/IdentityProvider";
import { KeepUrlProvider } from "./src/context/KeepUrlProvider";
import LanguageProvider from "./src/context/LanguageProvider";
import PushNotificationsProvider from "./src/context/PushNotificationsProvider";
import QueryProvider from "./src/context/QueryProvider";
import { UrlParamsProvider } from "./src/context/UrlParamsProvider";

export const validEnv =
	typeof process.env.GATSBY_BASIC_USERNAME !== "undefined" &&
	typeof process.env.GATSBY_BASIC_PASSWORD !== "undefined";

export const wrapRootElement = ({ element }): JSX.Element => {
	return (
		<ErrorBoundary fallback={GeneralFallback}>
			<Helmet>
				<meta
					name="viewport"
					content="width=device-width, initial-scale=1, viewport-fit=cover, user-scalable=no"
				/>
			</Helmet>
			<KeepUrlProvider>
				<QueryProvider>
					<IdentityProvider>
						<ApiClientProvider>
							<LanguageProvider>
								<PushNotificationsProvider>{element}</PushNotificationsProvider>
							</LanguageProvider>
						</ApiClientProvider>
					</IdentityProvider>
				</QueryProvider>
			</KeepUrlProvider>
		</ErrorBoundary>
	);
};

export const wrapPageElement: GatsbyBrowser["wrapPageElement"] = ({ element, props }) => {
	const layout = props.pageContext?.additionalProps?.layout;

	const content = {
		simple: <SimpleLayout {...props}>{element}</SimpleLayout>,
		withNavigate: <Layout {...props}>{element}</Layout>,
		none: <>{element}</>,
	};

	return <UrlParamsProvider params={props.params}>{content[layout]}</UrlParamsProvider>;
};
