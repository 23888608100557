import SvgIcon from "@mui/material/SvgIcon";
import React from "react";

import type { ColorProps } from "../types";
const Investing = ({ color }: ColorProps): JSX.Element => {
	return (
		<SvgIcon fill={color}>
			<svg width="20" height="18" viewBox="0 0 20 18" xmlns="http://www.w3.org/2000/svg">
				<path d="M0.367188 16.2656V1.21875C0.367188 0.984375 0.449219 0.782227 0.613281 0.612305C0.777344 0.442383 0.985352 0.357422 1.2373 0.357422C1.47754 0.357422 1.67969 0.442383 1.84375 0.612305C2.01367 0.782227 2.09863 0.984375 2.09863 1.21875V15.0439C2.09863 15.208 2.17773 15.29 2.33594 15.29H18.7275C18.9678 15.29 19.1729 15.3721 19.3428 15.5361C19.5186 15.7002 19.6064 15.9053 19.6064 16.1514C19.6064 16.3975 19.5186 16.6025 19.3428 16.7666C19.1729 16.9307 18.9678 17.0127 18.7275 17.0127H1.12305C0.894531 17.0127 0.709961 16.9424 0.569336 16.8018C0.43457 16.667 0.367188 16.4883 0.367188 16.2656ZM1.94922 11.4668L0.71875 10.2451L4.68262 6.22852C4.89355 6.00586 5.10156 5.84766 5.30664 5.75391C5.51758 5.66016 5.73145 5.61328 5.94824 5.61328C6.16504 5.61328 6.37891 5.66309 6.58984 5.7627C6.80078 5.85645 7.00879 6.01172 7.21387 6.22852L10.3516 9.47168C10.4277 9.55371 10.5039 9.59473 10.5801 9.59473C10.6621 9.59473 10.7412 9.55371 10.8174 9.47168L14.2275 5.99121L12.8652 4.62012C12.7363 4.49707 12.6719 4.36523 12.6719 4.22461C12.6719 4.08398 12.7217 3.95508 12.8213 3.83789C12.9209 3.7207 13.0615 3.63867 13.2432 3.5918L18.4023 2.27344C18.5605 2.23242 18.707 2.24707 18.8418 2.31738C18.9766 2.38184 19.0791 2.48145 19.1494 2.61621C19.2197 2.74512 19.2344 2.8916 19.1934 3.05566L17.8662 8.22363C17.8193 8.40527 17.7373 8.5459 17.6201 8.64551C17.5029 8.74512 17.374 8.79492 17.2334 8.79492C17.0928 8.79492 16.958 8.73047 16.8291 8.60156L15.458 7.22168L11.8457 10.8516C11.4297 11.2617 11.0078 11.4668 10.5801 11.4668C10.3574 11.4668 10.1406 11.417 9.92969 11.3174C9.71875 11.2178 9.51367 11.0625 9.31445 10.8516L6.17676 7.59961C6.10059 7.52344 6.02441 7.48535 5.94824 7.48535C5.86621 7.48535 5.78711 7.52344 5.71094 7.59961L1.94922 11.4668Z" />
			</svg>
		</SvgIcon>
	);
};
export default Investing;
