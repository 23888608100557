/* tslint:disable */
/* eslint-disable */
/**
 * VentureClub.RestApi v1.33.2.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  LoginResultDataTypeBase,
} from '../models/index';
import {
    LoginResultDataTypeBaseFromJSON,
    LoginResultDataTypeBaseToJSON,
} from '../models/index';

export interface UserGetUserRequest {
    languageCode: string;
    cookieToken: string;
    ipAddress: string;
    userAgent: string;
}

/**
 * 
 */
export class UserApi extends runtime.BaseAPI {

    /**
     * Vrací detailní informace o uživateli, na základě jeho přihlášení - AUTH
     */
    async userGetUserRaw(requestParameters: UserGetUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LoginResultDataTypeBase>> {
        if (requestParameters.languageCode === null || requestParameters.languageCode === undefined) {
            throw new runtime.RequiredError('languageCode','Required parameter requestParameters.languageCode was null or undefined when calling userGetUser.');
        }

        if (requestParameters.cookieToken === null || requestParameters.cookieToken === undefined) {
            throw new runtime.RequiredError('cookieToken','Required parameter requestParameters.cookieToken was null or undefined when calling userGetUser.');
        }

        if (requestParameters.ipAddress === null || requestParameters.ipAddress === undefined) {
            throw new runtime.RequiredError('ipAddress','Required parameter requestParameters.ipAddress was null or undefined when calling userGetUser.');
        }

        if (requestParameters.userAgent === null || requestParameters.userAgent === undefined) {
            throw new runtime.RequiredError('userAgent','Required parameter requestParameters.userAgent was null or undefined when calling userGetUser.');
        }

        const queryParameters: any = {};

        if (requestParameters.languageCode !== undefined) {
            queryParameters['languageCode'] = requestParameters.languageCode;
        }

        if (requestParameters.cookieToken !== undefined) {
            queryParameters['cookieToken'] = requestParameters.cookieToken;
        }

        if (requestParameters.ipAddress !== undefined) {
            queryParameters['ipAddress'] = requestParameters.ipAddress;
        }

        if (requestParameters.userAgent !== undefined) {
            queryParameters['userAgent'] = requestParameters.userAgent;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/user/detail`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LoginResultDataTypeBaseFromJSON(jsonValue));
    }

    /**
     * Vrací detailní informace o uživateli, na základě jeho přihlášení - AUTH
     */
    async userGetUser(requestParameters: UserGetUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LoginResultDataTypeBase> {
        const response = await this.userGetUserRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
