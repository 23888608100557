/* tslint:disable */
/* eslint-disable */
/**
 * VentureClub.RestApi2
 * 1.33.2.3, env PROD
 *
 * The version of the OpenAPI document: 1.0
 * Contact: it@spinware.cz
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * KPI
 * @export
 * @interface KpiBaseResultDataType
 */
export interface KpiBaseResultDataType {
    /**
     * 
     * @type {number}
     * @memberof KpiBaseResultDataType
     */
    resultCode?: number;
    /**
     * 
     * @type {boolean}
     * @memberof KpiBaseResultDataType
     */
    error?: boolean;
    /**
     * 
     * @type {string}
     * @memberof KpiBaseResultDataType
     */
    errorMessage?: string | null;
    /**
     * Id KPI
     * @type {number}
     * @memberof KpiBaseResultDataType
     */
    id?: number;
    /**
     * Id projektu
     * @type {number}
     * @memberof KpiBaseResultDataType
     */
    projectId?: number;
    /**
     * Období od
     * @type {Date}
     * @memberof KpiBaseResultDataType
     */
    validFrom?: Date;
    /**
     * Obdoví do
     * @type {Date}
     * @memberof KpiBaseResultDataType
     */
    validTo?: Date;
    /**
     * Jméno KPI
     * @type {string}
     * @memberof KpiBaseResultDataType
     */
    name?: string | null;
    /**
     * Zkratka - kód
     * @type {string}
     * @memberof KpiBaseResultDataType
     */
    code?: string | null;
    /**
     * Perioda (měsíční)
     * @type {number}
     * @memberof KpiBaseResultDataType
     */
    period?: number;
    /**
     * Jednotka
     * @type {string}
     * @memberof KpiBaseResultDataType
     */
    unit?: string | null;
    /**
     * Jedná se o výkonostní ukazatel projektu
     * @type {string}
     * @memberof KpiBaseResultDataType
     */
    isPerformanceIndicator?: string | null;
    /**
     * Kpi hodnoty obsahuji pouze skutečné hodnoty, ne plánované
     * @type {boolean}
     * @memberof KpiBaseResultDataType
     */
    readonly hasOnlyRealityValues?: boolean;
}

/**
 * Check if a given object implements the KpiBaseResultDataType interface.
 */
export function instanceOfKpiBaseResultDataType(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function KpiBaseResultDataTypeFromJSON(json: any): KpiBaseResultDataType {
    return KpiBaseResultDataTypeFromJSONTyped(json, false);
}

export function KpiBaseResultDataTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): KpiBaseResultDataType {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'resultCode': !exists(json, 'resultCode') ? undefined : json['resultCode'],
        'error': !exists(json, 'error') ? undefined : json['error'],
        'errorMessage': !exists(json, 'errorMessage') ? undefined : json['errorMessage'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'projectId': !exists(json, 'projectId') ? undefined : json['projectId'],
        'validFrom': !exists(json, 'validFrom') ? undefined : (new Date(json['validFrom'])),
        'validTo': !exists(json, 'validTo') ? undefined : (new Date(json['validTo'])),
        'name': !exists(json, 'name') ? undefined : json['name'],
        'code': !exists(json, 'code') ? undefined : json['code'],
        'period': !exists(json, 'period') ? undefined : json['period'],
        'unit': !exists(json, 'unit') ? undefined : json['unit'],
        'isPerformanceIndicator': !exists(json, 'isPerformanceIndicator') ? undefined : json['isPerformanceIndicator'],
        'hasOnlyRealityValues': !exists(json, 'hasOnlyRealityValues') ? undefined : json['hasOnlyRealityValues'],
    };
}

export function KpiBaseResultDataTypeToJSON(value?: KpiBaseResultDataType | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'resultCode': value.resultCode,
        'error': value.error,
        'errorMessage': value.errorMessage,
        'id': value.id,
        'projectId': value.projectId,
        'validFrom': value.validFrom === undefined ? undefined : (value.validFrom.toISOString()),
        'validTo': value.validTo === undefined ? undefined : (value.validTo.toISOString()),
        'name': value.name,
        'code': value.code,
        'period': value.period,
        'unit': value.unit,
        'isPerformanceIndicator': value.isPerformanceIndicator,
    };
}

