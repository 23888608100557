/* tslint:disable */
/* eslint-disable */
/**
 * VentureClub.RestApi v1.33.2.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BoolResultDataType
 */
export interface BoolResultDataType {
    /**
     * 
     * @type {boolean}
     * @memberof BoolResultDataType
     */
    result?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BoolResultDataType
     */
    error?: boolean;
    /**
     * 
     * @type {string}
     * @memberof BoolResultDataType
     */
    errorMessage?: string;
}

/**
 * Check if a given object implements the BoolResultDataType interface.
 */
export function instanceOfBoolResultDataType(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function BoolResultDataTypeFromJSON(json: any): BoolResultDataType {
    return BoolResultDataTypeFromJSONTyped(json, false);
}

export function BoolResultDataTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): BoolResultDataType {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'result': !exists(json, 'Result') ? undefined : json['Result'],
        'error': !exists(json, 'Error') ? undefined : json['Error'],
        'errorMessage': !exists(json, 'ErrorMessage') ? undefined : json['ErrorMessage'],
    };
}

export function BoolResultDataTypeToJSON(value?: BoolResultDataType | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Result': value.result,
        'Error': value.error,
        'ErrorMessage': value.errorMessage,
    };
}

