import { type IdentityTokenDecoded } from "../queryHooks/user";

export const USER_LOCAL_STORAGE_KEY = "USER";

export const saveUser = (user: IdentityTokenDecoded): void => {
	if (typeof window !== "undefined") {
		localStorage.setItem(USER_LOCAL_STORAGE_KEY, JSON.stringify(user));
	}
};

export const getUser = (): IdentityTokenDecoded => {
	const user = typeof window !== "undefined" && localStorage.getItem(USER_LOCAL_STORAGE_KEY);
	return typeof user === "string" && typeof user !== "undefined" ? JSON.parse(user) : undefined;
};

export const removeUser = (): void => {
	localStorage.removeItem(USER_LOCAL_STORAGE_KEY);
};
