/* tslint:disable */
/* eslint-disable */
/**
 * VentureClub.RestApi v1.33.2.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InvoiceContact
 */
export interface InvoiceContact {
    /**
     * 
     * @type {string}
     * @memberof InvoiceContact
     */
    companyName?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceContact
     */
    firstname?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceContact
     */
    surname?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceContact
     */
    city?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceContact
     */
    street?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceContact
     */
    postalCode?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceContact
     */
    countryCode?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceContact
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceContact
     */
    companyRegNo?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceContact
     */
    companyVatNo?: string;
}

/**
 * Check if a given object implements the InvoiceContact interface.
 */
export function instanceOfInvoiceContact(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "email" in value;

    return isInstance;
}

export function InvoiceContactFromJSON(json: any): InvoiceContact {
    return InvoiceContactFromJSONTyped(json, false);
}

export function InvoiceContactFromJSONTyped(json: any, ignoreDiscriminator: boolean): InvoiceContact {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'companyName': !exists(json, 'CompanyName') ? undefined : json['CompanyName'],
        'firstname': !exists(json, 'Firstname') ? undefined : json['Firstname'],
        'surname': !exists(json, 'Surname') ? undefined : json['Surname'],
        'city': !exists(json, 'City') ? undefined : json['City'],
        'street': !exists(json, 'Street') ? undefined : json['Street'],
        'postalCode': !exists(json, 'PostalCode') ? undefined : json['PostalCode'],
        'countryCode': !exists(json, 'CountryCode') ? undefined : json['CountryCode'],
        'email': json['Email'],
        'companyRegNo': !exists(json, 'CompanyRegNo') ? undefined : json['CompanyRegNo'],
        'companyVatNo': !exists(json, 'CompanyVatNo') ? undefined : json['CompanyVatNo'],
    };
}

export function InvoiceContactToJSON(value?: InvoiceContact | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'CompanyName': value.companyName,
        'Firstname': value.firstname,
        'Surname': value.surname,
        'City': value.city,
        'Street': value.street,
        'PostalCode': value.postalCode,
        'CountryCode': value.countryCode,
        'Email': value.email,
        'CompanyRegNo': value.companyRegNo,
        'CompanyVatNo': value.companyVatNo,
    };
}

