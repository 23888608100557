/* tslint:disable */
/* eslint-disable */
/**
 * VentureClub.RestApi2
 * 1.33.2.3, env PROD
 *
 * The version of the OpenAPI document: 1.0
 * Contact: it@spinware.cz
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PerfKpiPeriod } from './PerfKpiPeriod';
import {
    PerfKpiPeriodFromJSON,
    PerfKpiPeriodFromJSONTyped,
    PerfKpiPeriodToJSON,
} from './PerfKpiPeriod';

/**
 * Výkonnostní ukazatele KPI projektu
 * @export
 * @interface ProjectPerfKpi
 */
export interface ProjectPerfKpi {
    /**
     * Id projektu
     * @type {number}
     * @memberof ProjectPerfKpi
     */
    projectId?: number;
    /**
     * Kód projektu
     * @type {string}
     * @memberof ProjectPerfKpi
     */
    readonly projectCode?: string | null;
    /**
     * Jméno projektu
     * @type {string}
     * @memberof ProjectPerfKpi
     */
    projectName?: string | null;
    /**
     * Aktuální skutečně započtené přísliby
     * @type {number}
     * @memberof ProjectPerfKpi
     */
    countedInvestmentsAmount?: number;
    /**
     * Původní plánovaná cílová hodnota portfolia,
     * započtená investice * (původní výnos a exit).
     * pozn. neobsahuje srážky - jsou na jednotlivých příslibech
     * @type {number}
     * @memberof ProjectPerfKpi
     */
    plannedPortfolioValue?: number;
    /**
     * Aktuální hodnota portfolia
     * Dividenda a Exit je bráno z KPI poslední hodnota (pokud je vyplněna, jinak z plánované)
     * @type {number}
     * @memberof ProjectPerfKpi
     */
    currentPortfolioValue?: number | null;
    /**
     * Poslední odhad dividendy projektu %
     * @type {number}
     * @memberof ProjectPerfKpi
     */
    readonly lastDividendPrc?: number;
    /**
     * Poslední odhad exitu projektu %
     * @type {number}
     * @memberof ProjectPerfKpi
     */
    readonly lastExitPrc?: number;
    /**
     * Periody výkonnostních KPI projektu
     * @type {Array<PerfKpiPeriod>}
     * @memberof ProjectPerfKpi
     */
    perfKpiPeriods?: Array<PerfKpiPeriod> | null;
}

/**
 * Check if a given object implements the ProjectPerfKpi interface.
 */
export function instanceOfProjectPerfKpi(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ProjectPerfKpiFromJSON(json: any): ProjectPerfKpi {
    return ProjectPerfKpiFromJSONTyped(json, false);
}

export function ProjectPerfKpiFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectPerfKpi {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'projectId': !exists(json, 'projectId') ? undefined : json['projectId'],
        'projectCode': !exists(json, 'projectCode') ? undefined : json['projectCode'],
        'projectName': !exists(json, 'projectName') ? undefined : json['projectName'],
        'countedInvestmentsAmount': !exists(json, 'countedInvestmentsAmount') ? undefined : json['countedInvestmentsAmount'],
        'plannedPortfolioValue': !exists(json, 'plannedPortfolioValue') ? undefined : json['plannedPortfolioValue'],
        'currentPortfolioValue': !exists(json, 'currentPortfolioValue') ? undefined : json['currentPortfolioValue'],
        'lastDividendPrc': !exists(json, 'lastDividendPrc') ? undefined : json['lastDividendPrc'],
        'lastExitPrc': !exists(json, 'lastExitPrc') ? undefined : json['lastExitPrc'],
        'perfKpiPeriods': !exists(json, 'perfKpiPeriods') ? undefined : (json['perfKpiPeriods'] === null ? null : (json['perfKpiPeriods'] as Array<any>).map(PerfKpiPeriodFromJSON)),
    };
}

export function ProjectPerfKpiToJSON(value?: ProjectPerfKpi | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'projectId': value.projectId,
        'projectName': value.projectName,
        'countedInvestmentsAmount': value.countedInvestmentsAmount,
        'plannedPortfolioValue': value.plannedPortfolioValue,
        'currentPortfolioValue': value.currentPortfolioValue,
        'perfKpiPeriods': value.perfKpiPeriods === undefined ? undefined : (value.perfKpiPeriods === null ? null : (value.perfKpiPeriods as Array<any>).map(PerfKpiPeriodToJSON)),
    };
}

