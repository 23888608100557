import { type SupportedLanguageType } from "../context/LanguageProvider";

export const USER_LANGUAGE_CODE_KEY = "LANGUAGE";

export const SUPPORTED_LANGUAGES = ["cs", "en"];

export const saveLanguageCode = (languageCode: SupportedLanguageType): void => {
	if (typeof window !== "undefined") {
		localStorage.setItem(USER_LANGUAGE_CODE_KEY, languageCode);
	}
};

export const getLanguageCode = (): "cs" | "en" => {
	if (typeof window !== "undefined") {
		const languageCode = localStorage.getItem(USER_LANGUAGE_CODE_KEY) ?? "cs";
		return languageCode === "cs" || languageCode === "en" ? languageCode : "cs";
	} else {
		return "cs";
	}
};
