/* tslint:disable */
/* eslint-disable */
/**
 * VentureClub.RestApi2
 * 1.33.2.3, env PROD
 *
 * The version of the OpenAPI document: 1.0
 * Contact: it@spinware.cz
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  PagedListResultDataTypeOfTUserInvestmentsBaseResultDataType,
  ResultDataType,
} from '../models/index';
import {
    PagedListResultDataTypeOfTUserInvestmentsBaseResultDataTypeFromJSON,
    PagedListResultDataTypeOfTUserInvestmentsBaseResultDataTypeToJSON,
    ResultDataTypeFromJSON,
    ResultDataTypeToJSON,
} from '../models/index';

export interface ApiV1UserManagementInvestmentsGetRequest {
    projectId?: number;
    page?: number;
    limit?: number;
}

/**
 * 
 */
export class UserInvestmentsApi extends runtime.BaseAPI {

    /**
     * Portfolio uživatele - zainvestované projekty
     */
    async apiV1UserManagementInvestmentsGetRaw(requestParameters: ApiV1UserManagementInvestmentsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PagedListResultDataTypeOfTUserInvestmentsBaseResultDataType>> {
        const queryParameters: any = {};

        if (requestParameters.projectId !== undefined) {
            queryParameters['projectId'] = requestParameters.projectId;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/user-management/investments`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PagedListResultDataTypeOfTUserInvestmentsBaseResultDataTypeFromJSON(jsonValue));
    }

    /**
     * Portfolio uživatele - zainvestované projekty
     */
    async apiV1UserManagementInvestmentsGet(requestParameters: ApiV1UserManagementInvestmentsGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PagedListResultDataTypeOfTUserInvestmentsBaseResultDataType> {
        const response = await this.apiV1UserManagementInvestmentsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
