/* tslint:disable */
/* eslint-disable */
/**
 * VentureClub.RestApi v1.33.2.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface VersionInfo
 */
export interface VersionInfo {
    /**
     * 
     * @type {string}
     * @memberof VersionInfo
     */
    enviromentCode?: string;
    /**
     * 
     * @type {number}
     * @memberof VersionInfo
     */
    dbVersion?: number;
    /**
     * 
     * @type {string}
     * @memberof VersionInfo
     */
    appVersion?: string;
    /**
     * 
     * @type {string}
     * @memberof VersionInfo
     */
    apiVersion?: string;
}

/**
 * Check if a given object implements the VersionInfo interface.
 */
export function instanceOfVersionInfo(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function VersionInfoFromJSON(json: any): VersionInfo {
    return VersionInfoFromJSONTyped(json, false);
}

export function VersionInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): VersionInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'enviromentCode': !exists(json, 'EnviromentCode') ? undefined : json['EnviromentCode'],
        'dbVersion': !exists(json, 'DbVersion') ? undefined : json['DbVersion'],
        'appVersion': !exists(json, 'AppVersion') ? undefined : json['AppVersion'],
        'apiVersion': !exists(json, 'ApiVersion') ? undefined : json['ApiVersion'],
    };
}

export function VersionInfoToJSON(value?: VersionInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'EnviromentCode': value.enviromentCode,
        'DbVersion': value.dbVersion,
        'AppVersion': value.appVersion,
        'ApiVersion': value.apiVersion,
    };
}

