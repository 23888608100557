/* tslint:disable */
/* eslint-disable */
/**
 * VentureClub.RestApi2
 * 1.33.2.3, env PROD
 *
 * The version of the OpenAPI document: 1.0
 * Contact: it@spinware.cz
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BoolResultDataType
 */
export interface BoolResultDataType {
    /**
     * 
     * @type {number}
     * @memberof BoolResultDataType
     */
    resultCode?: number;
    /**
     * 
     * @type {boolean}
     * @memberof BoolResultDataType
     */
    error?: boolean;
    /**
     * 
     * @type {string}
     * @memberof BoolResultDataType
     */
    errorMessage?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof BoolResultDataType
     */
    result?: boolean;
}

/**
 * Check if a given object implements the BoolResultDataType interface.
 */
export function instanceOfBoolResultDataType(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function BoolResultDataTypeFromJSON(json: any): BoolResultDataType {
    return BoolResultDataTypeFromJSONTyped(json, false);
}

export function BoolResultDataTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): BoolResultDataType {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'resultCode': !exists(json, 'resultCode') ? undefined : json['resultCode'],
        'error': !exists(json, 'error') ? undefined : json['error'],
        'errorMessage': !exists(json, 'errorMessage') ? undefined : json['errorMessage'],
        'result': !exists(json, 'result') ? undefined : json['result'],
    };
}

export function BoolResultDataTypeToJSON(value?: BoolResultDataType | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'resultCode': value.resultCode,
        'error': value.error,
        'errorMessage': value.errorMessage,
        'result': value.result,
    };
}

