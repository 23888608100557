/* tslint:disable */
/* eslint-disable */
/**
 * VentureClub.RestApi v1.33.2.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface IdentityToken
 */
export interface IdentityToken {
    /**
     * 
     * @type {string}
     * @memberof IdentityToken
     */
    accessToken?: string;
    /**
     * 
     * @type {number}
     * @memberof IdentityToken
     */
    expiresIn?: number;
    /**
     * 
     * @type {string}
     * @memberof IdentityToken
     */
    refreshToken?: string;
    /**
     * 
     * @type {string}
     * @memberof IdentityToken
     */
    tokenType?: string;
}

/**
 * Check if a given object implements the IdentityToken interface.
 */
export function instanceOfIdentityToken(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function IdentityTokenFromJSON(json: any): IdentityToken {
    return IdentityTokenFromJSONTyped(json, false);
}

export function IdentityTokenFromJSONTyped(json: any, ignoreDiscriminator: boolean): IdentityToken {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'accessToken': !exists(json, 'access_token') ? undefined : json['access_token'],
        'expiresIn': !exists(json, 'expires_in') ? undefined : json['expires_in'],
        'refreshToken': !exists(json, 'refresh_token') ? undefined : json['refresh_token'],
        'tokenType': !exists(json, 'token_type') ? undefined : json['token_type'],
    };
}

export function IdentityTokenToJSON(value?: IdentityToken | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'access_token': value.accessToken,
        'expires_in': value.expiresIn,
        'refresh_token': value.refreshToken,
        'token_type': value.tokenType,
    };
}

