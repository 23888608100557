/* tslint:disable */
/* eslint-disable */
/**
 * VentureClub.RestApi2
 * 1.33.2.3, env PROD
 *
 * The version of the OpenAPI document: 1.0
 * Contact: it@spinware.cz
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Hodnota výkonnostního KPI
 * @export
 * @interface PerfKpiValue
 */
export interface PerfKpiValue {
    /**
     * Kpi ID
     * @type {number}
     * @memberof PerfKpiValue
     */
    kpiId?: number;
    /**
     * Jméno KPI
     * @type {string}
     * @memberof PerfKpiValue
     */
    name?: string | null;
    /**
     * Hodnota KPI v %
     * @type {number}
     * @memberof PerfKpiValue
     */
    valuePrc?: number | null;
}

/**
 * Check if a given object implements the PerfKpiValue interface.
 */
export function instanceOfPerfKpiValue(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PerfKpiValueFromJSON(json: any): PerfKpiValue {
    return PerfKpiValueFromJSONTyped(json, false);
}

export function PerfKpiValueFromJSONTyped(json: any, ignoreDiscriminator: boolean): PerfKpiValue {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'kpiId': !exists(json, 'kpiId') ? undefined : json['kpiId'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'valuePrc': !exists(json, 'valuePrc') ? undefined : json['valuePrc'],
    };
}

export function PerfKpiValueToJSON(value?: PerfKpiValue | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'kpiId': value.kpiId,
        'name': value.name,
        'valuePrc': value.valuePrc,
    };
}

