import { useQuery, type UseQueryResult } from "@tanstack/react-query";

import { type BaseUserInfoDataResultType } from "../../API/main/models/";
import { useApiClient } from "../context/ApiProvider";

export const useUserDetail = (
	options?: Record<string, any>,
): UseQueryResult<BaseUserInfoDataResultType, boolean | undefined> => {
	const clientsApi = useApiClient();

	return useQuery({
		...options,
		queryKey: ["userDetail"],
		queryFn: async () => {
			return await clientsApi.UserApi.apiV1UserManagementUserGet();
		},
	});
};
