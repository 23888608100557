/* tslint:disable */
/* eslint-disable */
/**
 * VentureClub.RestApi2
 * 1.33.2.3, env PROD
 *
 * The version of the OpenAPI document: 1.0
 * Contact: it@spinware.cz
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  BaseUserInfoDataResultType,
  BoolResultDataType,
  ResultDataType,
  ResultDataTypeOfTString,
  SubscriptionRequest,
} from '../models/index';
import {
    BaseUserInfoDataResultTypeFromJSON,
    BaseUserInfoDataResultTypeToJSON,
    BoolResultDataTypeFromJSON,
    BoolResultDataTypeToJSON,
    ResultDataTypeFromJSON,
    ResultDataTypeToJSON,
    ResultDataTypeOfTStringFromJSON,
    ResultDataTypeOfTStringToJSON,
    SubscriptionRequestFromJSON,
    SubscriptionRequestToJSON,
} from '../models/index';

export interface ApiV1UserManagementUserAuthCodeGetRequest {
    url?: string;
}

export interface ApiV1UserManagementUserLostPasswordPutRequest {
    email?: string;
}

export interface ApiV1UserManagementUserSubscriptionPutRequest {
    subscriptionRequest?: SubscriptionRequest;
}

/**
 * 
 */
export class UserApi extends runtime.BaseAPI {

    /**
     * Vygenerování jednorázového přihlašovacího tokenu pro web, TTL 5min, nutná stejná IP žadatele. Při žádosti o access  token (přihlášení) musí scope obsahovat app_auth-code.
     */
    async apiV1UserManagementUserAuthCodeGetRaw(requestParameters: ApiV1UserManagementUserAuthCodeGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResultDataTypeOfTString>> {
        const queryParameters: any = {};

        if (requestParameters.url !== undefined) {
            queryParameters['url'] = requestParameters.url;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/user-management/user/auth-code`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResultDataTypeOfTStringFromJSON(jsonValue));
    }

    /**
     * Vygenerování jednorázového přihlašovacího tokenu pro web, TTL 5min, nutná stejná IP žadatele. Při žádosti o access  token (přihlášení) musí scope obsahovat app_auth-code.
     */
    async apiV1UserManagementUserAuthCodeGet(requestParameters: ApiV1UserManagementUserAuthCodeGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResultDataTypeOfTString> {
        const response = await this.apiV1UserManagementUserAuthCodeGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Základní informace o uživateli
     */
    async apiV1UserManagementUserGetRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BaseUserInfoDataResultType>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/user-management/user`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BaseUserInfoDataResultTypeFromJSON(jsonValue));
    }

    /**
     * Základní informace o uživateli
     */
    async apiV1UserManagementUserGet(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BaseUserInfoDataResultType> {
        const response = await this.apiV1UserManagementUserGetRaw(initOverrides);
        return await response.value();
    }

    /**
     * Žádost o změnu ztraceného hesla
     */
    async apiV1UserManagementUserLostPasswordPutRaw(requestParameters: ApiV1UserManagementUserLostPasswordPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BoolResultDataType>> {
        const queryParameters: any = {};

        if (requestParameters.email !== undefined) {
            queryParameters['email'] = requestParameters.email;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/user-management/user/lost-password`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BoolResultDataTypeFromJSON(jsonValue));
    }

    /**
     * Žádost o změnu ztraceného hesla
     */
    async apiV1UserManagementUserLostPasswordPut(requestParameters: ApiV1UserManagementUserLostPasswordPutRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BoolResultDataType> {
        const response = await this.apiV1UserManagementUserLostPasswordPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Správa odběru Firebase Cloud Messaging.
     */
    async apiV1UserManagementUserSubscriptionPutRaw(requestParameters: ApiV1UserManagementUserSubscriptionPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResultDataType>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json; x-api-version=1.0';

        const response = await this.request({
            path: `/api/v1/user-management/user/subscription`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: SubscriptionRequestToJSON(requestParameters.subscriptionRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResultDataTypeFromJSON(jsonValue));
    }

    /**
     * Správa odběru Firebase Cloud Messaging.
     */
    async apiV1UserManagementUserSubscriptionPut(requestParameters: ApiV1UserManagementUserSubscriptionPutRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResultDataType> {
        const response = await this.apiV1UserManagementUserSubscriptionPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
