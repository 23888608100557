/* tslint:disable */
/* eslint-disable */
/**
 * VentureClub.RestApi v1.33.2.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RegistrationBase
 */
export interface RegistrationBase {
    /**
     * 
     * @type {string}
     * @memberof RegistrationBase
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof RegistrationBase
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof RegistrationBase
     */
    surname?: string;
    /**
     * 
     * @type {string}
     * @memberof RegistrationBase
     */
    languageCode?: string;
}

/**
 * Check if a given object implements the RegistrationBase interface.
 */
export function instanceOfRegistrationBase(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function RegistrationBaseFromJSON(json: any): RegistrationBase {
    return RegistrationBaseFromJSONTyped(json, false);
}

export function RegistrationBaseFromJSONTyped(json: any, ignoreDiscriminator: boolean): RegistrationBase {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'email': !exists(json, 'Email') ? undefined : json['Email'],
        'name': !exists(json, 'Name') ? undefined : json['Name'],
        'surname': !exists(json, 'Surname') ? undefined : json['Surname'],
        'languageCode': !exists(json, 'LanguageCode') ? undefined : json['LanguageCode'],
    };
}

export function RegistrationBaseToJSON(value?: RegistrationBase | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Email': value.email,
        'Name': value.name,
        'Surname': value.surname,
        'LanguageCode': value.languageCode,
    };
}

