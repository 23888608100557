/* tslint:disable */
/* eslint-disable */
/**
 * VentureClub.RestApi v1.33.2.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { InvoiceContact } from './InvoiceContact';
import {
    InvoiceContactFromJSON,
    InvoiceContactFromJSONTyped,
    InvoiceContactToJSON,
} from './InvoiceContact';
import type { InvoiceItem } from './InvoiceItem';
import {
    InvoiceItemFromJSON,
    InvoiceItemFromJSONTyped,
    InvoiceItemToJSON,
} from './InvoiceItem';

/**
 * 
 * @export
 * @interface InvoiceData
 */
export interface InvoiceData {
    /**
     * 
     * @type {string}
     * @memberof InvoiceData
     */
    issuerCode?: string;
    /**
     * 
     * @type {number}
     * @memberof InvoiceData
     */
    numberSeriesNoId?: number;
    /**
     * 
     * @type {string}
     * @memberof InvoiceData
     */
    externalIdentificator?: string;
    /**
     * 
     * @type {number}
     * @memberof InvoiceData
     */
    currency?: InvoiceDataCurrencyEnum;
    /**
     * 
     * @type {number}
     * @memberof InvoiceData
     */
    paymentMethod?: InvoiceDataPaymentMethodEnum;
    /**
     * 
     * @type {boolean}
     * @memberof InvoiceData
     */
    forCompany?: boolean;
    /**
     * 
     * @type {string}
     * @memberof InvoiceData
     */
    identification?: string;
    /**
     * 
     * @type {number}
     * @memberof InvoiceData
     */
    registrationId?: number;
    /**
     * 
     * @type {InvoiceContact}
     * @memberof InvoiceData
     */
    customer?: InvoiceContact;
    /**
     * 
     * @type {string}
     * @memberof InvoiceData
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceData
     */
    itemsTextPreffix?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceData
     */
    itemsTextSuffix?: string;
    /**
     * 
     * @type {Array<InvoiceItem>}
     * @memberof InvoiceData
     */
    items?: Array<InvoiceItem>;
    /**
     * 
     * @type {boolean}
     * @memberof InvoiceData
     */
    sendToCustomer?: boolean;
}


/**
 * @export
 */
export const InvoiceDataCurrencyEnum = {
    NUMBER_192: 192,
    NUMBER_193: 193
} as const;
export type InvoiceDataCurrencyEnum = typeof InvoiceDataCurrencyEnum[keyof typeof InvoiceDataCurrencyEnum];

/**
 * @export
 */
export const InvoiceDataPaymentMethodEnum = {
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3
} as const;
export type InvoiceDataPaymentMethodEnum = typeof InvoiceDataPaymentMethodEnum[keyof typeof InvoiceDataPaymentMethodEnum];


/**
 * Check if a given object implements the InvoiceData interface.
 */
export function instanceOfInvoiceData(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function InvoiceDataFromJSON(json: any): InvoiceData {
    return InvoiceDataFromJSONTyped(json, false);
}

export function InvoiceDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): InvoiceData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'issuerCode': !exists(json, 'IssuerCode') ? undefined : json['IssuerCode'],
        'numberSeriesNoId': !exists(json, 'NumberSeriesNoId') ? undefined : json['NumberSeriesNoId'],
        'externalIdentificator': !exists(json, 'ExternalIdentificator') ? undefined : json['ExternalIdentificator'],
        'currency': !exists(json, 'Currency') ? undefined : json['Currency'],
        'paymentMethod': !exists(json, 'PaymentMethod') ? undefined : json['PaymentMethod'],
        'forCompany': !exists(json, 'ForCompany') ? undefined : json['ForCompany'],
        'identification': !exists(json, 'Identification') ? undefined : json['Identification'],
        'registrationId': !exists(json, 'RegistrationId') ? undefined : json['RegistrationId'],
        'customer': !exists(json, 'Customer') ? undefined : InvoiceContactFromJSON(json['Customer']),
        'description': !exists(json, 'Description') ? undefined : json['Description'],
        'itemsTextPreffix': !exists(json, 'ItemsTextPreffix') ? undefined : json['ItemsTextPreffix'],
        'itemsTextSuffix': !exists(json, 'ItemsTextSuffix') ? undefined : json['ItemsTextSuffix'],
        'items': !exists(json, 'Items') ? undefined : ((json['Items'] as Array<any>).map(InvoiceItemFromJSON)),
        'sendToCustomer': !exists(json, 'SendToCustomer') ? undefined : json['SendToCustomer'],
    };
}

export function InvoiceDataToJSON(value?: InvoiceData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'IssuerCode': value.issuerCode,
        'NumberSeriesNoId': value.numberSeriesNoId,
        'ExternalIdentificator': value.externalIdentificator,
        'Currency': value.currency,
        'PaymentMethod': value.paymentMethod,
        'ForCompany': value.forCompany,
        'Identification': value.identification,
        'RegistrationId': value.registrationId,
        'Customer': InvoiceContactToJSON(value.customer),
        'Description': value.description,
        'ItemsTextPreffix': value.itemsTextPreffix,
        'ItemsTextSuffix': value.itemsTextSuffix,
        'Items': value.items === undefined ? undefined : ((value.items as Array<any>).map(InvoiceItemToJSON)),
        'SendToCustomer': value.sendToCustomer,
    };
}

