import { type UseMutationResult } from "@tanstack/react-query";
import { type RouteUpdateArgs } from "gatsby";

import { type ResultDataType } from "./API/main/models";
export { wrapPageElement, wrapRootElement } from "./gatsby-shared";

declare global {
	interface Window {
		gatsbyPreviousUrl: string | null;
		persistPushNotificationsToken: (
			token: string,
		) => UseMutationResult<ResultDataType, Error, string, unknown>;
	}
	interface Document {
		persistPushNotificationsToken: (
			token: string,
			deviceDescription: string,
		) => Promise<ResultDataType>;
	}
}

window.gatsbyPreviousUrl = window.gatsbyPreviousUrl ?? null;

export const onRouteUpdate = ({ location, prevLocation }: RouteUpdateArgs): void => {
	window.gatsbyPreviousUrl = prevLocation?.pathname ?? null;
};
