/* tslint:disable */
/* eslint-disable */
/**
 * VentureClub.RestApi2
 * 1.33.2.3, env PROD
 *
 * The version of the OpenAPI document: 1.0
 * Contact: it@spinware.cz
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  HoldingListResultDataType,
  InvestRequest,
  PagedListResultDataTypeOfTInvestCampaign,
  ResultDataType,
  ResultRedirectDataType,
} from '../models/index';
import {
    HoldingListResultDataTypeFromJSON,
    HoldingListResultDataTypeToJSON,
    InvestRequestFromJSON,
    InvestRequestToJSON,
    PagedListResultDataTypeOfTInvestCampaignFromJSON,
    PagedListResultDataTypeOfTInvestCampaignToJSON,
    ResultDataTypeFromJSON,
    ResultDataTypeToJSON,
    ResultRedirectDataTypeFromJSON,
    ResultRedirectDataTypeToJSON,
} from '../models/index';

export interface ApiV1InvestManagementInvestmentsGetRequest {
    projectId?: Array<number>;
    campaignId?: number;
    active?: boolean;
    isOpenForInvestment?: boolean;
    membershipLevel?: number;
    limit?: number;
    page?: number;
}

export interface ApiV1InvestManagementInvestmentsHoldingListsIdGetRequest {
    id: number;
}

export interface ApiV1InvestManagementInvestmentsPutRequest {
    investRequest?: InvestRequest;
}

/**
 * 
 */
export class InvestmentsApi extends runtime.BaseAPI {

    /**
     * Seznam aktivních investičních příležitostí
     */
    async apiV1InvestManagementInvestmentsGetRaw(requestParameters: ApiV1InvestManagementInvestmentsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PagedListResultDataTypeOfTInvestCampaign>> {
        const queryParameters: any = {};

        if (requestParameters.projectId) {
            queryParameters['projectId'] = requestParameters.projectId;
        }

        if (requestParameters.campaignId !== undefined) {
            queryParameters['campaignId'] = requestParameters.campaignId;
        }

        if (requestParameters.active !== undefined) {
            queryParameters['active'] = requestParameters.active;
        }

        if (requestParameters.isOpenForInvestment !== undefined) {
            queryParameters['isOpenForInvestment'] = requestParameters.isOpenForInvestment;
        }

        if (requestParameters.membershipLevel !== undefined) {
            queryParameters['membershipLevel'] = requestParameters.membershipLevel;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/invest-management/investments`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PagedListResultDataTypeOfTInvestCampaignFromJSON(jsonValue));
    }

    /**
     * Seznam aktivních investičních příležitostí
     */
    async apiV1InvestManagementInvestmentsGet(requestParameters: ApiV1InvestManagementInvestmentsGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PagedListResultDataTypeOfTInvestCampaign> {
        const response = await this.apiV1InvestManagementInvestmentsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Detail kmenového listu
     */
    async apiV1InvestManagementInvestmentsHoldingListsIdGetRaw(requestParameters: ApiV1InvestManagementInvestmentsHoldingListsIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<HoldingListResultDataType>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiV1InvestManagementInvestmentsHoldingListsIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/invest-management/investments/holding-lists/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HoldingListResultDataTypeFromJSON(jsonValue));
    }

    /**
     * Detail kmenového listu
     */
    async apiV1InvestManagementInvestmentsHoldingListsIdGet(requestParameters: ApiV1InvestManagementInvestmentsHoldingListsIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<HoldingListResultDataType> {
        const response = await this.apiV1InvestManagementInvestmentsHoldingListsIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Příslib
     */
    async apiV1InvestManagementInvestmentsPutRaw(requestParameters: ApiV1InvestManagementInvestmentsPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResultRedirectDataType>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json; x-api-version=1.0';

        const response = await this.request({
            path: `/api/v1/invest-management/investments`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: InvestRequestToJSON(requestParameters.investRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResultRedirectDataTypeFromJSON(jsonValue));
    }

    /**
     * Příslib
     */
    async apiV1InvestManagementInvestmentsPut(requestParameters: ApiV1InvestManagementInvestmentsPutRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResultRedirectDataType> {
        const response = await this.apiV1InvestManagementInvestmentsPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
