/* tslint:disable */
/* eslint-disable */
/**
 * VentureClub.RestApi2
 * 1.33.2.3, env PROD
 *
 * The version of the OpenAPI document: 1.0
 * Contact: it@spinware.cz
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  KpiDefinitionListResultDataType,
  PagedListResultDataTypeOfTKpiBaseResultDataType,
  PagedListResultDataTypeOfTKpiValue,
  ProjectsPerfKpiListResultDataType,
  ResultDataType,
} from '../models/index';
import {
    KpiDefinitionListResultDataTypeFromJSON,
    KpiDefinitionListResultDataTypeToJSON,
    PagedListResultDataTypeOfTKpiBaseResultDataTypeFromJSON,
    PagedListResultDataTypeOfTKpiBaseResultDataTypeToJSON,
    PagedListResultDataTypeOfTKpiValueFromJSON,
    PagedListResultDataTypeOfTKpiValueToJSON,
    ProjectsPerfKpiListResultDataTypeFromJSON,
    ProjectsPerfKpiListResultDataTypeToJSON,
    ResultDataTypeFromJSON,
    ResultDataTypeToJSON,
} from '../models/index';

export interface ApiV1ProjectManagementKpisGetRequest {
    projectId?: number;
    registrationId?: number;
    withMyInvests?: boolean;
    onlyPerfIndicators?: boolean;
    code?: string;
    page?: number;
    limit?: number;
}

export interface ApiV1ProjectManagementKpisIdGetRequest {
    id: number;
    step?: number;
    page?: number;
    limit?: number;
    periodDesc?: boolean;
    notSumPeriod?: boolean;
}

export interface ApiV1ProjectManagementKpisPerfDataGetRequest {
    periodFilter?: number;
    projectId?: number;
    onlyOwn?: boolean;
    syncPeriods?: boolean;
    onlyProjectLevel?: boolean;
}

export interface ApiV1ProjectManagementKpisTypesGetRequest {
    projectId?: number;
}

/**
 * 
 */
export class KpisApi extends runtime.BaseAPI {

    /**
     * Aktivní KPI na projektech
     */
    async apiV1ProjectManagementKpisGetRaw(requestParameters: ApiV1ProjectManagementKpisGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PagedListResultDataTypeOfTKpiBaseResultDataType>> {
        const queryParameters: any = {};

        if (requestParameters.projectId !== undefined) {
            queryParameters['projectId'] = requestParameters.projectId;
        }

        if (requestParameters.registrationId !== undefined) {
            queryParameters['registrationId'] = requestParameters.registrationId;
        }

        if (requestParameters.withMyInvests !== undefined) {
            queryParameters['withMyInvests'] = requestParameters.withMyInvests;
        }

        if (requestParameters.onlyPerfIndicators !== undefined) {
            queryParameters['onlyPerfIndicators'] = requestParameters.onlyPerfIndicators;
        }

        if (requestParameters.code !== undefined) {
            queryParameters['code'] = requestParameters.code;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/project-management/kpis`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PagedListResultDataTypeOfTKpiBaseResultDataTypeFromJSON(jsonValue));
    }

    /**
     * Aktivní KPI na projektech
     */
    async apiV1ProjectManagementKpisGet(requestParameters: ApiV1ProjectManagementKpisGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PagedListResultDataTypeOfTKpiBaseResultDataType> {
        const response = await this.apiV1ProjectManagementKpisGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Hodnoty KPI, v případě že hodnoty nejsou T1M, tak jsou aproximovány pro chybě
     */
    async apiV1ProjectManagementKpisIdGetRaw(requestParameters: ApiV1ProjectManagementKpisIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PagedListResultDataTypeOfTKpiValue>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiV1ProjectManagementKpisIdGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.step !== undefined) {
            queryParameters['step'] = requestParameters.step;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.periodDesc !== undefined) {
            queryParameters['periodDesc'] = requestParameters.periodDesc;
        }

        if (requestParameters.notSumPeriod !== undefined) {
            queryParameters['notSumPeriod'] = requestParameters.notSumPeriod;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/project-management/kpis/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PagedListResultDataTypeOfTKpiValueFromJSON(jsonValue));
    }

    /**
     * Hodnoty KPI, v případě že hodnoty nejsou T1M, tak jsou aproximovány pro chybě
     */
    async apiV1ProjectManagementKpisIdGet(requestParameters: ApiV1ProjectManagementKpisIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PagedListResultDataTypeOfTKpiValue> {
        const response = await this.apiV1ProjectManagementKpisIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Výkonnostní KPI
     */
    async apiV1ProjectManagementKpisPerfDataGetRaw(requestParameters: ApiV1ProjectManagementKpisPerfDataGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProjectsPerfKpiListResultDataType>> {
        const queryParameters: any = {};

        if (requestParameters.periodFilter !== undefined) {
            queryParameters['periodFilter'] = requestParameters.periodFilter;
        }

        if (requestParameters.projectId !== undefined) {
            queryParameters['projectId'] = requestParameters.projectId;
        }

        if (requestParameters.onlyOwn !== undefined) {
            queryParameters['onlyOwn'] = requestParameters.onlyOwn;
        }

        if (requestParameters.syncPeriods !== undefined) {
            queryParameters['syncPeriods'] = requestParameters.syncPeriods;
        }

        if (requestParameters.onlyProjectLevel !== undefined) {
            queryParameters['onlyProjectLevel'] = requestParameters.onlyProjectLevel;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/project-management/kpis/perf-data`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProjectsPerfKpiListResultDataTypeFromJSON(jsonValue));
    }

    /**
     * Výkonnostní KPI
     */
    async apiV1ProjectManagementKpisPerfDataGet(requestParameters: ApiV1ProjectManagementKpisPerfDataGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProjectsPerfKpiListResultDataType> {
        const response = await this.apiV1ProjectManagementKpisPerfDataGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Existující typy KPI - definice
     */
    async apiV1ProjectManagementKpisTypesGetRaw(requestParameters: ApiV1ProjectManagementKpisTypesGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<KpiDefinitionListResultDataType>> {
        const queryParameters: any = {};

        if (requestParameters.projectId !== undefined) {
            queryParameters['projectId'] = requestParameters.projectId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/project-management/kpis/types`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => KpiDefinitionListResultDataTypeFromJSON(jsonValue));
    }

    /**
     * Existující typy KPI - definice
     */
    async apiV1ProjectManagementKpisTypesGet(requestParameters: ApiV1ProjectManagementKpisTypesGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<KpiDefinitionListResultDataType> {
        const response = await this.apiV1ProjectManagementKpisTypesGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
