/* tslint:disable */
/* eslint-disable */
/**
 * VentureClub.RestApi v1.33.2.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  PaymentData,
  QrData,
  ResultDataTypeCAresBasicInfo,
  ResultDataTypePaymentQrResultDataType,
} from '../models/index';
import {
    PaymentDataFromJSON,
    PaymentDataToJSON,
    QrDataFromJSON,
    QrDataToJSON,
    ResultDataTypeCAresBasicInfoFromJSON,
    ResultDataTypeCAresBasicInfoToJSON,
    ResultDataTypePaymentQrResultDataTypeFromJSON,
    ResultDataTypePaymentQrResultDataTypeToJSON,
} from '../models/index';

export interface CommonCreatePaymentQRRequest {
    request: PaymentData;
}

export interface CommonCreateQRRequest {
    data: QrData;
}

export interface CommonGetAresDataRequest {
    ico: string;
}

/**
 * 
 */
export class CommonApi extends runtime.BaseAPI {

    /**
     * Authorized
     * Vygeneruje platební QR
     */
    async commonCreatePaymentQRRaw(requestParameters: CommonCreatePaymentQRRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResultDataTypePaymentQrResultDataType>> {
        if (requestParameters.request === null || requestParameters.request === undefined) {
            throw new runtime.RequiredError('request','Required parameter requestParameters.request was null or undefined when calling commonCreatePaymentQR.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/common/paymentqr`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PaymentDataToJSON(requestParameters.request),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResultDataTypePaymentQrResultDataTypeFromJSON(jsonValue));
    }

    /**
     * Authorized
     * Vygeneruje platební QR
     */
    async commonCreatePaymentQR(requestParameters: CommonCreatePaymentQRRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResultDataTypePaymentQrResultDataType> {
        const response = await this.commonCreatePaymentQRRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Authorized
     * Vygeneruje QR dle zadaných dat (obsah qr)
     */
    async commonCreateQRRaw(requestParameters: CommonCreateQRRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling commonCreateQR.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/common/qr`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: QrDataToJSON(requestParameters.data),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Authorized
     * Vygeneruje QR dle zadaných dat (obsah qr)
     */
    async commonCreateQR(requestParameters: CommonCreateQRRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.commonCreateQRRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Authorized
     * Vrátí informace k ičo z databáze Ares - RES
     */
    async commonGetAresDataRaw(requestParameters: CommonGetAresDataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResultDataTypeCAresBasicInfo>> {
        if (requestParameters.ico === null || requestParameters.ico === undefined) {
            throw new runtime.RequiredError('ico','Required parameter requestParameters.ico was null or undefined when calling commonGetAresData.');
        }

        const queryParameters: any = {};

        if (requestParameters.ico !== undefined) {
            queryParameters['ico'] = requestParameters.ico;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/common/ares`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResultDataTypeCAresBasicInfoFromJSON(jsonValue));
    }

    /**
     * Authorized
     * Vrátí informace k ičo z databáze Ares - RES
     */
    async commonGetAresData(requestParameters: CommonGetAresDataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResultDataTypeCAresBasicInfo> {
        const response = await this.commonGetAresDataRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
