import { createContext, type ReactElement, useContext, useEffect, useState } from "react";

import { AuthApi } from "../../API/identity/apis/index";
import { Configuration } from "../../API/identity/runtime";
import { validEnv } from "../../gatsby-shared";
import { getUser, removeUser, saveUser } from "../functions/user";
import { type IdentityTokenDecoded } from "../queryHooks/user";

interface IApiContext {
	user: IdentityTokenDecoded;
	setUser: (user: any) => void;
	isAuthorized: () => boolean;
	removeToken: () => void;
	AuthApi: AuthApi;
}

export const IdentityContext = createContext<IApiContext>({} as unknown as IApiContext);

export function useIdentity(): IApiContext {
	return useContext(IdentityContext);
}

interface IProps {
	children: ReactElement;
}

export const IdentityProvider = ({ children }: IProps): JSX.Element => {
	const storedUser = getUser();
	const [user, setUser] = useState<IdentityTokenDecoded>(storedUser);

	const removeToken = (): void => {
		removeUser();
		setUser(undefined);
	};

	const isAuthorized = (): boolean => {
		const isAuthorized =
			user?.accessTime?.authTime != null && user?.expiresIn != null
				? user?.expiresIn + user?.accessTime?.authTime > Math.trunc(Date.now() / 1000)
				: false;
		return isAuthorized;
	};

	useEffect(() => {
		if (isAuthorized()) {
			saveUser(user);
		}
	}, [user]);

	const configuration = validEnv
		? new Configuration({
				headers: {
					Authorization:
						// eslint-disable-next-line
						"Basic " + btoa(process.env.GATSBY_BASIC_USERNAME + ":" + process.env.GATSBY_BASIC_PASSWORD),
				},
		  })
		: undefined;

	return (
		<IdentityContext.Provider
			value={{
				user,
				setUser,
				removeToken,
				isAuthorized,
				AuthApi: new AuthApi(configuration),
			}}
		>
			{children}
		</IdentityContext.Provider>
	);
};
