/* tslint:disable */
/* eslint-disable */
/**
 * VentureClub.RestApi v1.33.2.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AuthenticationRequest
 */
export interface AuthenticationRequest {
    /**
     * 
     * @type {number}
     * @memberof AuthenticationRequest
     */
    grantType?: AuthenticationRequestGrantTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof AuthenticationRequest
     */
    scope?: string;
    /**
     * 
     * @type {string}
     * @memberof AuthenticationRequest
     */
    username?: string;
    /**
     * 
     * @type {string}
     * @memberof AuthenticationRequest
     */
    password?: string;
    /**
     * 
     * @type {string}
     * @memberof AuthenticationRequest
     */
    refreshToken?: string;
    /**
     * 
     * @type {string}
     * @memberof AuthenticationRequest
     */
    redirectUri?: string;
    /**
     * 
     * @type {string}
     * @memberof AuthenticationRequest
     */
    code?: string;
    /**
     * 
     * @type {string}
     * @memberof AuthenticationRequest
     */
    nonce?: string;
    /**
     * 
     * @type {string}
     * @memberof AuthenticationRequest
     */
    desc?: string;
}


/**
 * @export
 */
export const AuthenticationRequestGrantTypeEnum = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3
} as const;
export type AuthenticationRequestGrantTypeEnum = typeof AuthenticationRequestGrantTypeEnum[keyof typeof AuthenticationRequestGrantTypeEnum];


/**
 * Check if a given object implements the AuthenticationRequest interface.
 */
export function instanceOfAuthenticationRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function AuthenticationRequestFromJSON(json: any): AuthenticationRequest {
    return AuthenticationRequestFromJSONTyped(json, false);
}

export function AuthenticationRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): AuthenticationRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'grantType': !exists(json, 'grant_type') ? undefined : json['grant_type'],
        'scope': !exists(json, 'scope') ? undefined : json['scope'],
        'username': !exists(json, 'username') ? undefined : json['username'],
        'password': !exists(json, 'password') ? undefined : json['password'],
        'refreshToken': !exists(json, 'refresh_token') ? undefined : json['refresh_token'],
        'redirectUri': !exists(json, 'redirect_uri') ? undefined : json['redirect_uri'],
        'code': !exists(json, 'code') ? undefined : json['code'],
        'nonce': !exists(json, 'nonce') ? undefined : json['nonce'],
        'desc': !exists(json, 'desc') ? undefined : json['desc'],
    };
}

export function AuthenticationRequestToJSON(value?: AuthenticationRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'grant_type': value.grantType,
        'scope': value.scope,
        'username': value.username,
        'password': value.password,
        'refresh_token': value.refreshToken,
        'redirect_uri': value.redirectUri,
        'code': value.code,
        'nonce': value.nonce,
        'desc': value.desc,
    };
}

