/* tslint:disable */
/* eslint-disable */
/**
 * VentureClub.RestApi2
 * 1.33.2.3, env PROD
 *
 * The version of the OpenAPI document: 1.0
 * Contact: it@spinware.cz
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Detail kmenového listu
 * @export
 * @interface HoldingListResultDataType
 */
export interface HoldingListResultDataType {
    /**
     * 
     * @type {number}
     * @memberof HoldingListResultDataType
     */
    resultCode?: number;
    /**
     * 
     * @type {boolean}
     * @memberof HoldingListResultDataType
     */
    error?: boolean;
    /**
     * 
     * @type {string}
     * @memberof HoldingListResultDataType
     */
    errorMessage?: string | null;
    /**
     * Id kmenového listu
     * @type {number}
     * @memberof HoldingListResultDataType
     */
    id?: number;
    /**
     * Id investiční kampaně
     * @type {number}
     * @memberof HoldingListResultDataType
     */
    investCampaignId?: number;
    /**
     * Jméno kmenového listu
     * @type {string}
     * @memberof HoldingListResultDataType
     */
    name?: string | null;
    /**
     * Cena kmenového listu, platba VCI,
     * tato částka se neinvestuje, ale je součástí platby za investici
     * @type {number}
     * @memberof HoldingListResultDataType
     */
    readonly price?: number;
    /**
     * Příplatek - investiční část, platba na projekt
     * @type {number}
     * @memberof HoldingListResultDataType
     */
    readonly surcharge?: number;
    /**
     * Příplatek ostatní, platba na projekt
     * @type {number}
     * @memberof HoldingListResultDataType
     */
    surchargeOther?: number;
    /**
     * Krok příslibu (Surcharge + SurchargeOther),
     * násobek této částky investor investuje
     * @type {number}
     * @memberof HoldingListResultDataType
     */
    readonly promisesStep?: number;
    /**
     * Max. počet listů k prodeji (velikost edice)
     * @type {number}
     * @memberof HoldingListResultDataType
     */
    maxQuantity?: number;
    /**
     * Akt. počet přislíbených (zakoupených) listů
     * @type {number}
     * @memberof HoldingListResultDataType
     */
    usedQuantity?: number;
    /**
     * Akt. dostupné množství listů
     * @type {number}
     * @memberof HoldingListResultDataType
     */
    readonly availableQuantity?: number;
}

/**
 * Check if a given object implements the HoldingListResultDataType interface.
 */
export function instanceOfHoldingListResultDataType(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function HoldingListResultDataTypeFromJSON(json: any): HoldingListResultDataType {
    return HoldingListResultDataTypeFromJSONTyped(json, false);
}

export function HoldingListResultDataTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): HoldingListResultDataType {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'resultCode': !exists(json, 'resultCode') ? undefined : json['resultCode'],
        'error': !exists(json, 'error') ? undefined : json['error'],
        'errorMessage': !exists(json, 'errorMessage') ? undefined : json['errorMessage'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'investCampaignId': !exists(json, 'investCampaignId') ? undefined : json['investCampaignId'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'price': !exists(json, 'price') ? undefined : json['price'],
        'surcharge': !exists(json, 'surcharge') ? undefined : json['surcharge'],
        'surchargeOther': !exists(json, 'surchargeOther') ? undefined : json['surchargeOther'],
        'promisesStep': !exists(json, 'promisesStep') ? undefined : json['promisesStep'],
        'maxQuantity': !exists(json, 'maxQuantity') ? undefined : json['maxQuantity'],
        'usedQuantity': !exists(json, 'usedQuantity') ? undefined : json['usedQuantity'],
        'availableQuantity': !exists(json, 'availableQuantity') ? undefined : json['availableQuantity'],
    };
}

export function HoldingListResultDataTypeToJSON(value?: HoldingListResultDataType | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'resultCode': value.resultCode,
        'error': value.error,
        'errorMessage': value.errorMessage,
        'id': value.id,
        'investCampaignId': value.investCampaignId,
        'name': value.name,
        'surchargeOther': value.surchargeOther,
        'maxQuantity': value.maxQuantity,
        'usedQuantity': value.usedQuantity,
    };
}

