import { createTheme } from "@mui/material/styles";

import palette from "./palette";
import spacing from "./spacing";
import typography from "./typography";

const theme = createTheme({
	palette,
	...spacing,
	typography,
	components: {
		MuiCssBaseline: {
			styleOverrides: {
				html: {
					overflowY: "scroll",
					height: "100%",
					minHeight: "100%",
				},
				"body, #___gatsby, #gatsby-focus-wrapper": {
					height: "100%",
					minHeight: "100%",
					userSelect: "none",
					touchAction: "manipulation",
				},
				"body.background--main": {
					backgroundColor: palette.background.main,
				},
				".VictoryContainer": {
					// ONLY FOR VICTORY CHARTS
					// https://github.com/FormidableLabs/victory/issues/1430#issuecomment-609473230
					// userSelect: "auto !important",
					// pointerEvents: "auto !important",
					touchAction: "manipulation !important",
				},
				tspan: { userSelect: "none" },
			},
		},
	},
});

theme.components.MuiButton = {
	styleOverrides: {
		root: {
			textTransform: "none",
			fontSize: "16px",
		},
	},
	variants: [
		{
			props: { variant: "outlined" },
			style: {
				color: theme.palette.text.main,
			},
		},
		{
			props: { size: "medium" },
			style: {
				paddingTop: theme.spacing(2),
				paddingBottom: theme.spacing(2),
				borderRadius: theme.spacing(2),
			},
		},
		{
			props: { variant: "text" },
			style: {
				padding: 0,
				fontWeight: "unset",
				textDecoration: "underline",
				color: "inherit",
			},
		},
	],
};

theme.components.MuiLink = {
	styleOverrides: {
		root: {
			cursor: "pointer",
		},
	},
};

theme.components.MuiAlert = {
	styleOverrides: {
		root: {
			fontSize: "16px",
			borderRadius: theme.spacing(2),
		},
	},
};

theme.components.MuiOutlinedInput = {
	styleOverrides: {
		root: {
			fontSize: "16px",
			borderRadius: theme.spacing(2),
		},
	},
};
theme.components.MuiCard = {
	styleOverrides: {
		root: {
			boxShadow: "0 8px 8px -4px rgba(186, 216, 246, 0.8)",
		},
	},
};

theme.components.MuiAccordion = {
	styleOverrides: {
		root: {
			boxShadow: "0 8px 8px -4px rgba(186, 216, 246, 0.8)",
		},
	},
};

theme.components.MuiMenuItem = {
	styleOverrides: {
		root: {
			fontSize: "14px",
			paddingLeft: "8px",
			paddingRight: "8px",
		},
	},
};

export default theme;
