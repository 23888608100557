/* tslint:disable */
/* eslint-disable */
/**
 * VentureClub.RestApi2
 * 1.33.2.3, env PROD
 *
 * The version of the OpenAPI document: 1.0
 * Contact: it@spinware.cz
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { KpiValue } from './KpiValue';
import {
    KpiValueFromJSON,
    KpiValueFromJSONTyped,
    KpiValueToJSON,
} from './KpiValue';

/**
 * 
 * @export
 * @interface PagedListResultDataTypeOfTKpiValue
 */
export interface PagedListResultDataTypeOfTKpiValue {
    /**
     * 
     * @type {number}
     * @memberof PagedListResultDataTypeOfTKpiValue
     */
    resultCode?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PagedListResultDataTypeOfTKpiValue
     */
    error?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PagedListResultDataTypeOfTKpiValue
     */
    errorMessage?: string | null;
    /**
     * Celkový počet
     * @type {number}
     * @memberof PagedListResultDataTypeOfTKpiValue
     */
    totalCount?: number;
    /**
     * Počet položek na stránku
     * @type {number}
     * @memberof PagedListResultDataTypeOfTKpiValue
     */
    pageSize?: number;
    /**
     * Aktuální stránka
     * @type {number}
     * @memberof PagedListResultDataTypeOfTKpiValue
     */
    currentPage?: number;
    /**
     * Celkový počet stran
     * @type {number}
     * @memberof PagedListResultDataTypeOfTKpiValue
     */
    totalPages?: number;
    /**
     * K dispozici další strana
     * @type {boolean}
     * @memberof PagedListResultDataTypeOfTKpiValue
     */
    readonly hasNext?: boolean;
    /**
     * K dispozici předchozí strana
     * @type {boolean}
     * @memberof PagedListResultDataTypeOfTKpiValue
     */
    readonly hasPrevious?: boolean;
    /**
     * Položky
     * @type {Array<KpiValue>}
     * @memberof PagedListResultDataTypeOfTKpiValue
     */
    items?: Array<KpiValue> | null;
}

/**
 * Check if a given object implements the PagedListResultDataTypeOfTKpiValue interface.
 */
export function instanceOfPagedListResultDataTypeOfTKpiValue(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PagedListResultDataTypeOfTKpiValueFromJSON(json: any): PagedListResultDataTypeOfTKpiValue {
    return PagedListResultDataTypeOfTKpiValueFromJSONTyped(json, false);
}

export function PagedListResultDataTypeOfTKpiValueFromJSONTyped(json: any, ignoreDiscriminator: boolean): PagedListResultDataTypeOfTKpiValue {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'resultCode': !exists(json, 'resultCode') ? undefined : json['resultCode'],
        'error': !exists(json, 'error') ? undefined : json['error'],
        'errorMessage': !exists(json, 'errorMessage') ? undefined : json['errorMessage'],
        'totalCount': !exists(json, 'totalCount') ? undefined : json['totalCount'],
        'pageSize': !exists(json, 'pageSize') ? undefined : json['pageSize'],
        'currentPage': !exists(json, 'currentPage') ? undefined : json['currentPage'],
        'totalPages': !exists(json, 'totalPages') ? undefined : json['totalPages'],
        'hasNext': !exists(json, 'hasNext') ? undefined : json['hasNext'],
        'hasPrevious': !exists(json, 'hasPrevious') ? undefined : json['hasPrevious'],
        'items': !exists(json, 'items') ? undefined : (json['items'] === null ? null : (json['items'] as Array<any>).map(KpiValueFromJSON)),
    };
}

export function PagedListResultDataTypeOfTKpiValueToJSON(value?: PagedListResultDataTypeOfTKpiValue | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'resultCode': value.resultCode,
        'error': value.error,
        'errorMessage': value.errorMessage,
        'totalCount': value.totalCount,
        'pageSize': value.pageSize,
        'currentPage': value.currentPage,
        'totalPages': value.totalPages,
        'items': value.items === undefined ? undefined : (value.items === null ? null : (value.items as Array<any>).map(KpiValueToJSON)),
    };
}

