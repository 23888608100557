/* tslint:disable */
/* eslint-disable */
/**
 * VentureClub.RestApi v1.33.2.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ResultDataTypeUInt32
 */
export interface ResultDataTypeUInt32 {
    /**
     * 
     * @type {number}
     * @memberof ResultDataTypeUInt32
     */
    data?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ResultDataTypeUInt32
     */
    error?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ResultDataTypeUInt32
     */
    errorMessage?: string;
}

/**
 * Check if a given object implements the ResultDataTypeUInt32 interface.
 */
export function instanceOfResultDataTypeUInt32(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ResultDataTypeUInt32FromJSON(json: any): ResultDataTypeUInt32 {
    return ResultDataTypeUInt32FromJSONTyped(json, false);
}

export function ResultDataTypeUInt32FromJSONTyped(json: any, ignoreDiscriminator: boolean): ResultDataTypeUInt32 {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': !exists(json, 'Data') ? undefined : json['Data'],
        'error': !exists(json, 'Error') ? undefined : json['Error'],
        'errorMessage': !exists(json, 'ErrorMessage') ? undefined : json['ErrorMessage'],
    };
}

export function ResultDataTypeUInt32ToJSON(value?: ResultDataTypeUInt32 | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Data': value.data,
        'Error': value.error,
        'ErrorMessage': value.errorMessage,
    };
}

