/* tslint:disable */
/* eslint-disable */
/**
 * VentureClub.RestApi v1.33.2.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface QrData
 */
export interface QrData {
    /**
     * Text který má být převeden do QR
     * @type {string}
     * @memberof QrData
     */
    content?: string;
    /**
     * Velikost QR
     * @type {number}
     * @memberof QrData
     */
    size?: number;
}

/**
 * Check if a given object implements the QrData interface.
 */
export function instanceOfQrData(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function QrDataFromJSON(json: any): QrData {
    return QrDataFromJSONTyped(json, false);
}

export function QrDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): QrData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'content': !exists(json, 'Content') ? undefined : json['Content'],
        'size': !exists(json, 'Size') ? undefined : json['Size'],
    };
}

export function QrDataToJSON(value?: QrData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Content': value.content,
        'Size': value.size,
    };
}

