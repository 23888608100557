const palette = {
	primary: {
		main: "#DD9932",
		light: "#F6D383",
		dark: "#994204",
		contrastText: "#FFFFFF",
	},
	secondary: {
		main: "#0066B3",
		light: "#75B2EE",
		dark: "#00007D",
		contrastText: "#FFFFFF",
	},
	text: {
		main: "#000000",
		contrast: "#040068",
		dark: "#231F20",
		light: "#B0B0B0",
	},
	background: {
		main: "#EBF5FF",
		secondary: "#E9E9E9",
	},
};

export default palette;
