/* tslint:disable */
/* eslint-disable */
/**
 * VentureClub.RestApi v1.33.2.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LoginResultDataTypeBase
 */
export interface LoginResultDataTypeBase {
    /**
     * 
     * @type {number}
     * @memberof LoginResultDataTypeBase
     */
    registrationID?: number;
    /**
     * 
     * @type {string}
     * @memberof LoginResultDataTypeBase
     */
    cookieToken?: string;
    /**
     * 
     * @type {boolean}
     * @memberof LoginResultDataTypeBase
     */
    isLogged?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LoginResultDataTypeBase
     */
    error?: boolean;
    /**
     * 
     * @type {string}
     * @memberof LoginResultDataTypeBase
     */
    errorMessage?: string;
}

/**
 * Check if a given object implements the LoginResultDataTypeBase interface.
 */
export function instanceOfLoginResultDataTypeBase(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function LoginResultDataTypeBaseFromJSON(json: any): LoginResultDataTypeBase {
    return LoginResultDataTypeBaseFromJSONTyped(json, false);
}

export function LoginResultDataTypeBaseFromJSONTyped(json: any, ignoreDiscriminator: boolean): LoginResultDataTypeBase {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'registrationID': !exists(json, 'RegistrationID') ? undefined : json['RegistrationID'],
        'cookieToken': !exists(json, 'CookieToken') ? undefined : json['CookieToken'],
        'isLogged': !exists(json, 'IsLogged') ? undefined : json['IsLogged'],
        'error': !exists(json, 'Error') ? undefined : json['Error'],
        'errorMessage': !exists(json, 'ErrorMessage') ? undefined : json['ErrorMessage'],
    };
}

export function LoginResultDataTypeBaseToJSON(value?: LoginResultDataTypeBase | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'RegistrationID': value.registrationID,
        'CookieToken': value.cookieToken,
        'IsLogged': value.isLogged,
        'Error': value.error,
        'ErrorMessage': value.errorMessage,
    };
}

