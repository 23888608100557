/* tslint:disable */
/* eslint-disable */
/**
 * VentureClub.RestApi2
 * 1.33.2.3, env PROD
 *
 * The version of the OpenAPI document: 1.0
 * Contact: it@spinware.cz
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Translate
 */
export interface Translate {
    /**
     * 
     * @type {string}
     * @memberof Translate
     */
    textCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Translate
     */
    csText?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Translate
     */
    enText?: string | null;
}

/**
 * Check if a given object implements the Translate interface.
 */
export function instanceOfTranslate(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function TranslateFromJSON(json: any): Translate {
    return TranslateFromJSONTyped(json, false);
}

export function TranslateFromJSONTyped(json: any, ignoreDiscriminator: boolean): Translate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'textCode': !exists(json, 'textCode') ? undefined : json['textCode'],
        'csText': !exists(json, 'csText') ? undefined : json['csText'],
        'enText': !exists(json, 'enText') ? undefined : json['enText'],
    };
}

export function TranslateToJSON(value?: Translate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'textCode': value.textCode,
        'csText': value.csText,
        'enText': value.enText,
    };
}

