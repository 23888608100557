import LoadingButton from "@mui/lab/LoadingButton";
import { navigate } from "gatsby";

import { useIdentity } from "../../context/IdentityProvider";
import { useLocalization } from "../../context/LanguageProvider";
import { useLogout } from "../../queryHooks/user";

const LogOutButton = ({ children, ...rest }): JSX.Element => {
	const { localization } = useLocalization();
	const { removeToken, user } = useIdentity();
	const { mutate, isLoading, isError } = useLogout();

	return (
		<LoadingButton
			variant="outlined"
			{...rest}
			loading={isLoading}
			onClick={() => {
				removeToken();
				mutate(
					{ token: user?.accessToken },
					{
						onSuccess: () => {
							void navigate("/");
						},
						onError: () => {},
					},
				);
			}}
		>
			{isError ? localization.global.tryAgain : children}
		</LoadingButton>
	);
};

export default LogOutButton;
