import { createContext, type ReactElement, useContext } from "react";

import { UserApi } from "../../API/main/apis/index";
import { Configuration as MainConfig } from "../../API/main/runtime";
import { isAndroid, isIOS } from "../functions/wrapper";

interface IProps {
	children: ReactElement;
}

const persistPushNotificationsToken = (accessToken: string): void => {
	document.persistPushNotificationsToken = async (token: string, deviceDescription: string) => {
		const mainConfig = new MainConfig({
			headers: {
				// eslint-disable-next-line
				Authorization: "Bearer " + accessToken,
				"Accept-Language": "",
			},
		});
		const userApi = new UserApi(mainConfig);
		const subscriptionRequest = {
			subscribe: true,
			fcmToken: token,
			topic: "default",
			deviceDescription,
		};
		return await userApi.apiV1UserManagementUserSubscriptionPut({
			subscriptionRequest,
		});
	};
};

const requestPushNotificationsToken = (accessToken: string): void => {
	const w = window as any;
	if (w !== undefined) {
		if (isAndroid(w)) {
			w.Android.requestPushNotificationsToken();
			persistPushNotificationsToken(accessToken);
		} else if (isIOS(w)) {
			w.webkit.messageHandlers.requestPushNotificationsToken.postMessage("");
			persistPushNotificationsToken(accessToken);
		}
	}
};

const PushNotificationsContext = createContext<{
	requestPushNotificationsToken: (accessToken: string) => void;
}>({ requestPushNotificationsToken });

export const usePushNotifications = () => {
	return useContext(PushNotificationsContext);
};

const PushNotificationsProvider = ({ children }: IProps): JSX.Element => {
	return (
		<PushNotificationsContext.Provider value={{ requestPushNotificationsToken }}>
			{children}
		</PushNotificationsContext.Provider>
	);
};
export default PushNotificationsProvider;
