/* tslint:disable */
/* eslint-disable */
/**
 * VentureClub.RestApi2
 * 1.33.2.3, env PROD
 *
 * The version of the OpenAPI document: 1.0
 * Contact: it@spinware.cz
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Typ dostupnosti plného detailu projektu
 * @export
 */
export const EDetailAvalable = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3,
    NUMBER_4: 4,
    NUMBER_5: 5,
    NUMBER_6: 6,
    NUMBER_7: 7,
    NUMBER_8: 8,
    NUMBER_MINUS_2: -2,
    NUMBER_MINUS_1: -1
} as const;
export type EDetailAvalable = typeof EDetailAvalable[keyof typeof EDetailAvalable];


export function EDetailAvalableFromJSON(json: any): EDetailAvalable {
    return EDetailAvalableFromJSONTyped(json, false);
}

export function EDetailAvalableFromJSONTyped(json: any, ignoreDiscriminator: boolean): EDetailAvalable {
    return json as EDetailAvalable;
}

export function EDetailAvalableToJSON(value?: EDetailAvalable | null): any {
    return value as any;
}

