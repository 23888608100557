/* tslint:disable */
/* eslint-disable */
/**
 * VentureClub.RestApi v1.33.2.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ApplicationClient
 */
export interface ApplicationClient {
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationClient
     */
    readonly isValid?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationClient
     */
    readonly locked?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ApplicationClient
     */
    code?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationClient
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationClient
     */
    readonly clientId?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationClient
     */
    authScope?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationClient
     */
    envScope?: string;
    /**
     * 
     * @type {number}
     * @memberof ApplicationClient
     */
    accessTokenTTL?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationClient
     */
    refreshTokenAllowed?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ApplicationClient
     */
    refreshTokenTTL?: number;
    /**
     * 
     * @type {number}
     * @memberof ApplicationClient
     */
    readonly createdById?: number;
    /**
     * 
     * @type {number}
     * @memberof ApplicationClient
     */
    readonly deletedById?: number;
    /**
     * 
     * @type {Date}
     * @memberof ApplicationClient
     */
    readonly validFrom?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ApplicationClient
     */
    readonly validTo?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationClient
     */
    readonly deleted?: boolean;
}

/**
 * Check if a given object implements the ApplicationClient interface.
 */
export function instanceOfApplicationClient(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ApplicationClientFromJSON(json: any): ApplicationClient {
    return ApplicationClientFromJSONTyped(json, false);
}

export function ApplicationClientFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApplicationClient {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'isValid': !exists(json, 'IsValid') ? undefined : json['IsValid'],
        'locked': !exists(json, 'Locked') ? undefined : json['Locked'],
        'code': !exists(json, 'Code') ? undefined : json['Code'],
        'name': !exists(json, 'Name') ? undefined : json['Name'],
        'clientId': !exists(json, 'ClientId') ? undefined : json['ClientId'],
        'authScope': !exists(json, 'AuthScope') ? undefined : json['AuthScope'],
        'envScope': !exists(json, 'EnvScope') ? undefined : json['EnvScope'],
        'accessTokenTTL': !exists(json, 'AccessTokenTTL') ? undefined : json['AccessTokenTTL'],
        'refreshTokenAllowed': !exists(json, 'RefreshTokenAllowed') ? undefined : json['RefreshTokenAllowed'],
        'refreshTokenTTL': !exists(json, 'RefreshTokenTTL') ? undefined : json['RefreshTokenTTL'],
        'createdById': !exists(json, 'CreatedById') ? undefined : json['CreatedById'],
        'deletedById': !exists(json, 'DeletedById') ? undefined : json['DeletedById'],
        'validFrom': !exists(json, 'ValidFrom') ? undefined : (new Date(json['ValidFrom'])),
        'validTo': !exists(json, 'ValidTo') ? undefined : (new Date(json['ValidTo'])),
        'deleted': !exists(json, 'Deleted') ? undefined : json['Deleted'],
    };
}

export function ApplicationClientToJSON(value?: ApplicationClient | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Code': value.code,
        'Name': value.name,
        'AuthScope': value.authScope,
        'EnvScope': value.envScope,
        'AccessTokenTTL': value.accessTokenTTL,
        'RefreshTokenAllowed': value.refreshTokenAllowed,
        'RefreshTokenTTL': value.refreshTokenTTL,
    };
}

