exports.components = {
  "component---src-pages-404-index-tsx": () => import("./../../../src/pages/404/index.tsx" /* webpackChunkName: "component---src-pages-404-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-investing-index-tsx": () => import("./../../../src/pages/investing/index.tsx" /* webpackChunkName: "component---src-pages-investing-index-tsx" */),
  "component---src-pages-investing-project-detail-index-tsx": () => import("./../../../src/pages/investing/projectDetail/index.tsx" /* webpackChunkName: "component---src-pages-investing-project-detail-index-tsx" */),
  "component---src-pages-overview-club-index-tsx": () => import("./../../../src/pages/overview/club/index.tsx" /* webpackChunkName: "component---src-pages-overview-club-index-tsx" */),
  "component---src-pages-overview-index-tsx": () => import("./../../../src/pages/overview/index.tsx" /* webpackChunkName: "component---src-pages-overview-index-tsx" */),
  "component---src-pages-settings-index-tsx": () => import("./../../../src/pages/settings/index.tsx" /* webpackChunkName: "component---src-pages-settings-index-tsx" */)
}

