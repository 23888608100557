import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { type ReactElement } from "react";

interface IProps {
	children: ReactElement;
}

const QueryProvider = ({ children }: IProps): JSX.Element => {
	const client = new QueryClient({
		defaultOptions: {
			queries: {
				refetchOnWindowFocus: false,
				retry: (failureCount, error) => {
					if (error?.response?.status === 401) {
						return 0;
					}
					return 2 - failureCount;
				},
			},
		},
	});
	return (
		<QueryClientProvider client={client}>
			<>
				{children}
				{process.env.NODE_ENV === "development" && <ReactQueryDevtools initialIsOpen={false} />}
			</>
		</QueryClientProvider>
	);
};
export default QueryProvider;
