/* tslint:disable */
/* eslint-disable */
/**
 * VentureClub.RestApi v1.33.2.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PaymentData
 */
export interface PaymentData {
    /**
     * 
     * @type {number}
     * @memberof PaymentData
     */
    toAccountId?: number;
    /**
     * 
     * @type {number}
     * @memberof PaymentData
     */
    amount?: number;
    /**
     * 
     * @type {string}
     * @memberof PaymentData
     */
    variableSymbol?: string;
    /**
     * 
     * @type {Date}
     * @memberof PaymentData
     */
    dueDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof PaymentData
     */
    currencyCode?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentData
     */
    message?: string;
}

/**
 * Check if a given object implements the PaymentData interface.
 */
export function instanceOfPaymentData(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PaymentDataFromJSON(json: any): PaymentData {
    return PaymentDataFromJSONTyped(json, false);
}

export function PaymentDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaymentData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'toAccountId': !exists(json, 'ToAccountId') ? undefined : json['ToAccountId'],
        'amount': !exists(json, 'Amount') ? undefined : json['Amount'],
        'variableSymbol': !exists(json, 'VariableSymbol') ? undefined : json['VariableSymbol'],
        'dueDate': !exists(json, 'DueDate') ? undefined : (new Date(json['DueDate'])),
        'currencyCode': !exists(json, 'CurrencyCode') ? undefined : json['CurrencyCode'],
        'message': !exists(json, 'Message') ? undefined : json['Message'],
    };
}

export function PaymentDataToJSON(value?: PaymentData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'ToAccountId': value.toAccountId,
        'Amount': value.amount,
        'VariableSymbol': value.variableSymbol,
        'DueDate': value.dueDate === undefined ? undefined : (value.dueDate.toISOString()),
        'CurrencyCode': value.currencyCode,
        'Message': value.message,
    };
}

