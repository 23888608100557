/* tslint:disable */
/* eslint-disable */
/**
 * VentureClub.RestApi2
 * 1.33.2.3, env PROD
 *
 * The version of the OpenAPI document: 1.0
 * Contact: it@spinware.cz
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Definice KPI
 * @export
 * @interface KpiDefinitionResultDataType
 */
export interface KpiDefinitionResultDataType {
    /**
     * Id definice
     * @type {number}
     * @memberof KpiDefinitionResultDataType
     */
    id?: number;
    /**
     * Kód KPI definice
     * @type {string}
     * @memberof KpiDefinitionResultDataType
     */
    code?: string | null;
    /**
     * Jméno
     * @type {string}
     * @memberof KpiDefinitionResultDataType
     */
    name?: string | null;
    /**
     * Jednotka
     * @type {string}
     * @memberof KpiDefinitionResultDataType
     */
    unit?: string | null;
    /**
     * Datový typ hodnoty
     * @type {string}
     * @memberof KpiDefinitionResultDataType
     */
    dataType?: string | null;
    /**
     * Požadovaný typ zobrazení - pro budoucí použití na webu
     * @type {string}
     * @memberof KpiDefinitionResultDataType
     */
    presentationType?: string | null;
}

/**
 * Check if a given object implements the KpiDefinitionResultDataType interface.
 */
export function instanceOfKpiDefinitionResultDataType(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function KpiDefinitionResultDataTypeFromJSON(json: any): KpiDefinitionResultDataType {
    return KpiDefinitionResultDataTypeFromJSONTyped(json, false);
}

export function KpiDefinitionResultDataTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): KpiDefinitionResultDataType {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'code': !exists(json, 'code') ? undefined : json['code'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'unit': !exists(json, 'unit') ? undefined : json['unit'],
        'dataType': !exists(json, 'dataType') ? undefined : json['dataType'],
        'presentationType': !exists(json, 'presentationType') ? undefined : json['presentationType'],
    };
}

export function KpiDefinitionResultDataTypeToJSON(value?: KpiDefinitionResultDataType | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'code': value.code,
        'name': value.name,
        'unit': value.unit,
        'dataType': value.dataType,
        'presentationType': value.presentationType,
    };
}

